@import 'src/styles/variables';
@import 'src/styles/mixins';

.description {
    @include font-style($size: 14px, $bold: 400, $lineHeight: 21px, $color: $regent-gray);
    overflow-wrap: anywhere;
    word-break: break-word;
    margin-top: 8px;

    @media (max-width: 575.98px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.fullDescription {
    white-space: pre-wrap;
}

.expandOptions {
    @include display-flex($align: center);
    margin-top: 10px;
    cursor: pointer;

    .expandText {
        @include font-style($size: 16px, $bold: 700, $lineHeight: 21px, $color: $fiord);
    }

    .expandMoreIcon {
        color: $fiord;
        transition: transform 0.3s;
        margin-bottom: -2px;
    }

    .expandLessIcon {
        transform: rotate(180deg);
    }
}
