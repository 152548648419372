@import 'src/styles/variables';
@import 'src/styles/mixins';

.socialsContainer {
    margin-top: 80px;

    .socialsList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 60px;
        row-gap: 35px;
        margin-top: 35px;
    }

    .addSocialsButton {
        background-color: $ghostwhite;
        color: $dodger-blue-darker;
        height: 30px;
        margin-top: 35px;

        .addIcon {
            font-size: 24px;
            margin-left: -4px;
        }

        .title {
            @include font-style($size: 14px, $bold: 500, $transform: none);
        }

        &:hover {
            background-color: $ghostwhite;
        }
    }
}
