@import 'src/styles/variables';
@import 'src/styles/mixins';

.categoryContainer {
    margin: 12px 6px 0;

    .categoryButton {
        @include font-style($size: 12px, $bold: 500, $lineHeight: 18px, $color: $fiord, $transform: none);
        padding: 5px 9px;
        border: 1px solid $nepal;
        min-width: unset;
        border-radius: 20px;
        background-color: $white;

        &:hover {
            background-color: $white;
        }
    }

    .allCategoriesButton {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    .categoryButtonSelected {
        background-color: $bright-turquoise;
        color: $white;
        border-color: $bright-turquoise;

        &:hover {
            background-color: $bright-turquoise;
        }
    }
}
