@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 20px;
    border-top: 1px solid $catskill-white;

    & > div {
        font-size: 14px;
        font-weight: 500;
        color: $fiord;
    }

    .timePeriod {
        font-weight: 700;
        width: 20%;
    }

    .votingToken {
        width: 20%;
    }

    .usdValue {
        width: 20%;
    }

    .stablecoins {
        width: 20%;
    }

    .ethBtcTokens {
        width: 20%;
    }
}
