@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardWrapper {
    @include display-flex($align: center);
    padding: 20px;
    background-color: $white;
    position: relative;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;

    .image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    .contentWrapper {
        @include display-flex($align: center);
        flex-grow: 1;
        margin-left: 14px;
        height: 48px;
        background-color: $ghostwhite;
        border-radius: 4px;
        padding: 8px 11px;
        cursor: pointer;

        & > span {
            @include font-style($size: 18px, $bold: 400, $color: $regent-gray);
        }
    }

    .notificationTooltip {
        bottom: 55px;
        left: -18px;

        @media (max-width: 575.98px) {
            left: 0;
        }
    }
}
