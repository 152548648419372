@import 'src/styles/variables';
@import 'src/styles/mixins';

.itemsContainer {
    position: absolute;
    background-color: $white;
    width: 124px;
    top: 36px;
    left: 0;
    padding: 8px;

    & :first-child {
        margin-top: 0;
    }

    div {
        cursor: pointer;
        padding: 2px 0 5px 3px;
        margin-top: 7px;

        span {
            @include font-style($size: 13px, $bold: 400, $color: $fiord);
        }

        &:hover {
            background-color: $catskill-white;

            span {
                font-weight: 700;
            }
        }
    }
}
