@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($align: center, $justify: center, $direction: column);
    background: $white;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
    margin-bottom: 10px;
    transition: 0.2s background-color;

    &:hover {
        background: $polar-white;
        box-shadow: 0 0 10px rgba(64, 107, 179, 0.2);
    }

    .mainRowInfo {
        @include display-flex($align: center);
        width: 100%;
        min-height: 56px;
        padding: 0px 12px;
        transition: 0.2s all;
        color: $fiord;
        position: relative;
        cursor: pointer;

        & > div > div {
            display: flex;
            align-items: center;

            & span {
                font-size: 12px;
                font-weight: 700;
            }
        }

        .rank {
            width: 4%;

            & > span {
                font-size: 14px;
                background: $polar;
                font-weight: 700;
                padding: 5px 12px;
                border-radius: 2px;
                color: $fiord;
            }
        }

        .organization {
            width: 16%;
            padding-right: 10px;

            & span {
                font-size: 14px;
                line-height: 19px;
                overflow-wrap: anywhere;
                white-space: pre-wrap;
            }

            &Logo {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .premium {
                @include display-flex($align: center, $justify: center);
                padding: 2px 10px;
                background: #447CF7;
                border-radius: 12px;
                margin-left: auto;

                .text {
                    @include font-style($size: 10px, $bold: 700, $color: $white, $transform: uppercase);
                    white-space: nowrap;
                }
            }
        }

        .treasury {
            width: 9%;

            .treasuryValue {
                font-size: 14px;
            }
        }

        .changesPerDay {
            @include display-flex($align: center);
            width: 9%;
            position: relative;

            &::before {
                content: '';
                width: 1px;
                height: 100%;
                background-color: $mystic;
                position: absolute;
                right: 20px;
            }

            .arrowIcon {
                transform: rotate(45deg);
                font-size: 20px;
                margin-left: -3px;
                margin-bottom: -1px;
                color: $bright-turquoise;
            }

            .arrowIconNegative {
                color: $lavender-magenta;
                transform: rotate(135deg);
            }

            .percentageValue {
                font-size: 14px;
            }
        }

        .topTokens {
            width: 16%;
            padding-right: 40px;
        }

        .tokensNotFound {
            font-size: 14px;
            font-weight: 700;
        }

        .mainTreasuryChain {
            @include display-flex($align: center);
            width: 10%;
            padding-left: 10px;
            position: relative;

            &::before {
                content: '';
                width: 1px;
                height: 30px;
                background-color: $mystic;
                position: absolute;
                right: 35px;
            }

            .chainLogoWrapper {
                @include display-flex($align: center);

                .chainLogo {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                }
            }
        }

        .potentialVoters {
            width: 13%;

            & span {
                margin-left: 5px;
            }

            .memberIcon {
                width: 15px;
                height: 15px;
            }
        }

        .activeMembers {
            width: 8%;

            & span {
                margin-left: 5px;
            }

            .memberIcon {
                width: 15px;
                height: 15px;
            }
        }

        .votes {
            width: 8%;

            & span {
                margin-left: 5px;
            }

            .votesIcon {
                width: 13px;
                height: 16px;
            }
        }

        .proposals {
            width: 8%;

            & span {
                margin-left: 5px;
            }

            .proposalIcon {
                width: 13px;
                height: 16px;
            }
        }

        .expandButton {
            width: 30px;
            height: 30px;
            min-width: unset;
            background-color: $selago;
            border-radius: 3px;

            & svg {
                @include font-style($size: 36px, $color: $dodger-blue-darker);
            }
        }

        @media (max-width: 575.98px) {
            .organization {
                width: 15%;
            }

            .topTokens {
                width: 18%;

                span {
                    overflow: visible;
                }
                // width: 16%;
            }

            .changesPerDay {
                width: 10%;
            }

            .treasury {
                width: 8%;
                // width: 10%;
            }

            .votes,
            .proposals,
            .activeMembers {
                width: 9%;
                // width: 8%;
            }
        }
    }

    .subOrganizationsContainer {
        width: 100%;
        margin-bottom: 10px;
        padding: 0px 12px;

        & > div:last-child {
            border-bottom: none;
        }
    }
}
