@import 'src/styles/variables';
@import 'src/styles/mixins';

.pageWrapper {
    @include display-flex($direction: column, $justify: space-between);
    min-height: 100vh;
    height: 100%;

    .pageContainer {
        @include display-flex($grow: 1);

        .pageContentWrapper {
            @include display-flex($direction: column, $justify: space-between);
            padding-left: $sidebar-width;
            width: 100%;

            * {
                font-family: $font-inter;
            }

            .headerAndContent {
                @include display-flex($direction: column);
                flex-grow: 1;
                width: 100%;
                height: 100%;
            }

            @media (max-width: 991.98px) {
                padding-left: 0 !important;
            }
        }

        .sidebarIsExpanded {
            padding-left: $expanded-sidebar-width;

        }
    }

    .pageHeaderDisabled {
        margin-top: 0;
        min-height: 100vh;
    }

    .backgroundGhostWhite {
        background-color: $ghostwhite;
    }

    .backgroundWhite {
        background-color: $white;
    }

    .backgroundBlue {
        background-color: $dodger-blue-darker;
    }
}
