@import 'src/styles/variables';
@import 'src/styles/mixins';

.notificationsListWrapper {
    width: 220px;
    background-color: $white;
    border: 2px solid $dodger-blue-darker;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(0, 102, 246, 0.3);
    padding: 10px;
    position: absolute;
    right: -85px;
    top: 60px;
    z-index: 5;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 108px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 9px solid $dodger-blue-darker;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 110px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid $white;
    }

    .notificationsListContainer {
        max-height: 460px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(51, 123, 255, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $nepal;
        }

        .title {
            @include font-style($size: 12px, $bold: 700, $color: $fiord, $transform: uppercase);
        }

        .oldNotificationsTitle {
            margin-top: 14px;
        }

        .emptyNotificationsMessage {
            @include font-style($size: 16px, $bold: 500, $color: $fiord);
        }

        .dotsLoader {
            margin-top: 10px;
        }
    }

    @media (max-width: 767.98px) {
        width: 350px;
        right: -47px;
        top: 48px;

        &::before,
        &::after {
            display: none;
        }
    }

    @media (max-width: 575.98px) {
        width: 280px;
    }
}
