@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;

    .header {
        margin: 8px 0 0 6px;
        font-size: 14px;
        font-weight: 600;
        color: $white;
    }

    .graph {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        margin-top: 16px;

        .xAxisTick {
            font-size: 10px;
            font-weight: 500;
            fill: $white;
        }

        .bar {
            .barLabel {
                font-size: 24px;
                font-weight: 500;
                fill: $white;

                * {
                    font-family: $font-quicksand;
                }
            }
        }
    }
}
