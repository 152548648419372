@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    margin-left: 20px;

    .button {
        @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
        width: 90px;
        height: 40px;
        border: 2px solid $bright-turquoise;;
        background-color: $bright-turquoise;
        transition: 0.3s;

        &:hover {
            background-color: $lightseagreen;
            border-color: $lightseagreen;
        }
    }

    @media (max-width: 991.98px) {
        margin-left: 14px;
    }

    @media (max-width: 575.98px) {
        margin-left: 10px;

        .button {
            font-size: 12px;
            height: 30px;
            width: 60px;
        }
    }
}
