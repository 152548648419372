@import 'src/styles/variables';
@import 'src/styles/mixins';

.substormsWrapper {
    margin-left: 34px;

    .substormsList {
        max-height: 190px;
        overflow-y: auto;
        padding-right: 20px;
        margin-top: 10px;

        & > div:first-child {
            margin-top: 0;
        }

        &::-webkit-scrollbar {
            width: 3px;
            background-color: $catskill-white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $nepal;
        }

        .substorm {
            margin-top: 10px;
            cursor: pointer;
            user-select: none;
            overflow: hidden;

            .title {
                @include font-style($size: 14px, $bold: 400, $color: $fiord);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @media (max-width: 575.98px) {
                margin-top: 12px;

                .title {
                    font-size: 16px;
                }
            }
        }

        .substormSelected {
            .title {
                font-weight: 700;
            }
        }

        @media (max-width: 575.98px) {
            max-height: 255px;
        }
    }

    @media (max-width: 575.98px) {
        margin-left: 38px;
    }
}
