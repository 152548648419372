@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;

    .container {
        padding: 60px 20px;

        .title {
            @include font-style($size: 40px, $bold: 500, $color: $fiord, $family: $font-koho);
        }

        .optionsContainer {
            margin-top: 40px;
        }
    }
}

.sectionTitle {
    @include font-style($size: 20px, $bold: 700, $lineHeight: 24px, $color: $fiord);

    .planName {
        text-transform: capitalize;
    }

    .titleLink {
        .linkText {
            color: $fiord;
            transition: 0.3s color;

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }
}

.descriptionText {
    @include font-style($size: 16px, $bold: 400, $lineHeight: 24px, $color: $fiord);
    overflow-wrap: anywhere;
}

.boldText {
    font-weight: 700;
}

.descriptionTextMarginTop {
    margin-top: 4px;
}

.mail {
    & > span {
        @include font-style($size: 16px, $bold: 400, $lineHeight: 24px, $color: $fiord);
    }
}

.buttonGreen {
    @include font-style(
        $size: 16px !important,
        $bold: 500 !important,
        $color: $white !important,
        $transform: none !important
    );
    width: 100px;
    height: 44px;
    background-color: $bright-turquoise !important;

    &:disabled {
        background-color: $catskill-white !important;
        color: $fiord !important;
    }
}

.link {
    padding: 4px 0;
    white-space: nowrap;

    &Title {
        @include font-style($size: 16px, $bold: 400, $color: $fiord, $transform: none);
        white-space: nowrap;
        position: relative;
        transition: 0.3s color;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $fiord;
            bottom: -1px;
            transition: 0.3s color;
        }
    }

    .arrowIcon {
        @include font-style($size: 12px, $color: $fiord);
        transform: rotate(180deg);
        margin-top: 2px;
        transition: 0.3s color;
    }

    &:hover {
        background-color: transparent !important;

        .linkTitle,
        .arrowIcon {
            color: $dodger-blue-darker;

            &::before {
                background-color: $dodger-blue-darker;
            }
        }
    }
}

.apiLinksWrapper {
    @include display-flex($align: flex-start, $direction: column);

    & > a:last-child {
        margin-top: 10px;
    }

    .textLink {
        @include font-style($size: 16px, $color: $fiord);
        transition: 0.3s color;

        &:hover {
            color: $dodger-blue-darker;
        }
    }
}

.alignPositionCenter {
    align-items: center;
}

.alignPositionBottom {
    align-items: flex-end;
}
