@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex();
    padding: 0 14px 12px;

    & > div {
        @include display-flex($align: center);

        & > div {
            @include display-flex($align: center);

            span {
                @include table-header-font-styles();
            }
        }
    }

    .rank {
        @include display-flex();
        width: 6%;
        font-size: 20px;
    }

    .tokenName {
        width: 30%;
    }

    .symbol {
        width: 25%;
    }

    .balance {
        width: 25%;
    }

    .people {
        width: 14%;
    }

    @media (max-width: 767.98px) {
        .rank {
            width: 10%;
        }

        .tokenName {
            width: 30%;
        }

        .symbol {
            width: 18%;
        }

        .balance {
            width: 23%;
        }

        .people {
            width: 19%;
        }
    }
}
