@import 'src/styles/variables';
@import 'src/styles/mixins';

.boldText {
    font-weight: 700;
}

.resendEmailButton {
    @include font-style($size: 14px!important, $bold: 400!important, $color: $fiord!important, $transform: none!important);
    width: 140px !important;
    height: 30px !important;
    border: 1px solid $fiord !important;
    margin-bottom: 14px !important;
}
