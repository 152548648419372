@import 'src/styles/variables';
@import 'src/styles/mixins';

.drawerRoot {
    background-color: rgba($black, 0.1);
}

.drawerPaper {
    border-radius: 20px 20px 0 0;
}

.wrapper {
    padding: 45px 60px 35px;

    .membersHeader {
        @include display-flex($align: center);
        padding-bottom: 10px;
        border-bottom: 1px solid $periwinkle;
        margin-bottom: 20px;

        .button {
            min-width: unset;
            margin-left: -10px;

            .arrowIcon {
                @include font-style($size: 22px, $color: $fiord);
                transform: rotate(180deg);
            }
        }

        .text {
            @include font-style($size: 18px, $bold: 600, $color: $fiord);
            margin-left: 8px;
            margin-bottom: 2px;
        }
    }

    .titleSection {
        padding-bottom: 20px;
        border-bottom: 1px solid $periwinkle;

        .title {
            @include font-style($size: 18px, $bold: 600, $color: $fiord);
        }
    }

    .dateFilterBreakPointsList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        row-gap: 14px;
        padding: 20px 0;
        border-bottom: 1px solid $periwinkle;

        .breakPoint {
            @include display-flex();
            @include font-style($size: 16px, $bold: 400, $color: $fiord);

            &:nth-child(even) {
                justify-content: flex-end;
            }
        }
    }

    .customDatesWrapper {
        .customDates {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            margin-top: 20px;

            .dateFieldWrapper {
                & > p {
                    @include font-style($size: 10px, $bold: 700, $color: $fiord, $transform: uppercase);
                }

                .dateField {
                    @include font-style($size: 14px, $bold: 400, $color: $nepal);
                    width: 100%;
                    height: 36px;
                    margin-top: 6px;
                    background-color: $ghostwhite;
                    border: 1px solid $periwinkle;
                    border-radius: 3px;
                    padding: 4px 8px;

                    &:focus {
                        outline: none;
                    }
                }

                [type='date']::-webkit-inner-spin-button {
                    display: none;
                }
                [type='date']::-webkit-calendar-picker-indicator {
                    display: none;
                }
            }
        }

        .applyButton {
            @include font-style($size: 16px, $bold: 700, $color: $white, $transform: none);
            width: 100%;
            height: 44px;
            margin-top: 20px;
            background-color: $bright-turquoise;

            &:disabled {
                background-color: $heather;
            }
        }
    }

    .commonFiltersWrapper {
        & > div:first-child {
            margin-top: 20px;
        }

        .orderFilter {
            @include font-style($size: 16px, $bold: 400, $color: $fiord);
            @include display-flex($align: center);
            cursor: pointer;
            user-select: none;
            margin-bottom: 20px;

            .arrowIcon {
                font-size: 24px;
            }

            .diamondIcon {
                width: 24px;
                height: 24px;
            }

            .discussionsFilterIcon {
                font-size: 24px;
            }

            .orderDesc {
                transform: rotate(90deg);
            }

            .orderAsc {
                transform: rotate(-90deg);
            }

            .votersOrderAsc {
                transform: rotate(180deg);
            }

            & > span {
                margin-left: 14px;
            }
        }
    }

    .orderFiltersWrapper {
        padding-top: 20px;
        border-top: 1px solid $periwinkle;

        &:first-child {
            background-color: red;
        }

        .orderByNumberOfMembersHeader {
            @include font-style($size: 12px, $transform: uppercase, $bold: 700, $color: $fiord);
        }

        .container {
            @include display-flex($align: center, $justify: space-between);
            margin-top: 14px;

            .orderByNumberOfMembersDesc,
            .orderByNumberOfMembersAsc,
            .orderByNumberOfTokensDesc,
            .orderByNumberOfTokensAsc {
                @include font-style($size: 16px, $transform: capitalize, $bold: 400, $color: $fiord);
                @include display-flex($align: center);
                cursor: pointer;
                user-select: none;

                & > img {
                    width: 24px;
                    height: 24px;
                }

                & > span {
                    margin-left: 14px;
                }
            }
        }
    }

    .orderFiltersWrapperFirstBlock {
        padding-bottom: 20px;
    }

    .bold {
        font-weight: bold !important;
    }
}
