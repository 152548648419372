@import 'src/styles/variables';
@import 'src/styles/mixins';

.descriptionSection {
    margin-top: 8px;

    .description {
        @include font-style($size: 14px, $bold: 500, $lineHeight: 21px, $color: $fiord);
        overflow-wrap: anywhere;
        word-break: break-word;

        @media (max-width: 575.98px) {
            font-size: 12px;
            line-height: 18px;
        }

        a {
            color: $dodger-blue-darker;
            font-weight: 600;
        }
    }

    .fullDescription {
        white-space: pre-wrap;
    }

    .expandText {
        @include font-style($size: 12px, $bold: 700, $color: $fiord);
        margin-top: 8px;
        cursor: pointer;
    }
}
