@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.content {
    background-color: #D7E4F4;
    padding: 16px;
    border-radius: 16px;

    .headSection {
        @include display-flex($align: center, $justify: space-between);

        .textSection {
            @include display-flex($align: center);

            .infoIcon {
                margin: 0 8px;
            }

            & > h4 {
                @include font-style($size: 16px, $bold: 700, $color: #1F3B82);
            }

            & > h5 {
                @include font-style($size: 12px, $bold: 450, $color: $fiord);
            }
        }
    }

    .tableWrapper {
        margin-top: 20px;
        overflow-x: scroll;

        .tableContainer {
            background-color: $white;
            border-radius: 6px;
            border: 1px solid #B0C2CD;

            .rowsWrapper {
                max-height: 530px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f0f0f0;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #ccc;
                }
            }
            .stretchedWrapper {
                max-height: unset;
            }
        }
    }

    .loaderBox {
        position: static;
        padding: 20px 0;
    }
}