.organizationInfoWrapper {
    margin-top: 80px;

    .organizationFieldsWrapper {
        margin-top: 35px;

        .inputsWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 60px;
        }
    }
}
