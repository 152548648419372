@import 'src/styles/variables';
@import 'src/styles/mixins';

.labelsContainer {
    @include display-flex($direction: column);
    padding: 15px 0;
    border-bottom: 1px solid $heather;

    .labelHeader {
        @include display-flex();

        .labelTitle {
            @include font-style($size: 14px, $bold: 700, $color: $fiord);
        }
    }

    .labelsWrapper {
        @include display-flex($align: center, $wrap: wrap);

        .labelContent {
            @include display-flex($align: center);
            @include font-style($size: 14px, $bold: 400, $color: $dodger-blue-darker);
            margin-top: 8px;
            margin-right: 15px;
            cursor: pointer;
            transition: 0.3s color;

            & :last-child {
                font-size: 7px;
                margin-left: 3px;
            }

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }
}
