@import 'src/styles/variables';
@import 'src/styles/mixins';

.feedProposalInfoContainer {
    width: 100%;
    max-width: 350px;
    margin-left: 30px;

    .card {
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;

        &Header {
            @include display-flex($align: center);
            padding: 25px 25px 20px;
            background-color: $link-water;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .avatar {
                width: 52px;
                height: 52px;
                border-radius: 50%;
            }

            .userName {
                @include font-style($size: 14px, $bold: 700, $color: $fiord);
                margin: 0;
                text-decoration: none;
                margin-left: 10px;
                overflow-wrap: anywhere;
                transition: 0.3s color;

                &:hover {
                    color: $dodger-blue-darker;
                }
            }
        }

        .statusInfo {
            @include display-flex($align: center, $justify: space-between);
            padding: 30px 0 30px 25px;
            background-color: $white;

            .statusHeading {
                @include display-flex($align: center);
                @include font-style($size: 15px, $bold: 700, $color: $fiord);

                & > h4 {
                    font-size: 15px;
                    text-transform: uppercase;
                }

                & > span {
                    color: $nepal;
                    margin-left: 5px;
                }
            }

            .acceptedStatus {
                & > div {
                    margin-left: 4px;
                    margin-top: -6px;
                }
            }

            .rejectedStatus {
                & > div {
                    margin-left: 4px;
                    margin-bottom: -12px;
                }
            }
        }

        .mainInfo {
            padding: 30px 20px 30px 25px;
            background-color: $white;
            border-top: 1px solid $catskill-white;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            & > div {
                @include display-flex($align: center, $justify: space-between);

                & > span {
                    @include font-style($size: 14px, $bold: 400, $color: $regent-gray);
                }

                & > h5 {
                    @include font-style($size: 14px, $bold: 700, $color: $fiord);
                }

                &:last-child {
                    margin-top: 15px;
                }
            }
        }
    }

    .originalSourceNavigation {
        @include display-flex($justify: space-between);
        width: 100%;
        height: 44px;
        padding: 10px 10px 10px 15px;
        margin-top: 30px;
        color: $dodger-blue-darker;
        border: 2px solid $dodger-blue-darker;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color ease-out 0.25s;

        .title {
            @include font-style($transform: none, $bold: 600);
        }

        .arrowIcon {
            font-size: 24px;
            margin-top: -2px;
            transition: transform ease-out 0.25s;
        }

        &:hover {
            background-color: $white;

            .arrowIcon {
                transform: translateX(6px);
            }
        }
    }

    .navigationInactive {
        cursor: not-allowed;

        &:hover {
            background-color: transparent;

            .arrowIcon {
                transform: none;
            }
        }
    }

    @media (max-width: 767.98px) {
        margin-left: 0;
        max-width: 450px;
        padding: 0 16px;
    }
}
