@import 'src/styles/variables';
@import 'src/styles/mixins';

.navigationItems {
    .title {
        @include font-style($size: 14px, $bold: 600, $color: $fiord);
    }

    .linksContainer {
        @include display-flex($direction: column);
        margin-top: 12px;

        .link {
            @include font-style($size: 14, $bold: 400, $color: $regent-gray);
            padding: 5px 0 5px 20px;

            &:hover {
                color: $dodger-blue-darker;
            }
        }

        .linkActive {
            color: $white;
            background-color: $dodger-blue-darker;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &:hover {
                color: $white;
            }
        }
    }
}
