@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    background-color: $white;
    border-bottom: 1px solid $mystic;

    .tabsContainer {
        @include display-flex();
        @include max-device-width();
        padding: 0 85px;

        .tabItem {
            @include display-flex($align: center, $justify: center);
            text-align: center;
            width: 50%;
            height: 62px;
            cursor: pointer;
            position: relative;
            transition: 0.5s all;
            border-right: 1px solid $mystic;

            @media (max-width: 767.98px) {
                width: 100%;
                height: 44px;
            }

            &:hover {
                & > div {
                    & > span {
                        color: $bright-turquoise;
                    }
                }
            }

            &:first-child {
                border-left: 1px solid $mystic;
            }

            .itemContent {
                @include display-flex($align: center, $justify: space-between);
                width: 100%;
                padding: 13px 15px 13px 30px;

                & > span {
                    @include font-style($size: 16px, $bold: 500, $color: $fiord);
                    transition: 0.3s color;
                }

                @media (max-width: 991.98px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media (max-width: 767.98px) {
                    justify-content: center;

                    & > span {
                        @include font-style($size: 14px, $bold: 600);
                        text-transform: capitalize;
                    }
                }
            }
        }

        .activeTab {
            & > div {
                & > span {
                    color: $bright-turquoise !important;
                }
            }

            &::before {
                content: '';
                position: absolute;
                bottom: -1px;
                width: 100%;
                height: 3px;
                background-color: $bright-turquoise;
            }
        }

        @media (max-width: 991.98px) {
            padding: 0;
        }
    }
}
