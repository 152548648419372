@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($direction: column);
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(51, 123, 255, 0.3);
    border-radius: 12px;
    padding: 24px;

    .planName {
        @include font-style(
            $size: 40px,
            $bold: 700,
            $lineHeight: 44px,
            $color: $fiord-darker,
            $family: $font-koho !important
        );
    }

    .contactBlock {
        @include font-style($size: 18px, $bold: 600, $lineHeight: 38px, $color: $fiord-darker);
        margin-top: 8px;

        .mail {
            color: $fiord-darker;
            position: relative;
            transition: 0.3s color;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: $fiord-darker;
                transition: 0.3s background-color;
            }

            &:hover {
                color: $dodger-blue-darker;

                &::before {
                    background-color: $dodger-blue-darker;
                }
            }
        }
    }

    .pricingInfo {
        @include display-flex($align: flex-end);
        margin-top: 8px;

        & > h2 {
            @include font-style(
                $size: 32px,
                $bold: 600,
                $lineHeight: 38px,
                $color: $fiord-darker,
                $family: $font-koho !important
            );
        }

        & > h4 {
            @include font-style($size: 18px, $bold: 400, $lineHeight: 22px, $color: $fiord-darker);
            margin-bottom: 2px;
            margin-left: 4px;
        }
    }

    .planDescription {
        @include font-style($size: 14px, $bold: 700, $lineHeight: 21px, $color: $fiord-darker);
        margin-top: 30px;
    }

    .selectPlanButton {
        @include font-style($size: 18px, $bold: 700, $lineHeight: 21px, $color: $white, $transform: none);
        width: 100%;
        height: 40px;
        background-color: $bright-turquoise;
        margin-top: 30px;

        &:hover {
            background-color: $bright-turquoise;
        }

        &:disabled {
            background-color: transparent;
            color: $pattens-blue;
            border: 2px solid $pattens-blue;
        }
    }

    .infoSection {
        margin-top: 0;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-left: 15px;

        & > li {
            @include font-style($size: 14px, $bold: 400, $lineHeight: 21px, $color: $fiord-darker);
            margin-top: 12px;

            & > a {
                color: $fiord;
                position: relative;
                transition: 0.3s color;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $fiord-darker;
                    transition: 0.3s background-color;
                }

                &:hover {
                    color: $dodger-blue-darker;

                    &::before {
                        background-color: $dodger-blue-darker;
                    }
                }
            }

            &::marker {
                color: $bright-turquoise;
                font-size: 18px;
            }

            &:first-child {
                margin-top: 0;
            }
        }

        .bold {
            font-weight: 700;
        }
    }

    .feedbackBlock {
        @include display-flex($justify: flex-end, $grow: 1, $direction: column);

        .notification {
            @include font-style($size: 14px, $bold: 700, $lineHeight: 21px, $color: $fiord-darker);

            .mail {
                color: $fiord-darker;
                position: relative;
                transition: 0.3s color;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $fiord-darker;
                    transition: 0.3s background-color;
                }

                &:hover {
                    color: $dodger-blue-darker;

                    &::before {
                        background-color: $dodger-blue-darker;
                    }
                }
            }
        }
    }
}
