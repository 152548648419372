.statusWrapper {
    margin-left: 20px;

    .imageStatus {
        width: 23px;
        height: 26px;
    }
}

.feedStatus {
    margin-left: auto;
    margin-right: 10px;

    @media (max-width: 575.98px) {
        margin-left: 10px;

        .imageStatus {
            height: 16px;
            width: 14px;
        }
    }
}

.removeForMobile {
    @media (max-width: 575.98px) {
        display: none;
    }
}
