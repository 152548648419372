@import 'src/styles/variables';
@import 'src/styles/mixins';

.tokensInfo {
    .title {
        @include font-style($size: 10px, $bold: 700, $color: $nepal);
        margin: 0;
    }

    .tokensList {
        margin-top: 6px;

        .tokenImage {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 5px;
        }

        svg {
            color: $nepal;
        }
    }
}
