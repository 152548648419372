@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    background-color: $white;
    padding-left:0;
    padding-right: 15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid $catskill-white;
}
.tableHeader {
    padding: 10px 25px;
    background-color: $white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-right: 30px;
    .headerItems {
        @include display-flex();
        & > div {
            @include display-flex($align: center);

            & > div {
                @include display-flex($align: center);

                span {
                    @include table-header-font-styles();
                }
            }
        }

        .tableColumn {
          width: 25%;
        }
        .tokenPercentageColumn {
            width: 30%;
        }
        .tokenProposalColumn {
            width: 20%;
        }
        @media (max-width: 767.98px) {
            padding: 8px 8px 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
}
