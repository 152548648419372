@import 'src/styles/variables';
@import 'src/styles/mixins';

.mobileFiltersWrapper {
    padding-bottom: 16px;
    border-bottom: 1px solid $periwinkle;

    .filterHeader {
        @include display-flex($align: center, $justify: space-between);
        position: relative;

        .title {
            @include font-style($size: 18px, $bold: 600, $color: $fiord);
        }

        .mainFiltersContainer {
            @include display-flex();

            .buttonWrapper {
                border-right: 1px solid $periwinkle;
                padding: 0 14px;

                .button {
                    min-width: unset;
                    border-radius: 50%;
                    padding: 0;

                    .filterIcon,
                    .dateIcon {
                        @include font-style($size: 22px, $color: $fiord);
                    }
                }
            }

            .orderFilter {
                @include display-flex($align: center, $justify: center);
                @include font-style($size: 14px, $color: $fiord, $bold: 400);
                cursor: pointer;
                user-select: none;
                margin-left: 10px;
                margin-right: 6px;

                .arrowIcon {
                    font-size: 20px;
                }

                .text {
                    margin-left: 4px;
                }

                .orderDesc {
                    transform: rotate(90deg);
                }

                .orderAsc {
                    transform: rotate(-90deg);
                }
            }
        }

        .calendar {
            position: absolute;
            left: 0;
            top: 40px;
        }
    }

    .bold {
        font-weight: bold !important;
    }

    .selectedFiltersContainer {
        @include display-flex($wrap: wrap);
    }
}
