@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px 50px;
    max-width: 1270px;

    .metadata {
        padding: 40px 0 0 0;

        .data {
            display: block;
            flex-direction: column;
            overflow: hidden;
            background-color: #D7E4F4;
            padding: 16px;
            border-radius: 16px;
        }
    }

    .sectionTitle {
        @include display-flex($align: center);

        .infoIcon {
            @include font-style($size: 22px, $color: #1F3B82);
            cursor: pointer;
            margin-left: 15px;
            transition: color 0.2s;

        }

        h4 {
            @include font-style($size: 24px, $bold: 700, $color: #1F3B82);
            padding: 20px 0;

            @media (max-width: 767.98px) {
                font-size: 16px;
            }
        }
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @media (max-width: 1310px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    .gridItem {
        background-color: #fff;
        border: 1px solid $lightblue;
        border-radius: 8px;
        overflow: hidden;

        .organizationsStatsCardContainer {
            display: flex;
            flex-direction: column;
            background: linear-gradient(180deg, #337bff 34.12%, #1db3d2 100%);

            .organizationsTitleCard {
                margin-left: 20px;
            }

            & .organizationsStatsCardWrapper {
                & > hr {
                    border: 1px solid $lightblue;
                    width: 100%;
                    margin: 0;
                }

                & > div[class*='card']:first-child div[class*='Body'] {
                    border-radius: unset;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }

                & > div[class*='card']:last-child div[class*='Body'] {
                    border-radius: unset;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }

                & > div {
                    &:first-child {
                        height: 125px;
                    }

                    &:last-child {
                        height: 140px;
                    }
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        .gridContainer {
            grid-template-columns: 1fr;
            gap: 16px;
        }

        padding-left: 15px;
        padding-right: 15px;
    }
}
