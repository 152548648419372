@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 18px 20px 18px;

    & > div {
        @include table-header-font-styles();
        color: #447CF7;
    }

    .timePeriod {
        width: 20%;
    }

    .votingToken {
        width: 20%;
    }

    .usdValue {
        width: 20%;
    }

    .stablecoins {
        width: 20%;
    }

    .ethBtcTokens {
        width: 20%;
    }
}
