@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    min-height: 172px;
    box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
    background-color: $white;
    margin-top: 20px;

    &Header {
        @include display-flex($justify: space-between, $align: flex-start, $direction: column);

        .head {
            width: 100%;
            @include display-flex($justify: flex-end);
            padding: 10px;

            .date {
                @include font-style($size: 10px, $bold: 500, $color: $regent-gray);
            }
        }

        .details {
            @include display-flex($align: center);
            width: 100%;

            .organizationDetails,
            .userDetails {
                @include display-flex($align: center);
            }

            .iconLink {
                @include display-flex($align: center);

                .image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-left: 0;
                }
            }

            .arrowRight {
                @include font-style($size: 36px, $color: $nepal);
                transform: rotate(-90deg);
            }

            .organizationInfo {
                @include display-flex($align: flex-start, $direction: column);
                margin-left: 10px;

                .proposalType {
                    @include font-style($size: 12px, $bold: 400, $lineHeight: 18px, $color: $regent-gray);
                }
            }

            .organizationName,
            .userName {
                @include font-style($size: 14px, $bold: 700, $lineHeight: 21px, $color: $fiord, $decoration: none);
                cursor: pointer;
                overflow-wrap: anywhere;
                transition: color 0.3s;
                min-width: 70px;
                word-break: break-word;

                &:hover {
                    color: $dodger-blue-darker;
                }
            }

            .userName {
                margin: 0 10px;
            }
        }

        @media (max-width: 575.98px) {
            .head {
                justify-content: flex-start;
                align-items: center;
                padding-top: 6px;
                padding-bottom: 6px;

                .proposalType {
                    @include display-flex($align: center, $justify: center);
                    background-color: $light-blue;
                    border-radius: 2px;

                    & > svg {
                        @include font-style($size: 17px, $color: $white);
                    }
                }

                .discussionType {
                    @include display-flex($align: center, $justify: center);
                    width: 18px;
                    height: 20px;

                    & > svg {
                        @include font-style($size: 20px, $color: $light-blue);
                    }
                }

                .date {
                    margin-left: auto;
                }
            }

            .details {
                padding: 10px 10px 0;
                position: relative;

                &::before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: $catskill-white;
                }

                .iconLink {
                    .image {
                        width: 28px;
                        height: 28px;
                    }
                }

                .feedCardIcon {
                    display: none;
                }
            }
        }
    }

    &Body {
        @include display-flex();
        margin-top: 11px;
        padding-bottom: 20px;

        .textContainer {
            @include display-flex($align: flex-start, $direction: column);
            margin-left: 60px;
            margin-right: 40px;

            .title {
                @include font-style($size: 17px, $bold: 700, $lineHeight: 25px, $color: $fiord);
                overflow-wrap: anywhere;
                word-break: break-word;
                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                    color: $dodger-blue-darker;
                }
            }

            .description {
                @include font-style($size: 14px, $bold: 400, $lineHeight: 21px, $color: $regent-gray);
                overflow-wrap: anywhere;
                word-break: break-word;

            }

            .fullDescription {
                white-space: pre-wrap;
            }

            .expandOptions {
                @include display-flex($align: center);
                margin-top: 10px;
                cursor: pointer;

                .expandText {
                    @include font-style($size: 16px, $bold: 700, $lineHeight: 21px, $color: $fiord);
                }

                .expandMoreIcon {
                    color: $fiord;
                    transition: transform 0.3s;
                    margin-bottom: -2px;
                }

                .expandLessIcon {
                    transform: rotate(180deg);
                }
            }
        }

        @media (max-width: 575.98px) {
            padding-bottom: 10px;

            .textContainer {
                margin: 0;
                padding-left: 10px;
                padding-right: 10px;

                .title {
                    font-size: 14px;
                }

                .description {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    @media (max-width: 575.98px) {
        min-height: auto;
    }
}
