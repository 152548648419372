@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include max-device-width();
    @include display-flex($grow: 1, $direction: column);
    width: 100%;
}

.backgroundWhite {
    background-color: $white;
}
