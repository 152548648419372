@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;

    .header {
        @include display-flex($align: center, $justify: space-between);
        width: 100%;
        height: $header-tablet-height;
        padding: 10px 20px;
        background: $white;

        .logoContainer {
            @include display-flex($align: center);

            .logo {
                width: 212px;
                height: 55px;

                @media (max-width: 767.98px) {
                    width: 123px;
                    height: 34px;
                }

                @media (max-width: 399.98px) {
                    width: 100px;
                    height: 30px;
                }
            }
        }

        .options {
            @include display-flex($align: center);

            .menuButton {
                min-width: unset;
                border-radius: 50%;

                .menuIcon {
                    @include font-style($size: 40px, $color: $fiord);

                    @media (max-width: 767.98px) {
                        font-size: 30px;
                    }
                }
            }
        }

        .optionsFullWidth {
            width: 100%;
        }

        @media (max-width: 767.98px) {
            height: $header-mobile-height;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
