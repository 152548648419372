@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
    margin-top: 10px;
    border-radius: 6px;
    overflow-x: scroll;

    .tableContainer {
        width: 620px;
        background: $white;

        .giniIndexInfo {
            @include font-style($size: 15px, $bold: 600, $color: $fiord);
            padding: 27px 20px;

            span {
                @include font-style($size: 14px, $bold: 400);
                margin-left: 23px;
            }
        }
    }
}
