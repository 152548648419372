@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    display: flex;
    align-items: center;
    padding: 11px 25px 13px;
    margin-right: 30px;

    & > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: $fiord;

        .avatar {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }
    }
    .tableColumn {
        width: 30%;
    }
    .organizationColumn {
        width: 40%;
    }
    .holderInfo {
        @include display-flex($direction: column);
        margin-left: 8px;
        overflow: hidden;
        padding-right: 20px;

        .holderName {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #3d536c;
        }

        .chain {
            font-size: 10px;
            font-weight: 500;
            margin: 0;
            margin-top: 2px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

    }
 

    @media (max-width: 767.98px) {
        padding-left: 8px;
        padding-right: 8px;
    }
}
