@import 'src/styles/variables';
@import 'src/styles/mixins';

.contentWrapper {
    @include display-flex($justify: center);

    .statsGraphCardsWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;

        .lineGraph {
            height: 90px;
            margin-top: 10px;
        }

        @media (min-width: 768px) and (max-width: 1199.98px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 767.98px) {
            grid-template-columns: 1fr;
            row-gap: 40px;
        }
    }
}

.statsCardWrapper {
    min-height: unset !important;
}

.pageContentWrapper {
    @include max-device-width();
    width: 100%;
    padding: 40px 16px;
    position: relative;
}
