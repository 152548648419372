@import 'src/styles/variables';
@import 'src/styles/mixins';

.carouselWrapper {
    max-width: 1230px;
    position: relative;
    margin: auto;
    padding-bottom: 35px;

    .title {
        @include font-style($size: 14px, $bold: 600, $color: $fiord);

        @media (max-width: 767.98px) {
            font-size: 16px;
            margin-left: 16px;
        }
    }

    .arrowButton {
        position: absolute;
        bottom: 65px;
        padding: 0;
        min-width: unset;

        &:hover {
            background-color: transparent;
        }

        svg {
            @include font-style($size: 34px, $color: $nepal);
        }

        @media (max-width: 767.98px) {
            display: none;
        }
    }

    .previous {
        left: -35px;

        svg {
            transform: rotate(90deg);
        }
    }

    .next {
        right: -35px;

        svg {
            transform: rotate(-90deg);
        }
    }

    .carouselContainer {
        position: relative;
        height: 98px;

        .itemsList {
            @include display-flex($align: center);
            height: 100%;
            margin-top: 8px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .shadowBlock {
            width: 70px;
            height: 100%;
            position: absolute;
            left: 0;
            background: linear-gradient(90.1deg, $dodger-blue-darker -37.75%, rgba(245, 248, 255, 0) 32.81%);
            opacity: 0.3;
            border-radius: 4px;
            z-index: 2;

            @media (max-width: 767.98px) {
                display: none;
            }
        }

        .shadowBlockRight {
            right: 0;
            left: auto;
            transform: rotate(-180deg);
        }
    }

    .loaderBox {
        @include display-flex($align: center, $justify: center);
        height: 98px;
        margin-top: 8px;

        .similarUsersLoader {
            position: static;
        }
    }
}
