@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($direction: column);
    height: 100%;
    min-height: 360px;
    box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.03);

        .cardBody {
            .mainInfo {
                .resourceName {
                    color: $dodger-blue-darker;
                }
            }

            .arrowContainer {
                .arrowIcon {
                    color: $dodger-blue-darker;
                    transform: translateX(12px);
                }
            }
        }
    }

    &Header {
        @include display-flex($align: center);
        background-image: url('../../assets/jpg/dao_tools_bg_image.jpg');
        width: 100%;
        height: 90px;
        background-size: cover;
        background-position: center;
        border-bottom: 1px solid $catskill-white;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;

        .logo {
            height: 40px;
            margin-left: 20px;
        }
    }

    &Body {
        @include display-flex($justify: space-between, $direction: column);
        flex-grow: 1;
        background-color: $white;
        padding: 20px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .mainInfo {
            .resourceName {
                @include font-style($size: 28px, $bold: 300, $color: $fiord, $family: $font-koho, $lineHeight: 30px);
                transition: 0.3s color;
            }

            .resourceCategoriesWrapper {
                @include display-flex($wrap: wrap);

                .category {
                    @include font-style($size: 10px, $bold: 500, $color: $fiord);
                    background-color: $lavender-lighter;
                    padding: 4px 8px;
                    margin: 8px 8px 0 0;
                }
            }

            .descriptionTextContainer {
                margin-top: 20px;

                & > span {
                    @include font-style($size: 14px, $bold: 500, $color: $regent-gray);
                }
            }
        }
        .arrowContainer {
            @include display-flex($justify: flex-end, $align: flex-end);
            padding-top: 10px;

            .arrowIcon {
                @include font-style($size: 32px, $color: $heather);
                margin-right: -5px;
                margin-left: auto;
                cursor: pointer;
                transition: all 0.3s;
            }
        }
    }
}
