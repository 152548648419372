@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    padding: 10px;
    overflow: scroll;

    .tableContainer {
        height: 220px;
        width: 580px;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
        position: relative;

        @media (max-width: 991.98px) {
            width: auto;
        }

        @media (max-width: 767.98px) {
            min-width: 600px;
        }
    }

    @media (max-width: 767.98px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.loaderBox {
    @include display-flex($align: center, $justify: center);
    margin-top: 15px;

    .decisionsLoader {
        position: static;
    }
}
