@import 'src/styles/variables';
@import 'src/styles/mixins';

.textareaWrapper {
    position: relative;
    margin-top: 35px;

    .textarea {
        @include font-style($size: 14px, $color: $fiord, $lineHeight: 21px);
        width: 100%;
        border: none;
        border-bottom: 1px solid $nepal;
        padding: 2px 12px 6px 2px;
        resize: vertical;
        overflow: hidden;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $nepal;
        }
    }

    .requiredField {
        padding-left: 16px;
    }

    .asteriskSymbol {
        @include font-style($size: 14px, $color: $dodger-blue-darker, $bold: 700);
        position: absolute;
        left: 1px;
    }

    .textWrapper {
        @include display-flex();
        position: absolute;
        right: 0;

        .error {
            @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
            margin-right: 8px;
        }

        .infoText{
            @include font-style($size: 10px, $color: $nepal, $bold: 500);
        }
    }
}
