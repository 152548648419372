@import 'src/styles/variables';
@import 'src/styles/mixins';

.buttonWrapper {
    @include display-flex($align: center);
    margin-left: 6px;
    position: relative;

    &:hover {
        .description {
            visibility: visible;
        }
    }

    .editButton {
        padding: 0;
        border-radius: 50%;
        min-width: unset;
        transition: 0.3s all;

        .editIcon {
            color: $heliotrope;
            font-size: 15px;
            transition: 0.3s all;
        }

        &:hover {
            border-color: $daisy-bush;
            background-color: $white;

            .editIcon {
                color: $daisy-bush;
            }
        }
    }

    .description {
        @include font-style($size: 10px, $bold: 500, $color: $regent-gray, $lineHeight: 15px);
        position: absolute;
        width: 165px;
        padding: 12px 8px 8px 15px;
        background-color: $white;
        filter: drop-shadow(0 0 12px rgba(51, 63, 112, 0.2));
        visibility: hidden;
        border: 1px solid $heliotrope-lighter;
        transform: translateX(-50%);
        left: 50%;
        bottom: 25px;
        border-radius: 4px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 46.5%;
            top: 100%;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid $heliotrope-lighter;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 48%;
            top: 100%;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid $white;
        }
    }
}
