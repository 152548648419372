@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeaderWrapper {
    padding: 18px 20px 10px;
    border-top: 4px solid $catskill-white;

    .indicesOwner {
        @include font-style($size: 15px, $bold: 700, $color: $fiord);
        margin: 0;
    }

    .tableHeader {
        display: flex;
        margin-top: 16px;

        & > div {
            @include display-flex($align: center);

            & > div {
                @include display-flex($align: center);

                span {
                    @include font-style($size: 14px, $bold: 700, $color: $nepal, $lineHeight: 14px);
                }
            }
        }

        .headerVoter {
            width: 41%;
        }

        .headerShares {
            width: 34%;
        }

        .headerIndex {
            width: 25%;
        }
    }
}
