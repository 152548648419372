@import 'src/styles/variables';
@import 'src/styles/mixins';

.subDaoRow {
    @include display-flex($align: center);
    height: 74px;
    color: $fiord;
    background-color: $athens-gray-darker;
    border-bottom: 1px solid $mystic;
    cursor: pointer;

    & > div {
        display: flex;
        align-items: center;

        & > span {
            font-size: 12px;
            font-weight: 700;
        }
    }

    .emptyBlock {
        width: 6%;
    }

    .organization {
        width: 19%;
        padding-right: 20px;
        overflow: hidden;

        .subtractIcon {
            width: 28px;
            height: 25px;
            margin-bottom: 6px;
        }

        .organizationInfo {
            @include display-flex($align: center);
            margin-left: 20px;

            .organizationLogo {
                width: 19px;
                height: 19px;
                border-radius: 50%;
            }

            .daoName {
                @include font-style($size: 14px, $bold: 700);
                margin-left: 8px;
            }
        }
    }

    .treasury {
        width: 11%;

        .tresuryBlock {
            display: flex;
            padding: 5px 9px;
            align-items: center;
            background: $polar;
            border-radius: 2px;

            & > span {
                font-size: 14px;
                font-weight: 700;
            }
        }

        svg {
            font-size: 20px;
            transform: rotate(45deg);
            color: $java;
            margin-right: 5px;
            margin-left: -5px;

            &.down {
                transform: rotate(135deg);
                color: $wild-watermelon;
            }
        }
    }

    .topTokens {
        width: 25%;
        padding-right: 60px;

        & > div {
            width: 100%;
            padding: 5px 0;
            border-right: 1px solid $mystic;
        }
    }

    .potentialVoters {
        width: 9%;

        & > span {
            margin-left: 5px;
        }

        .memberIcon {
            width: 15px;
            height: 15px;
        }
    }

    .activeMembers {
        width: 9%;

        & > span {
            margin-left: 5px;
        }

        .memberIcon {
            width: 15px;
            height: 15px;
        }
    }

    .votes {
        flex-grow: 1;

        & > span {
            margin-left: 5px;
        }

        .votesIcon {
            width: 13px;
            height: 16px;
        }
    }

    .proposals {
        width: 9%;

        & > span {
            margin-left: 5px;
        }

        .proposalIcon {
            width: 13px;
            height: 16px;
        }
    }

    .expandButton {
        width: 30px;
        height: 30px;
        min-width: unset;
        background-color: $selago;
        border-radius: 3px;

        & svg {
            @include font-style($size: 30px, $color: $dodger-blue-darker);
        }
    }
}

.subOrganizationsContainer {
    margin-top: 15px;
}
