@import 'src/styles/variables';
@import 'src/styles/mixins';

.socialContainer {
    margin-bottom: 60px;

    .social {
        &List {
            @include display-flex($wrap: wrap);
            margin-top: 20px;
        }

        &Item {
            @include display-flex($align: center);
            flex: 50%;
            margin-bottom: 20px;

            &Icon {
                margin-right: 13px;
            }

            &Input {
                width: 255px;

                input {
                    @include font-style(14px, $bold: 400, $color: $fiord);
                    padding: 3px 0;
                }
            }
        }
    }
    .button {
        width: 64px;
        height: 30px;
        background-color: $white-ice;
        color: $bright-turquoise;
        text-transform: none;

        &:hover {
            background-color: $white-ice;
        }
    }
}
