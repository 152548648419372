@import 'src/styles/mixins';

.positiveFeedback {
    @include display-flex($align: center);

    .passedIcon {
        width: 16px;
        height: 16px;
    }

    & > h4 {
        margin-left: 10px;
        line-height: normal;
    }
}
