@import 'src/styles/variables';
@import 'src/styles/mixins';

.sectionContent {
    @include display-flex($align: center, $justify: center, $direction: column);

    .textInfo {
        @include display-flex($align: center, $justify: center, $direction: column);

        .title {
            @include font-style($size: 40px, $bold: 500, $color: $fiord, $family: $font-koho);
        }

        .loggedUserOptions {
            @include font-style($size: 16px, $bold: 400, $color: $fiord);

            .loginLink {
                color: $dodger-blue-darker;
                font-weight: 700;
            }
        }
    }
}

.formContainer {
    max-width: 525px;
    background: $white;
    box-shadow: 0px 0px 20px rgba(51, 123, 255, 0.2);
    border-radius: 12px;
    padding: 40px;
    margin-top: 70px;

    .description {
        @include font-style($size: 20px, $bold: 500, $color: $fiord, $align: center);
    }
}

.accountHolderHeader {
    margin-top: 80px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.details {
    margin-top: 30px;
}

.phoneField {
    display: flex;

    @media (max-width: 575.98px) {
        flex-direction: column;
    }
}

.countryCodeInputWrapper,
.countryInputWrapper {
    width: 110px;

    @media (max-width: 575.98px) {
        width: 100%;
    }
}

.phoneInputWrapper,
.zipCodeInputWrapper {
    flex: 1;
    margin-left: 30px;

    @media (max-width: 575.98px) {
        margin-left: 0;
        margin-top: 30px;
    }
}

.agreements {
    margin-top: 50px;
    display: flex;
}

.agreementsText {
    margin-left: 11px;
    font-size: 14px;
    line-height: 21px;
    color: #3d536c;
}

.documentLink {
    color: #3d536c;
    font-style: initial;
    text-decoration: underline;
}

.continueBtn {
    @include display-flex($align: center, $justify: center);
    border: none;
    outline: none;
    width: 100%;
    height: 44px;
    background-color: $bright-turquoise;
    border-radius: 4px;
    margin-top: 40px;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 8px 16px;
    cursor: pointer;

    .arrowIcon {
        @include font-style($size: 26px, $color: $white);
    }

    &:disabled {
        background-color: $ghostwhite;
        color: $heather;

        .arrowIcon {
            color: $heather;
        }
    }

    .loader {
        position: static;
    }
}

.btnArrow {
    margin-top: 2px;
}

.continueBtnText {
    flex: 1;
    padding-left: 20px;
}

.errorText {
    @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
    margin-right: 8px;
    position: absolute;
    margin-top: 4px;
}
