@import 'src/styles/variables';
@import 'src/styles/mixins';

.notificationItems {
    @include display-flex();
    margin-top: 16px;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
        .textInfoSection {
            .messageText {
                color: $dodger-blue-darker;
            }
        }
    }

    .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .textInfoSection {
        margin-left: 8px;

        .messageText {
            @include font-style($size: 13px, $bold: 500, $color: $fiord, $lineHeight: 15px);
            transition: 0.3s color;
        }

        .date {
            @include font-style($size: 10px, $bold: 500, $color: $regent-gray);
            margin-top: 4px;
        }
    }
}
