@import 'src/styles/variables';
@import 'src/styles/mixins';

.topTokensItems {
    @include display-flex($align: flex-end);

    .tokenLogoWrapper {
        width: 24px;
        height: 24px;
        margin-right: 5px;

        .tokenLogo {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }
    }

    .moreIcon {
        @include font-style($size: 16px, $color: $nepal);
        margin-bottom: -3px;
    }
}
