@import 'src/styles/variables';
@import 'src/styles/mixins';

.filterWrapper {
    color: $fiord;
    border-bottom: 1px solid $periwinkle;

    &Header {
        @include display-flex($align: center, $justify: space-between);
        cursor: pointer;
        padding: 24px 0;

        &:hover {
            .title,
            .arrowIcon {
                color: $dodger-blue-darker;
            }
        }

        .title {
            @include font-style($size: 12px, $bold: 700);
            transition: 0.3s color;
        }

        .arrowIcon {
            font-size: 22px;
            margin-top: -8px;
            transition: 0.3s color;
            transform: rotate(-90deg);
        }

        .arrowIconUp {
            transform: rotate(90deg);
            margin-top: 0;
            margin-bottom: -8px;
        }
    }

    .filtersWrapper {
        max-height: 200px;
        overflow-y: scroll;
    }

    .filtersWrapperNoOverflow {
        overflow-y: hidden;
    }
}
