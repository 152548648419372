@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableContentWrapper {
    margin-top: 50px;

    .tableContent {
        .headerOptions {
            @include display-flex($justify: space-between);
            padding: 0 16px;

            .selectWrapper {
                margin-left: 30px;

                @media (max-width: 575.98px) {
                    margin-left: 10px;
                }
            }

            @media (max-width: 991.98px) {
                align-items: flex-end;

                .selectWrapper {
                    margin-bottom: 6px;
                }
            }
        }

        .tableWrapper {
            padding: 16px 16px;
            overflow: scroll;

            .tableContainer {
                width: 1270px;
                box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
                border-radius: 6px;
                background: $white;
            }

            @media (max-width: 991.98px) {
                margin-top: 0;
            }
        }

        .loaderBox {
            @include display-flex($align: center, $justify: center);
            margin-top: 15px;

            .decisionsLoader {
                position: static;
            }
        }
    }
}
