@import 'src/styles/variables';
@import 'src/styles/mixins';

.iconWrapper {
    @include display-flex();
    position: relative;
    background-color: $heather;
    color: $white;
    padding: 10px 10px 10px 13px;
    margin-left: -8px;
    border-top-left-radius: 2px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 2px;
    margin-right: 20px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        border-top: 0 solid transparent;
        border-right: 8px solid $silver-sand;
        border-bottom: 3px solid transparent;
        top: 43px;
        right: 39px;
    }
}