@import '../../styles/variables';
@import '../../styles/mixins';

.carouselWrapper {
    max-width: 1230px;
    position: relative;
    margin: auto;

    .arrowButton {
        position: absolute;
        bottom: 35px;
        padding: 0;
        min-width: unset;

        &:hover {
            background-color: transparent;
        }

        svg {
            @include font-style($size: 34px, $color: #41536A);
        }

        @media (max-width: 767.98px) {
            display: none;
        }
    }

    .previous {
        left: -25px;

        svg {
            transform: rotate(90deg);
        }
    }

    .next {
        right: -25px;

        svg {
            transform: rotate(-90deg);
        }
    }

    .carouselContainer {
        position: relative;
        height: 98px;

        .itemsList {
            @include display-flex($align: center);
            height: 100%;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .loaderBox {
        @include display-flex($align: center, $justify: center);
        height: 98px;
        margin-top: 8px;

        .similarUsersLoader {
            position: static;
        }
    }
}
