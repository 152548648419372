@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    width: 100%;
    margin-top: 35px;

    .title {
        @include font-style($size: 25px, $bold: 400, $color: $fiord, $family: $font-koho);
    }

    .responseContainer {
        padding: 60px 30px;
        margin-top: 12px;
        background-color: $zircon;
        border-radius: 4px;

        .tabsContainer {
            @include display-flex();

            & > div:last-child {
                margin-left: 30px;
            }

            .tab {
                padding: 10px 8px;
                position: relative;
                cursor: pointer;

                & > h4 {
                    @include font-style($size: 14px, $bold: 600, $color: $nepal);
                }
            }

            .tabActive {
                &::before {
                    content: '';
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    background-color: $dodger-blue-darker;
                }

                & > h4 {
                    color: $dodger-blue-darker;
                }
            }
        }

        .responseExampleBlock {
            padding: 20px 30px;
            border: 1px solid $botticelli-lighter;

            .heading {
                @include font-style($size: 14px, $bold: 600, $color: $nepal);
            }

            .codeBlock {
                @include font-style(
                    $size: 16px,
                    $bold: 500,
                    $color: $fiord,
                    $lineHeight: 21px,
                    $family: $font-inconsolata
                );
                margin: 0;

                & > span {
                    @include font-style($size: 14px, $bold: 400, $color: $turquoise);
                }
            }
        }
    }
}
