@import 'src/styles/mixins';

.tableContent {
    @include display-flex($justify: center);
    @include max-device-width();
    padding: 50px 0;

    .tableWrapper {
        padding: 0 16px;
        overflow-x: scroll;

        .tableContainer {
            width: 1270px;

            @media (max-width: 575.98px) {
                width: 1100px;
            }
        }
    }
}
