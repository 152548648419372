@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 16px;
    box-shadow: 0px 0px 10px rgba(6, 102, 246, 0.1);
    border-radius: 6px;
    overflow: hidden;

    &LinkWrapper {
        overflow: hidden;
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.03);
        }
    }

    &:hover {
        .cardHeader {
            .organizationName {
                color: $dodger-blue-darker;
            }
        }
    }

    &Header {
        @include display-flex($align: center);

        .organizationLogo {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }

        .organizationName {
            @include font-style($size: 25px, $bold: 400, $color: $fiord, $family: $font-koho !important);
            margin-left: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: color 0.3s;
        }
    }

    &Body {
        margin-top: 16px;

        .categoriesWrapper {
            @include display-flex($gap: 6px, $wrap: wrap);

            .category {
                @include display-flex($align: center);
                background: $lavender-lighter;
                border-radius: 2px;
                padding: 2px 6px;

                &Name {
                    @include font-style($size: 10px, $bold: 500, $color: $fiord);
                }
            }
        }

        .description {
            @include font-style($size: 14px, $bold: 400, $color: $regent-gray, $lineHeight: 21px);
            margin-top: 16px;
            overflow-wrap: anywhere;
            word-break: break-word;
        }
    }
}
