@import 'src/styles/variables';
@import 'src/styles/mixins';

.membersFilter {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .membersFilterHeader {
        display: flex;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;

        & > div {
            .membersSearchInput {
                width: 155px;
                transition: 0s all;
                margin-left: 10px;
            }

            .membersSearchButton {
                padding: 0;
            }
        }

        .membersFilterIcon {
            width: 23px;
            margin-right: 20px;
        }
    }

    @media (max-width: 575.98px) {
        margin-bottom: 0;
    }
}