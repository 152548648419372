@import 'src/styles/variables';
@import 'src/styles/mixins';

.negativeFeedback {
    @include display-flex();

    .rejectedIcon {
        width: 16px;
        height: 16px;
        margin-top: 4px;
    }

    & > div {
        flex-grow: 1;
        margin-left: 10px;

        & > h6 {
            margin-top: 2px;
        }

        .feedbackForm {
            max-width: 720px;
            margin-top: 40px;

            .textareaWrapper {
                position: relative;

                .textarea {
                    @include font-style($size: 14px, $bold: 400, $color: $fiord);
                    width: 100%;
                    height: 107px;
                    padding: 10px;
                    resize: none;
                    background-color: $athens-gray;
                    border: none;
                    transition: border-color 0.3s;

                    &:focus {
                        outline: none;
                        border-color: $dodger-blue-darker;
                    }

                    &::placeholder {
                        color: $nepal;
                    }
                }

                .submitButton {
                    @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
                    width: 67px;
                    height: 34px;
                    min-width: unset;
                    background-color: $dodger-blue-darker;
                    position: absolute;
                    bottom: 16px;
                    right: 12px;

                    &:disabled {
                        background-color: $heather;
                    }
                }
            }
        }
    }
}
