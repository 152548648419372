@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: center);
    @include max-device-width();
    width: 100%;
    min-height: 450px;
    padding: 55px 16px;

    .discussionsContent {
        @include display-flex($justify: center);
        width: 100%;
        max-width: 955px;

        .container {
            @include display-flex($align: center, $direction: column);
            width: 100%;
            max-width: 740px;

            .startDiscussionWrapper {
                width: 100%;
            }

            .loaderBox {
                @include display-flex($align: center, $justify: center);
                margin-top: 20px;

                .loader {
                    position: static;
                }
            }

            .errorBlock {
                @include display-flex($align: flex-start, $justify: space-between);
                width: 100%;
                background-color: $lavender-rose;
                box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
                border-radius: 6px;
                padding: 20px;
                margin-top: 20px;

                .errorText {
                    @include font-style($size: 20px, $bold: 600, $color: $white, $transform: uppercase);
                }

                .closeBtn {
                    min-width: unset;
                    padding: 0;
                    margin-left: 20px;

                    .closeIcon {
                        @include font-style($size: 26px, $color: $white);
                    }
                }
            }

            .cadrsContainer {
                width: 100%;
                margin-top: 20px;

                & > div {
                    margin-top: 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        @media (max-width: 767.98px) {
            flex-direction: column;

            .container {
                margin-top: 16px;
            }
        }
    }

    @media (max-width: 1199.98px) {
        flex-direction: column-reverse;

        .discussionsContent {
            margin-top: 35px;
        }
    }

    @media (max-width: 575.98px) {
        justify-content: flex-end;
        min-height: auto;

        .discussionsContent {
            margin-top: 20px;
        }
    }
}
