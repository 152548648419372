@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .card {
        @include display-flex($justify: space-between, $direction: column);
        height: 213px;
        background-color: rgba($white, 0.25);
        border-radius: 6px;
        margin-top: 4px;
        padding: 19px 18px;
        overflow-y: hidden;

        .loaderBox {
            position: static;
        }

        .cardWrapper {
            overflow-y: scroll;
            padding: 0px 10px 0px 0;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: $dodger-blue-lighter;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $white;
            }

            & > div:first-child {
                margin-top: 0;
            }

            .element {
                @include display-flex($justify: space-between);
                margin-top: 10px;
                cursor: pointer;
                padding: 5px 8px;
                border-radius: 15px;
                transition: background-color 0.3s;

                &:hover {
                    background: rgba($white, 0.25);
                }

                &Info {
                    @include display-flex($align: center);
                    padding-right: 20px;
                    overflow: hidden;

                    &Logo {
                        width: 40px;
                        height: 40px;
                        border: 3px solid $white;
                        border-radius: 50%;
                    }

                    &Name {
                        @include font-style($size: 14px, $color: $white, $bold: 600);
                        margin-left: 14px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &Activity {
                    @include display-flex($align: center, $justify: space-between);
                    min-width: 100px;

                    & > span {
                        @include font-style($size: 14px, $color: $white, $bold: 400);

                        &:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
