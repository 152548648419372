@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 20px;
    border-top: 1px solid $catskill-white;

    & > div {
        font-size: 14px;
        font-weight: 700;
        color: $fiord;
    }
}
