@import 'src/styles/variables';
@import 'src/styles/mixins';

.autocompleteWrapper {
    width: 200px;
    position: absolute;
    top: 65px;
    background-color: $white;
    border: 2px solid $dodger-blue-darker;
    border-radius: 4px;
    z-index: 2;
    display: none;

    .autocompleteContainer {
        padding: 10px;
        max-height: 300px;
        overflow-y: scroll;

        .notFoundFeeds {
            @include font-style($size: 16px, $bold: 700, $color: $fiord);
        }

        .autocompleteItem {
            @include display-flex($align: center);
            padding: 10px;
            cursor: pointer;
            transition: background-color 0.3s;

            .organizationLogo {
                width: 28px;
                height: 28px;
                border-radius: 50%;
            }

            .organizationTitle {
                @include font-style($size: 14px, $bold: 700, $color: $fiord);
                max-width: 135px;
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:hover {
                background-color: $catskill-white;
            }

            .hoveredCircle {
                @include display-flex($align: center, $justify: center);
                width: 28px;
                min-width: 28px;
                height: 28px;
                background-color: $white;
                border-radius: 50%;
                border: 2px solid $dodger-blue-darker;

                .arrowImage {
                    width: 14px;
                    height: 12px;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $darkslategray;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 38px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid $dodger-blue-darker;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 40px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid $white;
    }
}

.autocompleteVisible {
    display: block;
}
