@import 'src/styles/variables';
@import 'src/styles/mixins';

.content {
    margin: 24px 0;
    background-color: #D7E4F4;
    padding: 16px;
    border-radius: 16px;

    .headSection {
        @include display-flex($align: center, $justify: space-between);

        .textSection {
            @include display-flex($align: center);

            .infoIcon {
                margin: 0 8px;
            }

            & > h4 {
                @include font-style($size: 18px, $bold: 700, $color: #1F3B82);
            }

            & > h5 {
                @include font-style($size: 12px, $bold: 450, $color: $fiord);
            }
        }
    }

    .tableWrapper {
        margin-top: 20px;
        overflow-x: scroll;

        .tableContainer {
            min-width: 600px;
            background-color: $white;
            border-radius: 6px;
            border: 1px solid #B0C2CD;
        }
    }

    .loaderBox {
        position: static;
        margin-top: 40px;
    }
}
