@import 'src/styles/variables';
@import 'src/styles/mixins';

.contactDetailsWrapper {
    & > div {
        margin-top: 30px;

        .inputsWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 60px;
            row-gap: 30px;
        }
    }

    .addEditorButton {
        background-color: $ghostwhite;
        color: $dodger-blue-darker;
        height: 30px;
        margin-top: 30px;

        .addIcon {
            font-size: 24px;
            margin-left: -4px;
        }

        .title {
            @include font-style($size: 14px, $bold: 500, $transform: none);
        }

        &:hover {
            background-color: $ghostwhite;
        }

        &:disabled {
            background-color: $ghostwhite;
            border: none;

            .title,.addIcon {
                color: $heather
            }
        }
    }
}