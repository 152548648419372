@import 'src/styles/variables';

.tableWrapper {
    overflow-x: scroll;
    padding: 10px;
    overflow-y: hidden;

    .tableContainer {
        width: 630px;
        box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;

        .rowsWrapper {
            background-color: $white;
            padding-right: 15px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            .rowsContainer {
                max-height: 570px;
                overflow-y: auto;

                & > div {
                    border-bottom: 1px solid $catskill-white;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                &::-webkit-scrollbar {
                    width: 3px;
                    background-color: $catskill-white;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $nepal;
                }
            }
        }

        @media (max-width: 991.98px) {
            width: auto;
        }

        @media (max-width: 767.98px) {
            min-width: 700px;
        }
    }

    @media (max-width: 767.98px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
