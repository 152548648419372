@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include max-device-width();
    width: 100%;
    padding: 10px 16px 40px;
    position: relative;

    .container {
        @include display-flex($justify: center, $direction: column);

        .bannerWrapper {
            @include display-flex($justify: flex-end);
            padding: 0 10px;
            margin-bottom: 10px;

            .bannerLink {
                .bannerImage {
                    width: 354px;
                    height: 100px;
                    border-radius: 4px;
                }

                @media (max-width: 575.98px) {
                    width: 100%;

                    .bannerImage {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .tablesWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 40px;
            row-gap: 40px;
            padding: 0 10px;

            @media (max-width: 991.98px) {
                grid-template-columns: 1fr;
            }
        }

        .notificationBlockWrapper {
            @include display-flex($justify: center);
            padding: 10px;

            .notificationBlockContainer {
                @include display-flex($justify: center);
                padding: 20px 24px;
                max-width: 680px;
                background-color: $white;
                box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
                border-radius: 6px;

                .notificationText {
                    @include font-style($size: 32px, $bold: 700, $color: $fiord, $align: center);

                    @media (max-width: 767.98px) {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
