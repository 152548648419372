@import 'src/styles/variables';
@import 'src/styles/mixins';

.selectWrapper {
    height: 100%;
    position: relative;

    .selectContainer {
        @include display-flex($align: center, $justify: space-between);
        width: 150px;
        height: 100%;
        padding: 8px;
        border: 2px solid $nepal;
        border-radius: 20px 0 0 20px;
        border-right-width: 1px;
        cursor: pointer;

        .selectedOptionName {
            @include font-style($size: 16px, $bold: 500, $color: $fiord);
        }

        .arrowIcon {
            @include font-style($size: 30px, $color: $fiord);
            margin-right: -8px;
        }

        @media (max-width: 575.98px) {
            width: 120px;
        }
    }

    .selectItemsWrapper {
        width: 100%;
        background-color: $white;
        border-radius: 4px;
        border: 2px solid $malibu-lighter;
        position: absolute;
        top: 55px;
        z-index: 3;

        &::before {
            content: '';
            display: block;
            position: absolute;
            right: 13px;
            bottom: 100%;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 9px solid $malibu-lighter;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 16px;
            bottom: 100%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 6px solid $white;
        }

        .selectElement {
            padding: 8px;
            cursor: pointer;

            .name {
                @include font-style($size: 16px, $color: $fiord, $bold: 700);
            }

            &:hover {
                background-color: $zircon;
            }
        }

        @media (max-width: 767.98px) {
            top: 58px;
        }
    }
}
