@import 'src/styles/variables';
@import 'src/styles/mixins';

.rowHover {
    background: #CCDEF5;
}

.tableRow {
    height: 70px;
    width: 100%;
    @include display-flex($align: center);
    @include font-style($size: 14px, $bold: 400, $color: $fiord);
    border-bottom: 1px solid $catskill-white;
    padding: 17px 16px;
    position: relative;

    .coalitions {
        @include display-flex();
        width: 100%;

        .coalitionItem {
            @include display-flex($align: center);
            font-weight: 700;
            margin-right: 30px;
        }

        & > div:last-child {
            margin-right: 0;
        }
    }

    & > div:last-child {
        border-bottom: 0;
    }
}
