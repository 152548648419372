@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    @include display-flex($align: flex-start, $justify: center);
    @include max-device-width();
    width: 100%;
    padding: 50px 20px;

    @media (max-width: 991.98px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
        padding-bottom: 40px;
    }
}
