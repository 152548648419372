@import 'src/styles/variables';

.wrapper {
    .moreButton {
        width: 34px;
        height: 34px;
        min-width: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $selago;
        border-radius: 50%;
        color: $heliotrope-lighter;
    }
}
