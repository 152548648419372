@import 'src/styles/variables';
@import 'src/styles/mixins';

.selectWrapper {
    position: relative;

    .select {
        @include display-flex($justify: space-between, $align: center);
        height: 30px;
        border: 2px solid $nepal;
        border-radius: 20px;
        padding: 0 14px;
        background-color: $white;
        cursor: pointer;

        &Title {
            @include font-style($size: 12px, $bold: 500, $color: $nepal);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 2px;
        }

        &Icon {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $fiord;
        }

        .removeButton {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            min-width: 17px;
            padding: 0;
            background-color: $catskill-white;
            margin-right: -5px;

            .removeIcon {
                @include font-style($size: 12px, $bold: 500, $color: $nepal);  
                transition: 0.2s color;    
            }

            &:hover {
                background-color: $catskill-white;

                .removeIcon {
                    color: $dodger-blue-darker;
                }
            }
        }
    }

    .selectActive {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .error {
        @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
        position: absolute;
        right: 0;
        bottom: -15px;
    }

    .selectItems {
        width: 100%;
        background-color: $white;
        position: absolute;
        top: 28px;
        max-height: 136px;
        overflow-y: scroll;
        border: solid $nepal;
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        z-index: 3;
        padding: 8px;
        visibility: hidden;
        opacity: 0;

        .menuItem {
            @include font-style($size: 12px, $bold: 500);
            padding: 6px;
            margin-top: 4px;
            color: $fiord;
            cursor: pointer;
            transition: background-color 0.2s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:first-child {
                margin-top: 0;
            }

            &:hover {
                @include font-style($bold: 700);
                background-color: $ghostwhite;
            }
        }
    }

    .fadeIn {
        visibility: visible;
        opacity: 1;
    }
}
