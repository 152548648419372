@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex();
    padding: 12px 16px;

    & > div {
        @include display-flex($align: center);
        width: calc(100% / 4);

        & > div {
            @include display-flex($align: center);

            & > span {
                @include table-header-font-styles();
            }
        }
    }
}
