@import 'src/styles/mixins';

.socialsContainer {
    @include display-flex($wrap: wrap);

    & > a {
        margin-top: 14px;
        margin-left: 12px;
        transition: 0.3s opacity;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    .socialLogo {
        width: 24px;
        height: 24px;
    }

    @media (max-width: 767.98px) {
        margin-top: 10px;

        & > a {
            margin-top: 16px;
            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }
        }

        .socialLogo {
            width: 32px;
            height: 32px;
        }
    }
}
