@import 'src/styles/variables';
@import 'src/styles/mixins';

.tooltipContainer {
    @include display-flex($align: center, $justify: space-between, $direction: column);
    width: 95px;
    height: 40px;
    padding: 6px;
    background-color: $white;
    border-radius: 4px;
    border: 2px solid $dodger-blue-darker;
    position: relative;

    & > h6 {
        @include font-style($size: 10px, $color: $fiord);
    }
}
