@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    @include display-flex($align: center, $justify: space-between, $direction: column, $grow: 1);
    width: 100%;

    .content {
        @include display-flex($direction: column, $grow: 1);
        width: 100%;
        max-width: 740px;
        padding: 70px 30px 95px;

        .title {
            @include font-style($size: 25px, $color: $fiord, $bold: 400, $family: $font-koho);
        }

        .form {
            margin-top: 35px;

            .inputFields {
                display: grid;
                grid-template-columns: 1fr 1fr;
                row-gap: 35px;
                column-gap: 90px;
            }

            .textAreaWrapper {
                margin-top: 35px;
            }

            .buttonsWrapper {
                @include display-flex($justify: space-between);
                margin-top: 100px;

                & > button {
                    @include display-flex($justify: space-between);
                    @include font-style($size: 16px, $bold: 500, $transform: none);
                    width: 170px;
                    height: 44px;
                    border: 2px solid;

                    &:hover {
                        background-color: transparent;
                    }

                    &:first-child {
                        flex-direction: row-reverse;
                        color: $nepal;

                        .arrowBackIcon {
                            transform: rotate(180deg);
                        }
                    }

                    &:last-child {
                        color: $bright-turquoise;
                    }
                }
            }
        }
    }
}
