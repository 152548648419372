@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableCellWrapper {
    overflow: hidden;
    padding-right: 20px;

    & > div {
        display: flex;
        padding: 5px 9px;
        align-items: center;
        border-radius: 2px;
        overflow: hidden;
    }

    .highlightedCell {
        background: $polar;
        width: fit-content;

        span {
            font-weight: 700;
        }
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
