@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .blurBox {
        width: 100%;
        height: 10px;
        background: $dodger-blue-darker;
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
        position: absolute;
        opacity: 0.3;
        filter: blur(10px);
    }

    .container {
        @include display-flex();
        @include max-device-width();
    }
}
