@import 'src/styles/variables';
@import 'src/styles/mixins';

.blockWrapper {
    @include display-flex($justify: space-between, $align: center);
    padding: 24px 0;
    border-top: 1px solid $nepal;

    &:first-child {
        border-top: none;
    }

    .textInfo {
        .descriptionBlock {
            margin-top: 10px;
        }
    }

    .optionWrapper {
        margin-left: 16px;
    }
}
