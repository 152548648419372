@import 'src/styles/variables';
@import 'src/styles/mixins';

.linksWrapper {
    @include display-flex($direction: column);

    .link {
        @include font-style($size: 16px, $bold: 400, $color: $white, $family: $font-koho);
        padding: 8px 0;
        border-radius: 2px;
        transition: 0.3s all;

        &:hover {
            color: $fiord;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
