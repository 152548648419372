@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    width: 100%;
    background-color: $white;
    z-index: 10;

    .header {
        width: 100%;

        .headerMainItems {
            @include display-flex($align: center, $justify: space-between);
            @include max-device-width();
            min-height: $header-height;
            padding: 0 16px;
            position: relative;
        }
    }
}
