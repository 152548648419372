@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 18px 20px 18px;

    & > div {
        @include table-header-font-styles();
        color: #447CF7;
    }

    .timePeriod {
        width: 12%;
    }

    .usdValue {
        width: 16%;
    }

    .totalSupply {
        width: 16%;
    }

    .circulatingSupply {
        width: 16%;
    }

    .circulatingSupplyPercentage {
        width: 20%;
    }

    .totalSupplyPercentage {
        width: 19%;
    }

    .holders {
        width: 13%;
    }
}
