@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    margin: 0 auto;
    padding: 10px 6px 50px;

    .mobileDescriptionSection {
        @include display-flex($align: flex-start, $direction: column);
        margin-bottom: 20px;
        padding: 0 16px;

        .textDescription {
            @include font-style($size: 22px, $color: $fiord, $bold: 400, $lineHeight: 27px, $family: $font-koho);
        }

        .readMore {
            @include display-flex($align: center);
            color: $fiord;
            margin-top: 14px;

            .text {
                @include font-style($size: 14px, $bold: 600);
            }

            .arrowIcon {
                margin-bottom: -2px;
                margin-left: 4px;
            }
        }
    }

    .bannerWrapper {
        @include display-flex($justify: flex-end);
        max-width: 1290px;
        margin: 0 auto -25px;
        padding: 0 10px;

        .banner {
            width: 536px;
            height: 100px;
            border-radius: 4px;
            border: 2px solid $white;

            @media (max-width: 767.98px) {
                width: 100%;
            }
        }

        @media (max-width: 991.98px) {
            margin-bottom: 0;
        }

        @media (max-width: 575.98px) {
            display: none;
        }
    }

    .carouselsWrapper {
        padding: 10px 35px 0;

        .carouselWrapper {
            max-width: 1270px;
            border: none;

            .notFoundWrapper {
                width: auto;
                margin-left: 16px;
                margin-right: 16px;
            }

            & > div {
                & > div {
                    & > a {
                        margin: 0 10px;
                    }
                }
            }

            @media (max-width: 767.98px) {
                & > div {
                    & > div {
                        & > a {
                            &:first-child {
                                margin-left: 16px;
                            }

                            &:last-child {
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 767.98px) {
            padding-left: 0;
            padding-right: 0;

            .stakeholdersContainer {
                height: 114px;
            }
        }
    }

    .daoInfo {
        @include display-flex($justify: center);
        margin-top: 20px;

        .currenciesContainer {
            width: 100%;
            max-width: 660px;
            overflow-x: scroll;
            overflow-y: hidden;

            & > h4 {
                margin-left: 10px;
            }

            @media (max-width: 991.98px) {
                max-width: none;
            }

            @media (max-width: 767.98px) {
                & > h4 {
                    margin-left: 16px;
                }
            }
        }

        .daosCardsContainer {
            width: 100%;
            max-width: 600px;
            margin-left: 30px;
            overflow-x: scroll;
            overflow-y: hidden;

            & > div {
                & > h4 {
                    margin-left: 10px;
                }
            }

            & > div:first-child {
                @media (max-width: 767.98px) {
                    padding-left: 6px;
                    padding-right: 6px;
                }
            }

            & > div:last-child {
                margin-top: 20px;

                @media (max-width: 991.98px) {
                    margin-top: 30px;
                }
            }

            @media (max-width: 767.98px) {
                & > div:last-child > h4 {
                    margin-left: 16px;
                }
            }

            @media (max-width: 991.98px) {
                @include display-flex($direction: column);
                margin-top: 30px;
                margin-left: 0;
                max-width: none;

                & > div {
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-top: 30px;
                    }
                }
            }
        }

        @media (max-width: 991.98px) {
            @include display-flex($align: center, $direction: column);
            margin-top: 0;
        }
    }

    @media (max-width: 767.98px) {
        padding-left: 0;
        padding-right: 0;
    }
}
