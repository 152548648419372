@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    padding: 15px 0 20px;
    background-color: $white;
    box-shadow: 0 0 30px rgba(64, 107, 179, 0.1);
    border-radius: 6px;

    .tableTitle {
        @include font-style($size: 14px, $bold: 600, $color: $fiord);
        margin-left: 20px;

        @media (max-width: 767.98px) {
            font-weight: 700px;
        }
    }
}

.tableRowsEmpty {
    padding-bottom: 0;
}
