@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include max-device-width();
    width: 100%;
    padding: 40px;
    flex-grow: 1;

    .mainContent {
        @include display-flex();

        .filterWrapper {
            min-width: 200px;
        }

        .cardsWrapper {
            width: 100%;

            .cardsContainer {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: 1fr;
                gap: 24px;
                padding-top: 24px;
                margin-left: 50px;
            }
        }
    }
}

.loaderBox {
    position: static;
    margin-top: 40px;
}
