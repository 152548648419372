@import 'src/styles/variables';
@import 'src/styles/mixins';

.statsCard {
    width: 100%;
    min-height: 380px;
    background: linear-gradient(180deg, $dodger-blue-darker 34.12%, $darkturquoise 100%);

    .statsWrapper {
        width: 100%;
        max-width: 1330px;
        margin: 0 auto;
        padding: 45px 16px 30px;

        .headSection {
            @include display-flex($align: center, $justify: space-between);
            margin-bottom: 30px;

            .details {
                color: $white;
                padding-right: 20px;

                .title {
                    @include font-style($size: 40px, $lineHeight: 44px, $bold: 400, $family: $font-koho);
                }

                .statsCounters {
                    @include display-flex($wrap: wrap);
                    @include font-style($size: 16px, $lineHeight: 18px, $bold: 400);
                    margin-top: 10px;

                    & > span {
                        margin-right: 5px;

                        &:last-child {
                            margin-right: 0;
                            font-weight: bold;
                        }
                    }
                }

                @media (max-width: 575.98px) {
                    .title {
                        font-size: 28px;
                        line-height: 36px;
                    }

                    .statsCounters {
                        font-size: 12px;
                    }
                }
            }

            .banner {
                width: 536px;
                height: 100px;
                border-radius: 4px;
                border: 2px solid $white;
                cursor: pointer;

                @media (max-width: 767.98px) {
                    width: 100%;
                    height: auto;
                }
            }

            @media (max-width: 991.98px) {
                flex-direction: column;
                align-items: flex-start;

                .details {
                    margin-bottom: 35px;
                    padding-right: 0;
                }
            }
        }
    }
}
