@import 'src/styles/variables';
@import 'src/styles/mixins';

.comment {
    margin-top: 16px;

    &:first-child {
        margin-top: 0;
    }

    &Details {
        @include display-flex();

        .link {
            width: 36px;
            height: 36px;

            .commenterLogo {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }

        .commentTextBlock {
            @include display-flex($justify: space-between, $align: flex-start, $direction: column);
            flex-grow: 1;
            background-color: $ghostwhite;
            border-radius: 4px;
            margin-left: 10px;
            padding: 8px 10px 8px;
            position: relative;

            &Header {
                @include display-flex($justify: space-between, $align: flex-end);
                width: 100%;

                .commenter {
                    @include font-style($size: 12px, $color: $fiord, $bold: 700);
                    transition: 0.3s color;

                    &:hover {
                        color: $dodger-blue-darker;
                    }
                }

                .date {
                    @include font-style($size: 10px, $color: $regent-gray, $bold: 500);
                }
            }

            .text {
                @include font-style($size: 14px, $color: $fiord, $bold: 400, $lineHeight: 21px);
                white-space: pre-wrap;
                overflow-wrap: anywhere;
                word-break: break-word;
                margin-top: 4px;

                a {
                    color: $dodger-blue-darker;
                    font-weight: 600;
                }
            }

            .textMarginRight {
                margin-right: 20px;
            }

            .commentsOptions {
                @include display-flex($align: flex-end, $justify: space-between);
                width: 100%;
                padding: 0 6px;

                .tokensInfo {
                    @include display-flex($align: center);
                    margin-top: 16px;

                    & > div {
                        &:last-child {
                            padding-left: 35px;
                        }
                    }

                    @media (max-width: 575.98px) {
                        width: 100%;
                        justify-content: space-between;
                        margin-right: 14px;

                        & > div {
                            &:last-child {
                                border-left: none;
                                padding-left: 5px;
                            }

                            &:first-child {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .info {
        @include display-flex($justify: space-between);
        margin-top: 2px;

        .replySection {
            @include display-flex($align: flex-end);
            margin-top: 4px;
            margin-left: 62px;

            .reply {
                position: relative;
                cursor: pointer;

                .text {
                    @include font-style($size: 12px, $color: $fiord, $bold: 700);
                }

                .notificationTooltip {
                    bottom: 30px;
                    left: -100px;

                    @media (max-width: 575.98px) {
                        left: 0;
                    }
                }
            }

            .replies {
                @include font-style($size: 10px, $color: $nepal, $bold: 700, $transform: uppercase);
                margin-left: 14px;
                position: relative;

                &::before {
                    content: '';
                    width: 3px;
                    height: 3px;
                    position: absolute;
                    left: -7px;
                    top: 4px;
                    border-radius: 50%;
                    background-color: $nepal;
                }
            }
        }
    }
}
