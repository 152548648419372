@import 'src/styles/variables';
@import 'src/styles/mixins';

.linkWrapper {
    margin: 0 10px;

    .similarUserCard {
        @include display-flex($align: center);
        width: 193px;
        min-width: 193px;
        padding: 8px 10px;
        height: 83px;
        background: $white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;
        transition: 0.3s box-shadow;

        &:hover {
            box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.3);
        }

        .logo {
            width: 54px;
            height: 54px;
            border-radius: 50%;
        }

        .userInfo {
            @include display-flex($justify: center, $direction: column);
            height: 100%;
            margin-left: 12px;

            .username {
                @include font-style($size: 14px, $bold: 700, $color: $fiord);
                max-width: 110px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .organizationItems {
                margin-top: 4px;

                .logosContainerMargins {
                    margin-top: 5px;
                }
            }
        }

        @media (max-width: 767.98px) {
            width: 127px;
            min-width: 127px;
            height: 144px;
            flex-direction: column;
            padding: 6px;

            .userInfo {
                align-items: center;
                margin-left: 0;

                .username {
                    margin-top: 4px;
                }

                .organizationItems {
                    & > div:last-child {
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}
