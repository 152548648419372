@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    .navigationBackBlock {
        @include display-flex($align: flex-end);
        width: 100%;
        margin-bottom: 10px;

        .accountSettingsLink {
            @include display-flex($align: center);
            color: $fiord;
            transition: 0.3s color;

            .arrowIcon {
                @include font-style($size: 14px);
            }

            .text {
                @include font-style($size: 14px, $bold: 400);
            }

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }

    .header {
        @include display-flex($align: flex-start, $justify: space-between);

        .textSection {
            max-width: 600px;
            margin-right: 40px;

            .description {
                @include font-style($size: 16px, $bold: 400, $color: $fiord, $lineHeight: 20px);
                margin-top: 8px;
            }
        }

        .billingCycleOptions {
            @include display-flex();
            margin-top: 10px;

            .radioFormTitle {
                @include font-style($size: 25px, $bold: 400, $color: $fiord, $family: $font-koho);
            }

            .period {
                @include display-flex($align: center);
                padding: 10px 14px;
                border-radius: 24px;
                background-color: $white;
                margin-left: 8px;
            }

            .radioInputsWrapper {
                @media (max-width: 767.98px) {
                    flex-direction: row;
                }

                & > div:last-child {
                    margin-left: 30px;
                }
            }
        }

        @media (max-width: 767.98px) {
            flex-direction: column;

            .textSection {
                margin-right: 0;
            }

            .billingCycleOptions {
                margin-top: 20px;
            }
        }
    }

    .plansCardsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 50px;
        column-gap: 50px;
        margin-top: 55px;

        @media (max-width: 1199.98px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        @media (max-width: 767.98px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }
    }

    .title {
        @include font-style($size: 40px, $bold: 500, $color: $fiord, $family: $font-koho);
    }

    .subscriptionsInfo {
        @include display-flex($align: center, $justify: center, $direction: column);
        margin-top: 30px;

        .subscriptionsBlocks {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 50px;
            column-gap: 50px;
            margin-top: 40px;

            @media (max-width: 767.98px) {
                grid-template-columns: 1fr;
            }
        }
    }
}
