@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    padding: 12px;

    & > div {
        @include table-header-font-styles();
        color: #447CF7;
    }

    .headerItems {
        @include display-flex();

        .address {
            width: 30%;
        }

        .type {
            width: 20%;
        }

        .chainName {
            width: 20%;
        }

        .description {
            width: 30%;
        }

        @media (max-width: 767.98px) {
            padding: 8px 12px 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
}
