@import 'src/styles/variables';
@import 'src/styles/mixins';

.statisticsBlock {
    @include display-flex($justify: space-between);

    .organizationInfo {
        .sum {
            @include font-style($size: 20px, $bold: 500, $color: $white, $lineHeight: 20px, $family: $font-koho);
        }

        .title {
            @include font-style($size: 16px, $bold: 500, $color: $white);
            margin-top: 2px;
        }
    }

    .rankingsContainer {
        .rankInfo {
            @include display-flex();

            & > span {
                @include font-style($size: 11px, $bold: 700, $color: $white);
            }

            :last-child {
                margin-left: 4px;
            }
        }

        .ranksChangesInfo {
            @include display-flex($align: center);
            margin-top: 4px;

            .rankChangesDefault {
                @include display-flex($align: center);

                .arrowIcon {
                    transform: rotate(45deg);
                    font-size: 14px;
                    color: $white;
                    margin-left: -3px;
                }

                .arrowIconPositive {
                    color: $turquoise-blue;
                }

                .arrowIconNegative {
                    color: $cornflower-lilac;
                    transform: rotate(135deg);
                }

                .rankShift {
                    @include font-style($size: 11px, $bold: 500, $color: $white);
                }

                .notFoundText {
                    @include font-style($size: 11px, $color: $white);
                }
            }

            .period {
                @include font-style($size: 10px, $bold: 400, $color: $white);
                white-space: nowrap;
                margin-left: 8px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: -4px;
                    top: 1px;
                    width: 1px;
                    height: 10px;
                    background-color: rgba($white, 0.5);
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        .organizationInfo {
            @include display-flex($justify: space-between, $grow: 1, $direction: row-reverse);

            .title,
            .sum {
                @include font-style($size: 14px, $bold: 700);
            }

            .title {
                margin-top: 0;
                text-transform: capitalize;
            }
        }

        .rankingsContainer {
            display: none;
        }
    }
}
