@import 'src/styles/variables';
@import 'src/styles/mixins';

.headerWrapper {
    width: 100%;
    background: linear-gradient(180deg, $dodger-blue-darker 34.12%, $darkturquoise 100%);

    .statsWrapper {
        height: 36px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        .statsInfoContainer {
            @include display-flex($align: center);
            @include max-device-width();
            height: 100%;
            padding: 0 50px;

            & > span {
                @include font-style($size: 14px, $color: $white, $bold: 700);
                padding-right: 12px;
                border-right: 1px solid rgba(255, 255, 255, 0.5);
                margin-left: 12px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    border: none;
                    padding-right: 0;
                }
            }

            .lastUpdateDate {
                font-weight: 400;

                @media (max-width: 575.98px) {
                    font-size: 11px;
                }
            }

            @media (max-width: 767.98px) {
                padding: 0 16px;
            }
        }

        @media (max-width: 575.98px) {
            height: 46px;

            .statsInfoContainer {
                justify-content: space-between;

                .goBackButton {
                    min-width: unset;
                    padding: 0;

                    .goBackIcon {
                        @include font-style($size: 24px, $color: $white);
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .container {
        @include display-flex($align: center, $justify: space-between);
        max-width: 1100px;
        margin: 0 auto;
        padding: 26px 16px;

        .content {
            @include display-flex($align: center);

            .organizationLogo {
                width: 175px;
                height: 175px;
                border: 8px solid $white;
                border-radius: 50%;
            }

            .organizationInfo {
                @include display-flex($align: flex-start, $justify: center, $direction: column);
                margin-left: 40px;
                margin-right: 90px;

                &Head {
                    @include display-flex($align: flex-start);

                    @media (max-width: 991.98px) {
                        flex-direction: column;
                        gap: 15px;
                    }

                    @media (max-width: 767.98px) {
                        flex-direction: row;
                        gap: 20px;
                    }

                    .optionsButtonsWrapper {
                        @include display-flex();
                        margin-left: 20px;

                        @media (max-width: 991.98px) {
                            margin-left: 0;
                        }

                        & > button,
                        & > div {
                            margin-right: 16px;
                        }

                        .editButton {
                            @include display-flex($justify: space-between);
                            width: 81px;
                            height: 34px;
                            background-color: $dodger-blue-darker;
                            border: 2px solid $white;
                            margin-left: 16px;

                            .editIcon {
                                @include font-style($size: 24px, $color: $white);
                                margin-left: -4px;
                            }

                            .buttonText {
                                @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
                            }
                        }
                    }

                    .organizationName {
                        @include font-style(
                            $size: 40px,
                            $bold: 400,
                            $color: $white,
                            $lineHeight: 44px,
                            $family: $font-koho
                        );
                        overflow-wrap: anywhere;
                        margin-top: -8px;
                    }
                }

                .textDescription {
                    @include font-style($size: 14px, $bold: 400, $lineHeight: 21px, $color: $white);
                    margin-top: 16px;
                }

                .listingLabelBlock {
                    padding: 4px 8px;
                    margin-top: 20px;
                    border: 1px solid $white;
                    border-radius: 4px;

                    .label {
                        @include font-style($size: 14px, $bold: 700, $lineHeight: 21px, $color: $white);
                    }
                }

                .readMore {
                    @include display-flex($align: center);
                    color: $white;
                    cursor: pointer;
                    margin-top: 8px;

                    .text {
                        @include font-style($size: 14, $bold: 700);
                    }

                    .arrowIcon {
                        font-size: 16px;
                        margin-left: 5px;
                        margin-top: 2px;
                    }
                }

                .readMoreDisabled {
                    color: $mystic;
                }

                @media (max-width: 1199.98px) {
                    margin-right: 40px;
                }
            }
        }

        @media (max-width: 767.98px) {
            padding-top: 35px;
            flex-direction: column;

            .content {
                flex-direction: column;

                .organizationInfo {
                    align-items: center;
                    margin: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}
