@import 'src/styles/variables';
@import 'src/styles/mixins';

.footerWrapper {
    @include display-flex($justify: center);
    background-color: $fiord;
    padding: 40px 30px;

    .footerContainer {
        @include display-flex($justify: space-between);
        width: 100%;
        max-width: 1200px;

        .contact {
            @include display-flex();
            height: 105px;
            color: $white;

            &Info {
                @include display-flex($justify: space-between, $direction: column);

                .heading {
                    @include font-style($size: 25px, $bold: 400, $family: $font-koho);
                }

                & > h6 {
                    @include font-style($size: 14px, $bold: 400);
                }

                .mail {
                    @include font-style($size: 25px, $bold: 400, $color: $bright-turquoise, $family: $font-koho);
                }
            }
        }

        .pageLinksContainer {
            @include display-flex($justify: space-between, $direction: column);

            & > div {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }

            .linkWrapper {
                width: 130px;
                padding-bottom: 16px;
                margin-top: 15px;
                border-bottom: 1px solid $nepal;

                .linkContent {
                    @include display-flex($align: center, $justify: space-between);

                    .pageName {
                        @include font-style($size: 16px, $bold: 500, $color: $white);
                    }

                    .arrowIcon {
                        width: 25px;
                        height: 15px;
                        transition: transform ease-out 0.25s;
                    }

                    &:hover {
                        .arrowIcon {
                            transform: translateX(12px);
                        }
                    }
                }
            }
        }
    }
}
