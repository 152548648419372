@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputWrapper {
    position: relative;

    .input {
        @include font-style($size: 14px, $color: $fiord);
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 1px solid $nepal;
        background-color: transparent;
        padding-bottom: 6px;
        padding-left: 0;
        transition: all 0.3s;

        &:focus {
            outline: none;
            border-color: $dodger-blue-darker;
        }

        &::placeholder {
            color: $nepal;
        }
    }

    .inputError {
        border-color: $tomato;
        transition: border-color 0.3s;

        &:focus {
            border-color: $tomato;
        }

        &::placeholder {
            color: $tomato;
        }
    }

    .errorText {
        @include font-style($size: 12px, $color: $tomato);
        position: absolute;
        bottom: -18px;
    }
}
