@import 'src/styles/variables';
@import 'src/styles/mixins';

.defaultFeedback {
    @include display-flex($align: center);

    & > button {
        @include font-style($size: 12px, $bold: 500, $transform: none);
        width: 56px;
        height: 24px;
        border: 2px solid;
        min-width: unset;
        margin-left: 16px;

        &:hover {
            background-color: transparent;
        }
    }

    .positiveFeedbackButton {
        color: $java;
        border-color: $java;
    }

    .negativeFeedbackButton {
        color: $lavender-magenta;
        border-color: $lavender-magenta;
    }
}
