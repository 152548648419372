@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($direction: column, $align: center);

    .card {
        width: 240px;
        height: 300px;
        border-radius: 20px;
        background-repeat: no-repeat;
        position: relative;
        background: url('../../../../../../assets/png/mint_score_background.png');
        background-size: cover;

        .cardWrapper {
            @include display-flex($align: center, $justify: space-between, $direction: column);
            width: 100%;
            height: 100%;
            padding: 30px;

            .dolphinImage {
                width: 147px;
                height: 136px;
            }

            .content {
                width: 100%;

                .statsWrapper {
                    @include display-flex($justify: space-between);
                    width: 100%;

                    .stats {
                        & > p {
                            @include font-style($size: 14px, $bold: 500, $color: $white, $lineHeight: 120%);

                            &:last-child {
                                font-family: $font-koho !important;
                                margin-top: 8px;
                            }
                        }

                        &:last-child {
                            &:last-child {
                                margin-right: 20px;
                            }
                        }
                    }
                }

                .labelWrapper {
                    @include display-flex($justify: center);
                    margin-top: 20px;

                    .label {
                        @include font-style($size: 12px, $color: $white, $bold: 700, $family: $font-koho);
                        width: 155px;
                        padding: 8px;
                        text-align: center;
                        background-color: rgba(255, 255, 255, 0.2);
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    .infoText {
        @include font-style(
            $size: 14px,
            $bold: 700,
            $color: $white,
            $family: $font-koho !important,
            $decoration: underline
        );
        margin-top: 20px;
    }

}

.tooltipTitle {
    font-size: 14px;
    line-height: 21px;
    color: $fiord;
    p {
        padding: 10px 2px;
    }
}
