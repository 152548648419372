@import 'src/styles/variables';
@import 'src/styles/mixins';

.progressBarContainer {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-family: $font-inter;

    & > span {
        @include font-style($size: 12px, $bold: 700);
    }

    .progressBar {
        height: 8px;
        width: 91px;
        background-color: $catskill-white;
        border-radius: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        align-content: center;

        .fillerStyles {
            height: 100%;
            background-color: $java;
            border-radius: inherit;
        }
    }
}
