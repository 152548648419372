@import 'src/styles/variables';
@import 'src/styles/mixins';

.tooltip {
    @include display-flex($direction: column);
    background-color: $white;
    padding: 10px;
    border: 1px solid $fiord;
    border-radius: 4px;

    .text {
        @include font-style($size: 14px, $bold: 500, $color: $fiord);

        .value {
            font-weight: 700;
        }
    }
}
