@import 'src/styles/variables';
@import 'src/styles/mixins';

.shareButtonWrapper {
    .shareButton {
        border-radius: 50%;
        width: 34px;
        height: 34px;
        min-width: unset;
        background-color: $bright-turquoise;

        &:hover {
            background-color: $lightseagreen;
        }

        .shareIcon {
            @include font-style($size: 20px, $color: $white);
            margin-right: 2px;
        }
    }
}
