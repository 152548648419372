@import 'src/styles/variables';
@import 'src/styles/mixins';

.tagsContainer {
    .tags {
        &List {
            @include display-flex($wrap: wrap);
        }

        &Item {
            @include display-flex($align: center);
            background-color: $catskill-white;
            border-radius: 4px;
            height: 30px;
            margin-right: 12px;
            margin-bottom: 12px;
            padding: 0 9px;
            color: $fiord;

            .deleteButton {
                min-width: unset;
                margin-left: 8px;
                padding: 0;

                &:hover {
                    background-color: transparent;
                }

                .closeIcon {
                    font-size: 17px;
                    color: $nepal;
                }
            }

            .tagText {
                @include font-style($size: 12px, $bold: 500, $color: $fiord);
            }
        }
    }

    input {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
    }

    .maxTagLength {
        font-size: 10px;
        font-weight: 500;
        color: $heather;
    }

    .tagsInputField {
        min-width: 100%;
    }
}
