@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    @include display-flex($align: center, $justify: space-between, $direction: column, $grow: 1);
    width: 100%;

    .content {
        @include display-flex($direction: column, $grow: 1);
        width: 100%;
        max-width: 1100px;
        padding: 70px 30px 95px;

        .generalOptions {
            @include display-flex($justify: space-between);

            & > div {
                & > h3 {
                    @include font-style($size: 25px, $color: $fiord, $bold: 400, $family: $font-koho);
                }
            }

            .apisListContainer {
                .checkboxesList {
                    @include display-flex($align: center);

                    .checkboxItems {
                        @include display-flex();
                        margin-top: 20px;
                        margin-right: 40px;

                        .info {
                            margin-left: 8px;

                            .name {
                                @include font-style($size: 14px, $color: $fiord, $bold: 400);
                            }

                            .tokensCount {
                                @include font-style($size: 10px, $color: $nepal, $bold: 700);
                                margin-top: 2px;
                            }
                        }
                    }
                }
            }

            .billingCycleOptions {
                .options {
                    @include display-flex($align: center);
                    margin-top: 10px;

                    .selector {
                        @include display-flex($align: center);
                        background-color: $white;
                        padding: 6px;
                        border: 1px solid $ghostwhite;
                        border-radius: 6px;
                        box-shadow: 0px 0px 10px rgba(36, 66, 84, 0.1);

                        .period {
                            @include font-style($size: 12px, $color: $fiord, $bold: 500);
                            padding: 6px 10px;
                            border-radius: 4px;
                            cursor: pointer;
                            transition: 0.3s background-color;

                            &:last-child {
                                margin-left: 8px;
                            }
                        }

                        .selectedPeriod {
                            background-color: $dodger-blue-darker;
                            color: $white;
                        }
                    }

                    .renewsCheckboxItems {
                        @include display-flex($align: center);
                        margin-left: 35px;

                        .text {
                            @include font-style($size: 12px, $color: $fiord, $bold: 400);
                            margin-left: 8px;
                        }
                    }
                }
            }
        }

        .plansList {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 40px;
            margin-top: 70px;
        }
    }
}
