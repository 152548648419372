@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 20px 12px;
    border-top: 1px solid $catskill-white;

    & > div {
        @include display-flex($align: center);
        padding-right: 20px;
        overflow: hidden;

        & > span {
            @include font-style($size: 14px, $bold: 400, $color: $fiord);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .address {
        width: 30%;

        .link {
            @include font-style($size: 14px, $bold: 700, $color: $fiord, $decoration: none);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: color 0.3s;

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }

    .type {
        width: 20%;
    }

    .chainName {
        width: 20%;
    }

    .description {
        width: 30%;
        padding-right: 0;
    }
}

.textCapitalized {
    text-transform: capitalize;
}
