@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.tablesWrapper {
    width: 100%;
    overflow-x: scroll;
    margin-top: 20px;

    background-color: #D7E4F4;
    padding: 16px;
    border-radius: 16px;

    .title {
        @include display-flex($align: center);

        .infoIcon {
            margin: 0 8px;
        }

        h4 {
            @include font-style($size: 18px, $bold: 700, $color: #1F3B82);

            @media (max-width: 767.98px) {
                font-size: 16px;
            }
        }
    }

    .votingTitle {
        margin-top: 24px;
    }

    .tableWrapper {
        margin-top: 20px;
        overflow-x: scroll;

        .tableContainer {
            min-width: 600px;
            background: $white;
            border-radius: 6px;
            border: 1px solid #B0C2CD;

            .loaderBox {
                padding: 20px 0;
                position: static;
            }
        }
    }

    @media (max-width: 991.98px) {
        margin-top: 30px;
        margin-left: 0;
    }
}
