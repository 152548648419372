@import 'src/styles/variables';
@import 'src/styles/mixins';

.statsGraphCard {
    @include display-flex($direction: column);
    min-height: 282px;
    border-radius: 6px;
    overflow: hidden;

    .content {
        @include display-flex($justify: space-between, $direction: column);
        flex-grow: 1;
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 6px;
    }

    .lineGraphContent {
        padding: 20px;
    }

    .barGraphContent {
        padding: 14px 14px 0;
    }
}
