@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    .titleWrapper {
        padding-bottom: 16px;
        @include display-flex($align: center);

        .infoIcon {
            margin: 0 8px;
        }

        .tableTitle {
            @include font-style($size: 18px, $bold: 700, $color: #1F3B82);
        }
    }

    .tableContainer {
        overflow: auto;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
        position: relative;
        border: 1px solid #B0C2CD;

        .table {
            min-width: 600px;
            max-height: 377px;

            .tableHeader {
                position: sticky;
                top: 0;
                z-index: 10;
                background-color: $white;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
            }

            @media (max-width: 991.98px) {
                width: auto;
            }
        }
    }
}

.loaderBox {
    @include display-flex($align: center, $justify: center);
    margin-top: 15px;

    .decisionsLoader {
        position: static;
    }
}
