@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $zircon;

    & > div {
        & > span {
            @include font-style($size: 14px, $bold: 600, $color: $dodger-blue-darker);
        }
    }

    .parameters {
        width: 30%;
    }

    .comments {
        width: 70%;
    }
}
