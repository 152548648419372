@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    @include display-flex($align: center, $justify: space-between);

    .tabsContainer {
        @include display-flex($align: flex-start, $grow: 1);

        .title {
            @include font-style($size: 14px, $bold: 700, $color: $white);
            max-width: 90px;
            margin-top: -3px;
        }

        .tabsList {
            @include display-flex($grow: 1);

            margin-left: 5px;

            .tabItems {
                @include display-flex($justify: center);
                width: 50%;
                padding: 8px 10px;
                cursor: pointer;

                .period {
                    @include font-style($size: 10px, $bold: 500, $color: $white);
                }

                border-bottom: 1px solid rgba(231, 236, 243, 0.5);
                text-transform: uppercase;
            }

            .activeTab {
                border-width: 3px;
                border-color: $aquamarine;

                .period {
                    color: $aquamarine;
                }
            }
        }
    }
}
