@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    @include display-flex($align: center, $justify: center, $direction: column);
    width: 100%;
    padding: 10px;

    .card {
        width: 100%;
        max-width: 890px;
        padding: 10px 16px 50px;

        .title {
            @include font-style($size: 25px, $lineHeight: 25px, $bold: 400, $color: $fiord, $family: $font-koho);
        }

        .description {
            @include font-style($size: 14px, $color: $fiord, $lineHeight: 21px);
            margin-top: 30px;
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            word-break: break-word;
        }
    }

    @media (max-width: 575.98px) {
        .card {
            padding-top: 30px;
            padding-bottom: 30px;

            .title {
                font-size: 28px;
            }

            .description {
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 767.98px) {
        background-color: transparent;
    }
}
