@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex();
    padding: 12px 16px;
    border-top: 1px solid $mystic;

    & > div {
        @include display-flex($align: center);
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
    }

    .number {
        width: 5%;
    }

    .proposalName {
        width: 53%;
        padding-right: 20px;

        & > span {
            margin-left: 10px;
            font-weight: 700;
            overflow-wrap: anywhere;
        }

        .proposalStatusIcon {
            width: 16px;
            height: 16px;
        }
    }

    .date {
        width: 18%;
    }

    .voted {
        width: 18%;
        overflow-wrap: anywhere;
        padding-right: 20px;

        .wonSuccess {
            color: $puerto-rico;
        }

        .wonNotSuccess {
            color: $lavender-magenta;
        }
    }

    .wonVote {
        width: 6%;

        img.thumbIcon {
            width: 23px;
            height: 26px;
            // margin-left: 8px;
        }
    }
}
