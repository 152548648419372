@import 'src/styles/variables';
@import 'src/styles/mixins';

.left {
    @include display-flex($direction: column);
    width: 290px;
    min-width: 220px;
    margin-left: 25px;

    .projectTokenCard {
        background-color: $white;
        padding: 10px 16px;
        border-radius: 6px;

        .header {
            @include display-flex($align: center, $justify: space-between);
            padding-bottom: 10px;
            border-bottom: 1px solid $periwinkle;

            &Title {
                @include font-style($size: 18px, $bold: 600, $color: $fiord);
            }

            .expandMoreButton {
                width: 24px;
                height: 24px;
                min-width: unset;
                background-color: $zumthor;

                .arrowIcon {
                    @include font-style($size: 15px, $color: $dodger-blue-darker);
                    transform: rotate(90deg);
                    margin-top: 6px;
                }

                .arrowIconDown {
                    transform: rotate(-90deg);
                    margin-top: 0;
                    margin-bottom: 6px;
                }
            }
        }

        .body {
            @include display-flex($align: center);

            .link {
                @include display-flex();
                text-decoration: none;

                &:hover {
                    .section {
                        .title {
                            color: $dodger-blue-darker;
                        }

                        .chainSection {
                            .chainName {
                                color: $dodger-blue-darker;
                            }
                        }
                    }
                }

                .tokenLogoBig {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                }

                .section {
                    @include display-flex($align: flex-start, $direction: column);
                    margin-left: 8px;

                    .title {
                        @include font-style($size: 14px, $bold: 700, $color: $fiord);
                        transition: 0.3s color;
                    }

                    .chainSection {
                        @include display-flex($align: center);
                        border: 1px solid $pattens-blue;
                        border-radius: 20px;
                        padding: 3px;
                        margin-top: 6px;

                        .tokenLogoSmall {
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                        }

                        .chainName {
                            @include font-style($size: 10px, $bold: 500, $color: $fiord, $transform: uppercase);
                            margin-left: 4px;
                            transition: 0.3s color;
                        }
                    }
                }
            }

            @media (max-width: 575.98px) {
                padding-top: 10px;

                .tokenLogoBig {
                    width: 36px;
                    height: 36px;
                }

                .section {
                    flex-direction: row;
                    align-items: center;

                    .chainSection {
                        margin-top: 0;

                        .tokenLogoSmall {
                            width: 24px;
                            height: 24px;
                        }

                        .chainName {
                            font-size: 12px;
                        }
                    }

                    .title {
                        margin-right: 8px;
                    }
                }
            }
        }

        @media (min-width: 576px) and (max-width: 1199.98px) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 10px;
        }
    }

    .projectTokenCardMobile {
        @media (max-width: 575.98px) {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .tokenDescriptionCard {
        background-color: $white;
        padding: 16px;
        border-radius: 6px;
        margin-top: 30px;

        .container {
            max-height: calc(250px - 32px);
            overflow-y: scroll;
            padding-right: 30px;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: $pattens-blue;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $nepal;
            }

            .descriptionSection {
                margin-top: 16px;

                &:first-child {
                    margin-top: 0;
                }

                .title {
                    @include font-style($size: 14px, $bold: 700, $color: $fiord, $lineHeight: 21px);
                }

                .text {
                    @include font-style($size: 14px, $bold: 500, $color: $fiord, $lineHeight: 21px);
                    margin-top: 4px;
                }
            }
        }

        @media (max-width: 1199.98px) {
            margin-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .container {
                max-height: none;
                padding-right: 0;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 1199.98px) {
        width: 100%;
        margin-left: 0;
    }
}
