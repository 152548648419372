@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardWrapper {
    margin-top: 50px;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
    padding: 40px;

    h4 {
        @include font-style($size: 17px, $bold: 700, $color: $fiord, $lineHeight: 25px);
    }

    h6 {
        @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 21px);
        margin-top: 15px;
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $catskill-white;
        margin-top: 45px;
    }

    .feedbackQuestions {
        margin-top: 30px;

    }
}
