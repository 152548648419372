@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: center);
    position: relative;

    .blurBox {
        background: linear-gradient(
            180.71deg,
            $dodger-blue-darker 22.77%,
            $cornflowerblue 79.44%,
            $lightseagreen 99.39%
        );
        filter: blur(120px);
        position: absolute;
        width: 100%;
        height: 120px;
    }

    .container {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 45px 20px;
        z-index: 2;

        .title {
            @include font-style($size: 40px, $bold: 400, $color: $fiord, $family: $font-koho);
        }

        .questionsContainer {
            @include display-flex($direction: column, $align: flex-start);
            margin-top: 50px;

            .link {
                margin-top: 20px;
                @include font-style($size: 17px, $bold: 700, $color: $fiord, $lineHeight: 25px);
                transition: 0.3s color;

                &:hover {
                    color: $dodger-blue-darker;
                }
            }

            & > a:first-child {
                margin-top: 0;
            }
        }

        @media (max-width: 991.98px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
