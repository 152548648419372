@import 'src/styles/variables';
@import 'src/styles/mixins';

.alertWrapper {
    position: fixed;
    width: 100%;
    top: 22px;
    z-index: 100;
    pointer-events: none;

    .alertContainer {
        @include display-flex($justify: flex-end);
        @include max-device-width();

        .alertBox {
            @include display-flex($align: flex-start, $justify: space-between);
            width: 440px;
            padding: 16px;
            margin-right: 80px;
            border-radius: 4px;
            box-shadow: 0px 6px 16px rgba(64, 107, 179, 0.45);
            pointer-events: visible;

            .mainContent {
                @include display-flex($align: flex-start, $direction: column);
                padding-right: 10px;

                & > div {
                    @include display-flex($align: center);

                    .alertIcon {
                        width: 24px;
                        height: 24px;
                    }

                    .title {
                        @include font-style($size: 14px, $bold: 400, $color: $white, $lineHeight: 21px);
                        margin-left: 12px;
                    }
                }

                .description {
                    @include font-style($size: 14px, $bold: 400, $color: $white);
                    margin-top: 16px;
                }
            }

            .closeButton {
                padding: 0;
                min-width: unset;
                margin: -5px -2px;

                .closeIcon {
                    @include font-style($size: 34px, $color: $white);
                }
            }
        }

        .alertSuccess {
            background-color: $lightseagreen;
        }

        .alertError {
            background-color: $lavender-rose;
        }

        .alertInfo {
            background-color: $nepal;
        }

        .alertNotification {
            background-color: $nepal;
        }
    }

    @media (max-width: 575.98px) {
        top: 0;

        .alertContainer {
            .alertBox {
                margin-right: 0;
            }
        }
    }
}
