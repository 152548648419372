@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputsWrapper {
    padding: 8px 0;

    .input {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
        width: 100%;
        padding: 8px;
        border: 1px solid $periwinkle;
        outline: none;
        border-radius: 8px;
    }
}
