@import 'src/styles/variables';
@import 'src/styles/mixins';

.userInfoContent {
    padding: 12px 20px 18px 20px;
    border-top: 1px solid $catskill-white;

    .followUser {
        @include display-flex($align: center, $justify: space-between);

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .followButton {
            @include font-style($size: 12px, $bold: 500, $color: $heliotrope-lighter, $transform: none);
            width: 56px;
            height: 24px;
            border: 2px solid $heliotrope-lighter;
        }
    }

    .tokensContainer {
        @include display-flex($justify: space-between);
        margin-top: 10px;
    }

    @media (max-width: 575.98px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}
