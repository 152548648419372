@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    display: flex;

    .block {
        @include display-flex($justify: center, $align: center);

        .mainInfo {
            @include display-flex($align: flex-start, $direction: column);

            .title {
                @include font-style($size: 40px, $bold: 400, $color: $aquamarine, $family: $font-koho);
                max-width: 400px;
            }

            .subTitle {
                @include font-style($size: 16px, $lineHeight: 24px, $bold: 400, $color: $white);
                max-width: 420px;
                margin-top: 30px;
            }

            .featuresContainer {
                @include display-flex($wrap: wrap);
                width: 100%;
                margin-top: 30px;

                .feature {
                    @include display-flex($align:center, $justify: center);
                    height: 37px;
                    padding: 6px 10px;
                    border: 1px solid $aquamarine;
                    margin: -1px -1px 0 0;

                    .name {
                        @include font-style($size: 18px, $bold: 500, $color: $white);

                        @media (max-width: 575.98px) {
                            @include font-style($size: 14px, $bold: 700);
                        }
                    }
                }
            }
        }

        @media (max-width: 575.98px) {
            flex-direction: column;
            width: 100%;

            .mainInfo {
                width: 100%;

                .title {
                    font-size: 28px;
                    margin-top: 16px;
                }

                .subTitle {
                    max-width: none;
                    margin-top: 10px;
                }
            }
        }
    }
}
