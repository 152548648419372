@import '../../../../../../../../../../styles/variables';

.content {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    margin-top: 25px;

    .axisTick {
        font-size: 10px;
        font-weight: 700;
        fill: $nepal;
    }
}
