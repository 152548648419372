@import 'src/styles/mixins';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.searchContainer {
    @include display-flex($align: center);
    padding: 9px 26px;
    height: 48px;
    background-color: $alice-blue;
    .searchButton {
        min-width: unset;
        border-radius: 50%;
        padding: 0;

        &:hover {
            background-color: transparent;
        }

        .searchIcon {
            color: $fiord;
            padding: 0;
            height: 30px;
            width: 30px;
        }

        .searchIconTable {
            color: $nepal;
        }
    }

    .inputContainer {
        @include display-flex();
        position: relative;
        width: 100%;
        height: 100%;
        .input {
            @include font-style($size: 14px, $color: $fiord);
            border: none;
            background-color: transparent;
            padding: 0 30px 0px 10px;
            width: 100%;
            &:focus {
                outline: none;
            }
        }

        .clearFieldButton {
            display: none;
            width: 25px;
            height: 25px;
            min-width: unset;
            padding: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                background-color: transparent;
            }

            .clearFieldIcon {
                @include font-style($size: 25px, $color: $fiord);
            }

            .clearFieldIconTable {
                color: $fiord;
            }
        }

        .clearButtonActive {
            display: block;
        }
    }
}
