@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 10px 20px 10px;
    border-bottom: 1px solid $catskill-white;

    & > div {
        @include display-flex($align: center);
        @include font-style($size: 10px, $bold: 700, $color: $nepal);
    }

    .number {
        width: 6%;
    }

    .name {
        width: 45%;
    }

    .holding {
        width: 31%;
    }

    .usdValue {
        width: 18%;
    }
}
