@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 24px 0;
    border-top: 1px solid $heather;

    & > div {
        width: 22%;

        & > span {
            @include font-style($size: 14px, $bold: 400, $color: $fiord);
        }

        .boldText {
            font-weight: 700;
        }

        .textUppercase {
            text-transform: uppercase;
        }

        .download {
            @include display-flex($align: center, $justify: center);
            @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
            width: 100px;
            height: 36px;
            background-color: $bright-turquoise;
            border-radius: 4px;

            &:hover {
                background-color: $bright-turquoise;
            }
        }

        &:last-child {
            @include display-flex($justify: flex-end);
            width: 12%;
        }
    }
}
