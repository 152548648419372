@import 'src/styles/variables';
@import 'src/styles/mixins';

.infoWrapper {
    @include display-flex($justify: space-between, $direction: column);
    margin-left: 120px;
    color: $white;

    .title {
        @include font-style($size: 25px, $bold: 400, $family: $font-koho);
    }

    .description {
        @include font-style($size: 14px, $bold: 400);
    }

    .linksContainer {
        margin-left: 0;

        & > a {
            margin-left: 12;
        }
    }
}
