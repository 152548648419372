@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($align: center, $justify: center);
    height: calc(100vh - #{$header-height});
    min-height: 700px;
    padding: 0 20px;

    .notFoundImage {
        width: 540px;
        height: 403px;
        margin-right: -76px;
    }

    .mainContent {
        margin-bottom: 20px;

        & > h1 {
            @include font-style($size: 64px, $bold: 400, $color: $white, $lineHeight: 70px, $family: $font-koho);
        }

        & > h2 {
            @include font-style($size: 40px, $bold: 400, $color: $white, $lineHeight: 40px, $family: $font-koho);
            margin-top: 14px;
        }

        .navigationBtn {
            @include font-style($size: 16px, $bold: 500, $color: $ghostwhite, $transform: none);
            margin-top: 30px;
            background-color: $bright-turquoise;
        }
    }
}
