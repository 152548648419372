@import 'src/styles/variables';
@import 'src/styles/mixins';

.linkWrapper {
    @include display-flex();
    margin-top: 20px;
    position: relative;
    padding: 10px;
    border-radius: 8px;

    &:first-child {
        margin-top: 0;
    }

    &:hover {
        .tooltip {
            @include display-flex($align: center, $justify: center);
        }
    }

    .content {
        @include display-flex($align: center);
        overflow: hidden;

        .pageImage {
            width: 24px;
            height: 24px;
            transition: 0.3s opacity ease-in-out;
        }

        .title {
            display: none;
            @include font-style($size: 16px, $bold: 400, $color: $white, $family: $font-koho);
            margin-left: 14px;
            white-space: nowrap;
            opacity: 0;
            transition: 0.3s opacity ease-in-out;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .tooltip {
        display: none;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        padding: 6px 8px;
        left: 57px;
        height: 30px;
        bottom: 5px;
        border-radius: 0 4px 4px 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        & > h6 {
            @include font-style($size: 16px, $bold: 400, $color: $bunker, $family: $font-koho);
            white-space: nowrap;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -15px;
            bottom: 0;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-right: 15px solid rgba(255, 255, 255, 0.8);
        }
    }
}

.linksWrapperExpanded {
    .content {
        .title {
            display: block;
            opacity: 1;
        }
    }
}

.linkActive {
    background: $left-sidebar-active-link;
}
