@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalContainer {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 15;
    background: rgba($fiord, 0.75);

    .modal {
        @include display-flex($direction: column);
        width: 100%;
        max-width: 650px;
        max-height: calc(100vh - 100px);
        background-color: $white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;

        &Header {
            @include display-flex($align: flex-start, $justify: space-between);
            background: linear-gradient(
                180.71deg,
                rgba(51, 123, 255, 0.2) 22.77%,
                rgba(103, 183, 244, 0.2) 61.88%,
                rgba(255, 255, 255, 0.2) 99.39%
            );
            padding: 30px;

            .title {
                @include font-style($size: 28px, $bold: 600, $color: $fiord, $family: $font-koho, $lineHeight: 25px);
            }

            .closeButton {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                min-width: unset;
                margin-top: -14px;
                margin-right: -6px;

                .closeIcon {
                    font-size: 30px;
                    color: $fiord;
                }
            }
        }

        &Body {
            padding: 10px 30px 20px;
            overflow-y: scroll;

            .form {
                @include display-flex($align: flex-end, $direction: column);

                & > div {
                    width: 100%;
                    position: relative;
                    margin-top: 24px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .errorText {
                        @include font-style($size: 14px, $bold: 500, $color: $cornflower-lilac);
                        position: absolute;
                        right: 0;
                    }
                }

                .selectWrapper {
                    & > div {
                        margin-top: 7px;
                    }
                }

                .title {
                    @include font-style($size: 14px, $bold: 700, $color: $fiord);
                    margin-bottom: 6px;
                }

                .input {
                    @include font-style($size: 16px, $color: $fiord);
                    width: 100%;
                    height: 40px;
                    padding: 8px;
                    border: none;
                    background: $ghostwhite;
                    border-radius: 4px;

                    &:focus {
                        outline: none;
                        border-color: $dodger-blue-darker;
                    }
                }

                .textAreaWrapper {
                    height: 300px;
                    margin-left: 0;

                    & > div {
                        height: 100%;

                        & > div {
                            height: 100%;

                            & > div {
                                height: 100%;

                                & > div {
                                    height: 100%;
                                    padding: 10px;
                                }

                                & > textarea {
                                    overflow-y: scroll;
                                    padding: 10px;
                                }
                            }
                        }
                    }
                }

                .publishButton {
                    @include font-style($size: 12px, $bold: 500, $color: $white, $transform: none);
                    height: 34px;
                    width: 122px;
                    margin-left: auto;
                    margin-top: 24px;
                    background-color: $bright-turquoise;

                    &:disabled {
                        background-color: $heather;
                    }
                }
            }
        }
    }
}
