@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .barLabel {
        @include font-style($size: 16px, $bold: 600, $lineHeight: 30px, $family: $font-quicksand, $fill: $white);
    }

    .barLabelPercentage {
        @include font-style($size: 10px, $bold: 500, $fill: $white, $family: $font-inter);
        position: absolute;
    }
}
