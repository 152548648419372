@import 'src/styles/variables';
@import 'src/styles/mixins';

.listItem {
    list-style: none;
    margin-top: 6px;

    div {
        @include font-style($size: 15px, $bold: 600, $color: $fiord, $lineHeight: 30px);
        cursor: pointer;
    }
}
