@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    padding: 10px 20px;
    background-color: $white;
    border-bottom: 1px solid $catskill-white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .titleWrapper {
        padding-bottom: 16px;

        .tableTitle {
            @include font-style($size: 14px, $bold: 600, $color: $fiord);

            @media (max-width: 767.98px) {
                font-size: 16px;
                margin-left: 8px;
            }
        }

        @media (max-width: 767.98px) {
            border-bottom: 1px solid $catskill-white;
            padding-bottom: 8px;
        }
    }

    .headerItems {
        @include display-flex();

        & > div {
            @include display-flex($align: center);

            & > div {
                @include display-flex($align: center);

                span {
                    @include table-header-font-styles();
                }
            }
        }

        .rank {
            width: 10%;
        }

        .tokenInfo {
            width: 29%;
        }

        .currencyValue {
            width: 35%;
        }

        .tokenBalance {
            width: 26%;
        }

        @media (max-width: 767.98px) {
            padding: 8px 8px 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
}
