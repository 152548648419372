@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex();
    padding: 16px;

    & > div {
        @include display-flex($align: center);
        @include font-style($size: 10px, $bold: 700, $color: $nepal);
    }

    .number {
        width: 5%;
    }

    .proposalName {
        width: 53%;
    }

    .date {
        width: 18%;
    }

    .voted {
        width: 18%;
    }

    .wonVote {
        width: 6%;
        white-space: nowrap;
    }
}
