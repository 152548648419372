@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    margin: 0 auto;
    padding: 24px 6px 50px;

    .carouselContainer {
        margin: 24px 20px 0 20px;
        background-color: #D7E4F4;
        padding: 16px 16px 10px 16px;
        border-radius: 16px;

        .title {
            @include display-flex($align: center);

            .infoIcon {
                margin: 0 8px;
            }

            .titleText {
                @include font-style($size: 18px, $bold: 700, $color: #1F3B82);

                @media (max-width: 767.98px) {
                    font-size: 16px;
                }
            }
        }

        .carousel {
            width: 100%;
            padding: 0 15px 0 15px;

            .carouselWrapper {
                max-width: 1270px;
                border: none;

                .notFoundWrapper {
                    width: auto;
                    margin-left: 16px;
                    margin-right: 16px;
                }

                & > div {
                    & > div {
                        & > a {
                            margin: 0 4px;
                        }
                    }
                }

                @media (max-width: 767.98px) {
                    & > div {
                        & > div {
                            & > a {
                                &:first-child {
                                    margin-left: 16px;
                                }

                                &:last-child {
                                    margin-right: 16px;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 767.98px) {
                padding-left: 0;
                padding-right: 0;

                .stakeholdersContainer {
                    height: 114px;
                }
            }
        }
    }

    .votingToken {
        margin: 24px 20px 0;
    }

    .avgVotesAndTokenPriceGraph {
        margin: 24px 20px 0;
    }

    .successfulProposalsAndTokenPriceGraph {
        margin: 24px 20px 0;
    }

    .votesAndTokenPriceGraph {
        margin: 24px 20px 0;
    }

    .proposalsAndTokenPriceGraph {
        margin: 24px 20px 0;
    }

    @media (max-width: 767.98px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    padding: 24px 16px 0 16px;

    @media (max-width: 991.98px) {
        grid-template-columns: 1fr;
        padding-left: 16px;
        padding-right: 16px;
    }

    .gridItem {
        display: block;
        flex-direction: column;
        overflow: hidden;
        background-color: #D7E4F4;
        padding: 16px;
        border-radius: 16px;
    }
}