@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    .categoriesBannerWrapper {
        position: relative;

        .blurBox {
            background-image: url('../../assets/png/shadow_bg.png');
            position: absolute;
            background-repeat: repeat-x;
            width: 100%;
            height: 100%;
        }

        .content {
            position: relative;
            width: 100%;
            max-width: 1310px;
            margin: 0 auto;
            padding: 45px 20px 0;

            .headSection {
                @include display-flex($justify: space-between);

                .details {
                    color: $fiord;
                    padding-right: 20px;

                    .title {
                        @include font-style($size: 40px, $lineHeight: 44px, $bold: 400, $family: $font-koho);
                    }

                    .subTitle {
                        @include font-style($size: 16px, $lineHeight: 18px, $bold: 400);
                        margin-top: 10px;
                    }

                    @media (max-width: 575.98px) {
                        .title {
                            font-size: 28px;
                            line-height: 36px;
                        }

                        .statsCounters {
                            font-size: 12px;
                        }
                    }
                }

                .banner {
                    width: 536px;
                    height: 100px;
                    border-radius: 4px;
                    border: 2px solid $white;
                    cursor: pointer;

                    @media (max-width: 767.98px) {
                        width: 100%;
                        height: auto;
                    }
                }

                @media (max-width: 991.98px) {
                    flex-direction: column;
                    align-items: flex-start;

                    .details {
                        margin-bottom: 35px;
                        padding-right: 0;
                    }
                }
            }

            .categoriesContainer {
                max-width: 1100px;
                margin: 0 auto;
                padding: 14px 20px 40px;

                .resourcesCategoriesContainer {
                    @include display-flex($justify: center, $wrap: wrap);
                }
            }
        }
    }

    .resourcesCardsWrapper {
        position: relative;
        width: 100%;
        max-width: 1310px;
        margin: 0 auto;
        padding: 0px 20px 45px;

        .resourcesCardsContainer {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: repeat(3, 1fr);

            @media (min-width: 576px) and (max-width: 991.98px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 575.98px) {
                grid-template-columns: 1fr;
            }
        }
    }

    .resourcesNotFound {
        @include font-style($size: 17px, $bold: 700, $color: $lavender-magenta);
        margin-top: 50px;
    }
}
