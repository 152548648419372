@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardHeader {
    @include display-flex($align: center, $justify: space-between);
    height: 46px;
    min-height: 46px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: transparent;

    .title {
        @include font-style($size: 20px, $color: $aquamarine, $bold: 400, $family: $font-koho);

        .titleComplement {
            @include font-style($size: 12px, $color: $aquamarine, $bold: 400, $family: $font-koho);
            white-space: nowrap;
            margin-inline: 4px;
        }

        @media (max-width: 575.98px) {
            font-size: 20px;

            .titleComplement {
                font-size: 10px;
            }
        }
    }

    .titleFontIncreased {
        font-size: 25px;

        @media (max-width: 575.98px) {
            font-size: 20px;
        }
    }

    .infoIcon {
        @include font-style($size: 22px, $color: $white);
        cursor: pointer;
        transition: color 0.2s;

        &:hover {
            color: $white;
        }
    }
}
