@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($direction: column, $justify: center);
    margin-left: 14px;

    .notificationsButtonWrapper {
        @include display-flex($justify: center);
        position: relative;

        .notificationsButton {
            position: relative;
            min-width: unset;
            padding: 0;
            margin-top: -3px;

            &:hover {
                background-color: transparent;

                .notificationsIcon {
                    color: $fiord;
                }
            }

            .notificationsIcon {
                @include font-style($size: 37px, $color: $nepal);
                transition: 0.3s color;
            }

            .circle {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: $bright-turquoise;
                position: absolute;
                top: 7px;
                right: 8px;
            }

            .notificationsOpen {
                color: $dodger-blue-darker;

                &:hover {
                    color: $dodger-blue-darker;
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        margin-left: 10px;
    }
}
