@import 'src/styles/variables';
@import 'src/styles/mixins';

.buttonsWrapper {
    @include display-flex();
    margin-bottom: -22px;
    position: relative;

    & > button:last-child {
        margin-left: 14px;
    }

    .notificationTooltip {
        left: -83px;
        bottom: 35px;

        @media (max-width: 991.98px) {
            left: auto;
            right: -16px;

            & > div {
                &::after {
                    right: 45px;
                }
            }
        }
    }
}
