@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .table {
        &Wrapper {
            width: 100%;
            border-radius: 6px;
            overflow-x: scroll;
            margin-top: 6px;
            padding: 10px;

            .tableContainer {
                min-width: 700px;
                background: $white;
                box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
                border-radius: 6px;
            }

            @media (max-width: 767.98px) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    .loaderBox {
        position: static;
    }

    .dataNotFound {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 16px;

        @media (max-width: 767.98px) {
            margin-left: 16px;
            margin-right: 16px;
        }
    }
}
