@import 'src/styles/variables';
@import 'src/styles/mixins';

.filterWrapper {
    width: 180px;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    margin-right: 35px;
    color: #3d536c;

    .filterHeader {
        color: #3d536c;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #cfe1ff;

        .filterHeaderIcon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }

    .orderFilterWrapper {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        border-bottom: 1px solid #cfe1ff;

        .orderFilter {
            font-family: 'Inter';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 120%;
            color: #3d536c;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            margin-bottom: 20px;

            .arrowIcon {
                font-size: 24px;
            }

            .diamondIcon {
                width: 24px;
                height: 24px;
            }

            .discussionsFilterIcon {
                font-size: 24px;
            }

            .orderDesc {
                transform: rotate(90deg);
            }

            .orderAsc {
                transform: rotate(-90deg);
            }

            .votersOrderAsc {
                transform: rotate(180deg);
            }

            & > span {
                margin-left: 10px;
            }
        }
    }

    .discussionsFilter {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        border-bottom: 1px solid #cfe1ff;

        .discussionsFilterHeader {
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 120%;
            cursor: pointer;
            user-select: none;
        }
    }

    .orderByNumberOfMembersWrapper,
    .orderByNumberOfTokensWrapper {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        font-weight: 700;
        font-size: 10px;
        line-height: 120%;

        .orderByNumberOfMembersDesc,
        .orderByNumberOfMembersAsc,
        .orderByNumberOfTokensDesc,
        .orderByNumberOfTokensAsc {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            margin-top: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;

            & > img {
                width: 27px;
                height: 27px;
            }

            & > span {
                margin-left: 10px;
            }
        }

        .orderByNumberOfMembersHeader {
            @include font-style($size: 12px, $transform: uppercase);
        }
    }

    @media (max-width: 767.98px) {
        width: 100%;
    }
}

.cursorPointer {
    cursor: pointer;
}

.bold {
    font-weight: bold !important;
}

.elementSelected {
    flex-grow: 1;
    padding: 2px 4px;
    background-color: $pattens-blue;
    border-radius: 4px;
    font-weight: bold;
}
