.headingText {
    font-size: 17px;
    font-weight: 700;
    color: #545c61;
    padding: 40px 0 20px;
}

.descriptionText {
    font-size: 14px;
    font-weight: 400;
    color: #798d99;
}

.subheadingText {
    font-size: 14px;
    font-weight: 700;
    color: #798d99;
    padding: 40px 0 20px;
}

section {
    scroll-margin: 90px;
}

.active {
    color: #9069ff !important;
    position: relative;
}

.active::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    right: 0;
    background-color: #9069ff;
}
