@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex();
    padding: 0 14px 10px;

    & > div {
        @include display-flex($align: center);

        & > div {
            @include display-flex($align: center);

            span {
                @include font-style($size: 14px, $bold: 700, $color: $nepal, $family: $font-inter);
            }
        }
    }

    .rank {
        width: 6%;
    }

    .members {
        width: 28%;
    }

    .type {
        width: 12%;
    }

    .participationScore {
        width: 18%;
    }

    .daos {
        width: 12%;
    }

    .proposals {
        width: 12%;
    }

    .votes {
        width: 12%;
    }
}
