@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    border-bottom: 1px solid $mystic;
    .tabsContainer {
        @include display-flex();
        max-width: 100%;
        margin: 0 auto;
        .tabItem {
            @include display-flex($align: center, $justify: center);
            @include font-style($size:25px, $bold: 700, $color: $nepal, $family: $font-koho);
            text-align: center;
            width: 50%;
            height: 72px;
            cursor: pointer;
            position: relative;
            transition: 0.5s all;
            border: none;
            background: $white;
            outline: none;
            text-transform: capitalize;
            @media (max-width: 767.98px) {
                width: 100%;
                height: 44px;
                border-right: none;
            }

            &:hover {
                color: $darkslategray;
            }

            &.activeTab{
                color: $fiord;
            }
        }
    }
}
