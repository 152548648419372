@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex();
    background-color: $white;
    padding: 10px 16px;

    .inputContainer {
        @include display-flex($grow: 1);
        position: relative;

        .input {
            @include font-style($size: 16px, $color: $fiord);
            width: 100%;
            height: 30px;
            background-color: $ghostwhite;
            border-radius: 4px;
            padding: 2px 10px 2px 30px;
            border: 1px solid $mystic;

            &:focus {
                outline: none;
            }
        }

        .searchIcon {
            @include font-style($size: 25px, $color: $fiord);
            position: absolute;
            left: 2px;
            top: 3px;
        }
    }

    .categoriesButton {
        @include font-style($size: 12px, $bold: 500, $color: $fiord, $transform: none);
        height: 30px;
        margin-left: 24px;
        min-width: unset;
        color: $fiord;
        border: 2px solid $nepal;
        border-radius: 20px;

        .arrowIcon {
            @include font-style($size: 20px);
        }
    }

    .categoriesButtonActive {
        border-color: $bright-turquoise;

        .arrowIcon {
            transform: rotate(180deg);
        }
    }
}
