@import 'src/styles/variables';
@import 'src/styles/mixins';

.footerSection {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    min-height: 125px;
    background-color: $white;
    padding: 30px;

    .wrapper {
        @include display-flex($align: center, $justify: space-between);
        width: 100%;
        max-width: 1270px;

        .content {
            @include display-flex($align: center);

            .title {
                @include font-style($size: 14px, $bold: 600, $color: $fiord);
                margin-right: 30px;
            }

            .selectedApis {
                @include display-flex($align: center);

                .choosenApi {
                    @include display-flex($align: center, $justify: space-between);
                    width: 105px;
                    padding: 6px 8px;
                    border-radius: 4px;
                    background-color: $ghostwhite;
                    margin-right: 12px;

                    .name {
                        @include font-style($size: 12px, $bold: 500, $color: $fiord, $transform: lowercase);
                    }

                    .removeButton {
                        min-width: unset;
                        padding: 0;

                        &:hover {
                            background-color: transparent;
                        }

                        .removeIcon {
                            @include font-style($size: 18px, $color: $nepal);
                        }
                    }
                }
            }
        }

        .choosePlanBtn {
            @include display-flex($align: center, $justify: space-between);
            @include font-style($size: 16px, $bold: 500, $color: $bright-turquoise, $transform: none);
            width: 170px;
            height: 44px;
            padding: 6px 10px;
            border: 2px solid $bright-turquoise;

            &:hover {
                background-color: transparent;
            }

            .arrowIcon {
                font-size: 26px;
                margin-right: -4px;
            }
        }
    }
}