@import 'src/styles/variables';
@import 'src/styles/mixins';

.filterSection {
    width: 100%;
    max-width: 575px;

    .filterBox {
        @include display-flex();
        height: 48px;
        background-color: $white;
        border: 1px solid $mystic-darker;
        border-radius: 4px;
        position: relative;

        .inputContainer {
            position: relative;
            flex-grow: 1;

            .input {
                @include font-style($size: 16px, $bold: 400, $color: $fiord);
                width: 100%;
                height: 100%;
                padding: 8px 40px;
                border: none;
                border-radius: 4px;

                &::placeholder {
                    @include font-style($size: 16px, $bold: 400, $color: $nepal);
                }

                &:focus {
                    outline: none;
                }
            }

            .searchIcon,
            .clearSearchIcon {
                @include font-style($size: 26px, $color: $nepal);
                position: absolute;
                top: 11px;
                left: 8px;
                pointer-events: none;
                transition: 0.2s color;
            }

            .clearSearchIcon {
                left: initial;
                right: 8px;
                cursor: pointer;
                pointer-events: initial;
            }

            .searchIconActive {
                color: $dodger-blue-darker;
            }
        }

        @media (max-width: 575.98px) {
            width: calc(100% - 25px);
            height: 36px;
            margin-left: auto;

            .inputContainer {
                .input {
                    padding-left: 10px;
                }

                .searchIcon,
                .clearSearchIcon {
                    top: 5px;
                }

                .searchIcon {
                    left: -30px;
                }
            }
        }
    }

    .selectedOrganizationsContainer {
        @include display-flex($wrap: wrap);

        .selectedOrganization {
            @include display-flex($align: center);
            margin: 20px 13px 0 0;
            padding: 6px 8px 6px 10px;
            background-color: $lightskyblue;
            border-radius: 4px;

            .organizationName {
                @include font-style($size: 13px, $bold: 400, $color: $white);
                max-width: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 10px;
            }

            .removeButton {
                width: 24px;
                height: 24px;
                min-width: unset;

                &:hover {
                    background-color: transparent;

                    .closeIcon {
                        color: $white;
                    }
                }

                .closeIcon {
                    @include font-style($size: 21px, $color: $white);
                    transition: all 0.2s;
                }
            }
        }
    }

    @media (max-width: 575.98px) {
        padding-bottom: 25px;
    }
}
