@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($justify: space-between, $direction: column);
    width: 270px;
    min-width: 270px;
    background: rgba($white, 0.25);
    border-radius: 6px;
    padding: 16px;

    .notificationIcon {
        width: 40px;
        height: 49px;
    }

    .text {
        @include font-style($size: 16px, $color: $aquamarine, $bold: 500, $lineHeight: 24px);
        margin-top: 35px;

        & > a {
            color: $white;
            font-weight: 600;
        }
    }

    @media (max-width: 767.98px) {
        width: 100%;
        margin-top: 35px;
        height: auto;

        .title,
        .description {
            margin-top: 14px;
        }
    }
}
