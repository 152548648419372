@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    .searchElementName {
        @include font-style($size: 14px, $color: $fiord, $bold: 600);
        margin: 5px 20px 10px;
    }

    .info {
        @include display-flex($align: center, $justify: space-between);
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
            background-color: $zircon;
        }

        .mainInfo {
            @include display-flex($align: center);
            overflow-x: hidden;

            .avatar {
                width: 28px;
                height: 28px;
                border-radius: 50%;
            }

            .name {
                @include font-style($size: 14px, $color: $regent-gray, $bold: 700);
                margin-left: 10px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.containerOverflow {
    max-height: 270px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $darkslategray;
    }
}
