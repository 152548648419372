@import 'src/styles/mixins';

.tableWrapper {
    @include display-flex($justify: center);
    padding: 0px 20px;
    overflow-x: scroll;
    width: 100%;

    .tableContainer {
        width: 1270px;
        min-width: 600px;

        .rowsWrapper {
            box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
            border-radius: 6px;

            & > div:first-child {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }

            & > div:last-child {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }
    }

    @media (max-width: 767.98px) {
        justify-content: flex-start;
    }
}
