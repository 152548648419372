@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 20px 20px 0;

    & > div {
        @include display-flex($align: center);
        @include font-style($size: 12px, $bold: 700, $color: $fiord);
    }

    .number {
        width: 10%;
    }

    .name {
        width: 45%;
        padding-right: 10px;

        .tokenLogoWrapper {
            width: 28px;
            height: 28px;

            .tokenLogo {
                width: 28px;
                height: 28px;
                border-radius: 50%;
            }
        }

        & > span {
            font-size: 12px;
            margin-left: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .holding {
        @include font-style($size: 12px, $bold: 400, $color: $regent-gray);
        width: 25%;
    }

    .usdValue {
        @include font-style($size: 12px, $bold: 400, $color: $regent-gray);
        width: 20%;
    }
}
