@import 'src/styles/variables';
@import 'src/styles/mixins';

.governanceInfoWrapper {
    margin-top: 80px;

    .fieldsWrapper {
        display: grid;
        grid-template-columns: minmax(120px, 120px) minmax(120px, 320px);
        column-gap: 30px;
        margin-top: 30px;
        row-gap: 30px;
    }

    .addSocialsButton {
        background-color: $ghostwhite;
        color: $dodger-blue-darker;
        height: 30px;
        margin-top: 35px;

        .addIcon {
            font-size: 24px;
            margin-left: -4px;
        }

        .title {
            @include font-style($size: 14px, $bold: 500, $transform: none);
        }

        &:hover {
            background-color: $ghostwhite;
        }
    }
}