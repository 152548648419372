@import 'src/styles/variables';
@import 'src/styles/mixins';

.logoSection {
    @include display-flex();
    margin-top: 35px;

    .logoWrapper {
        width: 80px;
        height: 80px;
        background-color: $mystic;
        border-radius: 50%;
        position: relative;

        .organizationLogo {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        .removeLogoButton {
            width: 23px;
            height: 23px;
            min-width: unset;
            background-color: $white;
            border-radius: 50%;
            border: 2px solid $dodger-blue-darker;
            position: absolute;
            bottom: 0;
            right: -4px;

            .closeIcon {
                @include font-style($size: 18px, $color: $dodger-blue-darker);
            }
        }
    }

    .logoOptions {
        @include display-flex($direction: column, $justify: space-between);
        margin-left: 20px;

        .logoInfoText {
            .title {
                @include font-style($size: 14px, $bold: 400, $color: $fiord);
            }

            .notificationText {
                @include font-style($size: 10px, $bold: 500, $color: $nepal);
                margin-top: 4px;
            }

            .errorText {
                text-transform: uppercase;
                color: $lavender-magenta;

            }
        }

        .imageUploaderWrapper {
            width: 64px;
            height: 30px;
            position: relative;

            .imageUploaderInput {
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0;
            }

            .container {
                @include display-flex($align: center, $justify: center);
                width: 100%;
                height: 100%;
                border-radius: 4px;
                background-color: $ghostwhite;

                & > span {
                    @include font-style($size: 14px, $bold: 500, $color: $dodger-blue-darker);
                }
            }
        }
    }
}