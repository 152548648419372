@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex();
    padding: 0 14px 8px;

    & > div {
        @include display-flex($align: center);
        width: 8%;

        & > div {
            @include display-flex($align: center);

            span {
                @include table-header-font-styles();
            }
        }
    }

    .rank {
        width: 5%;
    }

    .organization {
        width: 15%;
    }

    .treasury {
        width: 9%;
    }

    .topTokens {
        width: 16%;
    }

    .mainTreasuryChain {
        width: 10%;
    }

    .potentialVoters {
        width: 13%;
    }

    .votes {
        width: 8%;
    }

    .changesPerDay {
        width: 9%;
    }

    @media (max-width: 575.98px) {
        .organization {
            width: 15%;
        }

        .topTokens {
            width: 18%;
        }

        .treasury {
            width: 8%;
        }

        .changesPerDay {
            width: 10%;
        }

        .votes,
        .proposals,
        .activeMembers {
            width: 9%;
        }
    }
}
