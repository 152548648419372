@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: center);
    position: relative;

    .blurBox {
        background: linear-gradient(180.71deg, $dodger-blue-darker 22.77%, $cornflowerblue 79.44%, $lightseagreen 99.39%);
        filter: blur(120px);
        position: absolute;
        width: 100%;
        height: 120px;
    }

    .container {
        width: 860px;
        padding: 50px 30px;
        z-index: 2;

        .textInfo {
            .title {
                @include font-style($size: 34px, $color: $fiord, $bold: 400, $family: $font-koho);
            }

            .description {
                @include font-style($size: 14px, $color: $fiord, $bold: 400, $lineHeight: 21px);
                margin-top: 10px;
            }
        }

        @media (max-width: 575.98px) {
            padding-top: 40px;
            padding-bottom: 40px;

            .title {
                font-size: 28px;
            }
        }
    }
}
