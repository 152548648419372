@import 'src/styles/variables';
@import 'src/styles/mixins';

.headerButtons {
    @include display-flex();

    .headerSocialLinks {
        margin-left: 14px;
    }

    .plansButtonWrapper {
        position: relative;
    }

    @media (max-width: 575.98px) {
        align-items: center;
    }
}
