@import 'src/styles/variables';
@import 'src/styles/mixins';

.contentWrapper {
    @include display-flex($align: flex-start, $justify: flex-end, $direction: column);

    .checkboxItems {
        @include display-flex($align: center);
        margin-bottom: 16px;

        .label {
            @include font-style($size: 25px, $bold: 400, $color: $fiord, $family: $font-koho);
            margin-left: 10px;
            margin-top: -2px;
        }
    }

    .card {
        width: 100%;
        background-color: $white;
        border-radius: 6px;
        padding: 14px;
        box-shadow: 0px 4px 10px rgba(64, 107, 179, 0.1);

        &Header {
            @include display-flex($align: center, $justify: space-between);
            padding-bottom: 14px;
            border-bottom: 1px solid $ghostwhite;

            .textInfo {
                .title {
                    @include font-style($size: 14px, $bold: 600, $color: $fiord);
                }

                .description {
                    @include font-style($size: 10px, $bold: 700, $color: $nepal);
                    margin-top: 4px;
                }
            }

            .infoBtn {
                @include font-style($size: 12px, $bold: 500, $color: $nepal, $transform: none);
                width: 45px;
                min-width: unset;
                height: 30px;
                border: 1px solid $nepal;
                margin-left: 16px;
                transition: 0s all;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        &Body {
            padding-top: 18px;

            .option {
                @include display-flex($align: center);
                @include font-style($size: 14px, $bold: 400, $color: $fiord);
                position: relative;
                margin-left: 14px;
                margin-top: 14px;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: $dodger-blue-darker;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    left: -14px;
                    top: 7px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .cardSelected {
        background-color: $dodger-blue-darker;

        .cardHeader {
            .textInfo {
                .title {
                    color: $white;
                }

                .description {
                    color: $aquamarine;
                }
            }

            .infoBtn {
                background-color: $white;
                color: $dodger-blue-darker;
                border: none;
            }
        }

        .cardBody {
            .option {
                color: $white;
            }
        }
    }
}