@import 'src/styles/variables';
@import 'src/styles/mixins';

.statsContainer {
    .aumInfo {
        @include display-flex($justify: space-between);
        padding-bottom: 8px;
        border-bottom: 1px solid $mystic;

        .organizationAUM {
            @include font-style($size: 14px, $bold: 700, $color: $heather);
            margin: 0;
            margin-right: 4px;
        }
    }

    .membersProposalsInfo {
        @include display-flex($align: center, $justify: space-between);
        margin-top: 8px;

        & > div,
        > svg {
            @include display-flex($align: center);
            color: $heather;
        }

        & > :last-child {
            margin-left: 30px;
        }
    }

    & span {
        @include font-style($size: 14px, $bold: 700, $color: $fiord);
    }
}
