@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.totalVotes {
    width: 40%;
}

.voters {
    width: 35%;
}

.avgVotes {
    width: 25%;

    @include display-flex();
    overflow: hidden;
    padding-right: 20px;

    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
