@import 'src/styles/variables';
@import 'src/styles/mixins';

.substormsWrapper {
    @include display-flex($wrap: wrap);

    .substorm {
        @include display-flex($align: center, $justify: center);
        border-radius: 4px;
        border: 1px solid $fiord;
        padding: 2px 4px;
        margin-right: 10px;

        &Title {
            @include font-style($size: 10px, $color: $fiord, $bold: 500);
        }
    }
}
