@import 'src/styles/variables';
@import 'src/styles/mixins';

.radioGroup {
    display: flex;

    .inputWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

        .label {
            @include font-style($size: 14px, $bold: 500, $color: $fiord);
            margin: 0;
            margin-left: 6px;
            cursor: pointer;
        }

        .checkedLabel {
            font-weight: 700;
        }

        & > input {
            margin: 0;
            appearance: none;
            position: relative;
            border: 2px solid #3d536c;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            cursor: pointer;

            &.checked {
                border-color: #0fdab5;
            }

            &.checked::after {
                content: '';
                top: 0;
                left: 0;
                border-radius: 50%;
                background-color: #0fdab5;
                height: 10px;
                width: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        & > span {
            color: #9cb4c2;
        }
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
    }
}
