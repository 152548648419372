@import 'src/styles/variables';
@import 'src/styles/mixins';

.buttonWrapper {
    @include display-flex($direction: column, $align: center);

    .circleButton {
        width: 54px;
        height: 54px;
        min-width: unset;
        border-radius: 50%;

        &:hover {
            box-shadow: 0px 4px 12px rgba(92, 149, 255, 0.4);
        }

        .loader {
            position: static;
        }
    }

    .backgroundGradient {
        background: linear-gradient(135deg, #3759f6 12.96%, #1ac1e5 57.46%, #0fdab5 87.04%);

        &:hover {
            background: linear-gradient(135deg, #2343DB 12.96%, #13B0D2 57.46%, #48BFAA 87.04%);
        }
    }

    .backgroundGreen {
        background-color: $bright-turquoise;

        &:hover {
            background-color: $puerto-rico;
        }
    }

    .title {
        @include font-style($size: 16px, $bold: 500, $color: $fiord);
        white-space: nowrap;
        margin-top: 8px;
    }
}

.marginRight {
    margin-right: 25px;
}
