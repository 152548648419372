@import 'src/styles/variables';
@import 'src/styles/mixins';

.uploaderWrapper {
    position: absolute;
    left: -15px;
    top: 40px;

    .avatarUploadButton {
        width: 40px;
        height: 40px;
        min-width: auto;
        background-color: $dodger-blue-darker;
        color: $white;
        border-radius: 50%;
        border: 2px solid $white;
        padding: 0;
        box-shadow: 0px 0px 12px rgba(6, 102, 247, 0.2);

        &:hover {
            background-color: $dodger-blue-darker;
        }

        .title {
            @include font-style($size: 16px, $bold: 500, $color: $bright-turquoise, $transform: none, $align: center);
        }
    }

    .uploadActive {
        animation-name: spin;
        animation-duration: 2500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    .uploadLoading {
        padding-right: 20px;
    }

    @media (max-width: 767.98px) {
        top: 0;

        .avatarUploadButton {
            width: 34px;
            height: 34px;

            .cameraIcon {
                font-size: 18px;
            }
        }
    }
}
