@import 'src/styles/variables';
@import 'src/styles/mixins';

.organizationsStatsCardContainer {
	@include display-flex($direction: column);
	width: 100%;
	background: linear-gradient(180deg, #337bff 34.12%, #1db3d2);
	border-radius: 5px;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;

	&.organizationsStatsCardWrapper {
		
		& > hr {
			border: 1px solid $lightblue;
			width: 100%;
			margin: 0;
		}

		& > div[class*='card']:first-child div[class*='Body'] {
			border-radius: unset;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		& > div[class*='card']:last-child div[class*='Body'] {
			border-radius: unset;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		& > div {
			&:first-child {
				height: 125px;
			}

			&:last-child {
				height: 140px;
			}
		}
	}
}
