@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    margin: 0 auto;
    padding: 10px 6px 50px;

    .mobileDescriptionSection {
        @include display-flex($align: flex-start, $direction: column);
        margin-bottom: 20px;
        padding: 0 16px;

        .textDescription {
            @include font-style($size: 22px, $color: $fiord, $bold: 400, $lineHeight: 27px, $family: $font-koho);
        }

        .readMore {
            @include display-flex($align: center);
            color: $fiord;
            margin-top: 14px;

            .text {
                @include font-style($size: 14px, $bold: 600);
            }

            .arrowIcon {
                margin-bottom: -2px;
                margin-left: 4px;
            }
        }
    }

    .bannerWrapper {
        @include display-flex($justify: flex-end);
        max-width: 1290px;
        margin: 0 auto -25px;
        padding: 0 10px;

        .banner {
            width: 536px;
            height: 100px;
            border-radius: 4px;
            border: 2px solid $white;

            @media (max-width: 767.98px) {
                width: 100%;
            }
        }

        @media (max-width: 991.98px) {
            margin-bottom: 0;
        }

        @media (max-width: 575.98px) {
            display: none;
        }
    }

    .treasuryOverTime {
        padding: 24px 16px 0 16px;

        .data {
            display: block;
            flex-direction: column;
            overflow: hidden;
            background-color: #D7E4F4;
            padding: 16px;
            border-radius: 16px;
        }
    }

    .treasuryTrendsTable {
        padding: 24px 16px 0 16px;
    }

    .treasuryCompositionTable {
        padding: 24px 16px 0 16px;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        padding: 24px 16px 0 16px;

        @media (max-width: 991.98px) {
            grid-template-columns: 1fr;
            padding-left: 16px;
            padding-right: 16px;
        }

        .gridItem {
            display: block;
            flex-direction: column;
            overflow: hidden;
            background-color: #D7E4F4;
            padding: 16px;
            border-radius: 16px;
        }
    }

    @media (max-width: 767.98px) {
        padding-left: 0;
        padding-right: 0;
    }
}
