@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardsContent {
    padding: 30px 0;
    border-top: 1px solid $heather;

    .title {
        @include font-style($size: 15px, $bold: 600, $color: $fiord);
        margin: 0;
    }

    .subTitle {
        @include font-style($size: 10px, $bold: 500, $color: $nepal);
        margin: 0;
        margin-top: 4px;
    }

    .cardsSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin-top: 14px;

        @media (min-width: 576px) and (max-width: 767.98px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 575.98px) {
            grid-template-columns: 1fr;
            max-height: 200px;
            overflow-y: scroll;
        }
    }
}
