@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: flex-end);
    margin-left: 20px;

    .circleWrapper {
        @include display-flex($align: center, $justify: center, $direction: column);
        position: relative;

        .container {
            @include display-flex($align: center, $justify: center);
            width: 40px;
            height: 40px;
            cursor: pointer;
            border-radius: 50%;
            transition: 0.3s border-color;

            .userAvatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            &:hover {
                border-color: $fiord;

                .personIcon {
                    color: $fiord;
                }
            }
        }

        .title {
            @include font-style($size: 16px, $bold: 500, $color: $fiord);
            margin-top: 8px;
            white-space: nowrap;

            @media (max-width: 767.98px) {
                display: none;
            }
        }
    }

    @media (max-width: 991.98px) {
        width: auto;
        margin-bottom: 0;

        .circleWrapper {
            .container {
                width: 36px;
                height: 36px;

                .userAvatar {
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        margin-left: 14px;
    }
}
