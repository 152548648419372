@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    margin-top: 30px;

    .tabsContainer {
        @include display-flex();
        max-width: 1270px;
        margin: 0 auto;
        overflow-x: auto;
        overflow-y: hidden;
        border-bottom: 1px solid $heather;

        .tabItem {
            @include display-flex($align: center, $justify: center);
            text-align: center;
            padding: 14px;
            min-width: 110px;
            cursor: pointer;
            position: relative;
            transition: 0.5s all;
            flex: 0 0 auto;

            &:first-child {
                white-space: nowrap;
                margin-left: 14px !important;
            }

            &:hover {
                & > div {
                    & > span {
                        color: $dodger-blue-darker;
                    }
                }
            }

            @media (max-width: 767.98px) {
                border: none;
                width: 100%;
            }
        }

        .activeTab {
            &::before {
                content: '';
                position: absolute;
                bottom: -1px;
                width: 100%;
                height: 3px;
                background-color: $dodger-blue-darker;
            }
        }

        .itemContent {
            @include display-flex($justify: space-between);
            align-items: center;

            span {
                @include font-style($size: 16px, $bold: 500, $color: $fiord);
                transition: 0.3s color;

                @media (max-width: 767.98px) {
                    @include font-style($size: 14px, $bold: 700, $transform: capitalize);
                }
            }

            .premium {
                @include display-flex($align: center, $justify: center);
                padding: 2px 10px;
                background: #447CF7;
                border-radius: 12px;
                margin-left: 8px;
                height: 20px;

                .text {
                    @include font-style($size: 10px, $bold: 700, $color: $white, $transform: uppercase);
                }
            }
        }

        @media (max-width: 991.98px) {
            padding: 0;
        }

        @media (max-width: 767.98px) {
            height: 44px;
            background-color: $white;
        }
    }

    @media (max-width: 767.98px) {
        margin-top: 0;
    }
}
