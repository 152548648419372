@import 'src/styles/variables';

.iconsWrapper {
    display: flex;
    flex-direction: column;
    margin-right: 4px;

    .arrowUp {
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 5px solid $nepal;
        transition: border 0.3s;
    }

    .arrowDown {
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid $nepal;
        margin-top: 3px;
        transition: border 0.3s;
    }
}

.iconsActive {
    .arrowUp {
        border-bottom-color: $bright-turquoise;
    }

    .arrowDown {
        border-top-color: $bright-turquoise;
    }
}
