@import 'src/styles/variables';
@import 'src/styles/mixins';

.addressOptions {
    @include display-flex($align: center, $wrap: nowrap);
    margin-top: 10px;

    .address {
        @include font-style($size: 14px, $bold: 400, $lineHeight: 21px, $color: $white);
        margin-right: 8px;
    }

    .copyButton {
        min-width: unset;
        padding: 0px;

        .copyIcon {
            width: 24px;
            height: 24px;
        }

        &:hover {
            background-color: transparent;
        }
    }

    @media (max-width: 767.98px) {
        margin-top: 4px;

        .copyButton {
            .copyIcon {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.tagLineText {
    @include font-style($size: 17px, $bold: 700, $lineHeight: 25px, $color: $white);
    margin-top: 10px;
    overflow-wrap: anywhere;

    @media (max-width: 767.98px) {
        margin-top: 18px;
    }
}
