@import 'src/styles/mixins';

.container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;

    .content {
        padding: 10px 0 60px;
    }
}
