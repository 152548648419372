@import 'src/styles/variables';
@import 'src/styles/mixins';

.menuWrapper {
    width: 200px;
    min-width: 200px;
    min-height: 100vh;
    border-right: 1px solid $solitude;

    .navigationsWrapper {
        margin-top: 70px;
        margin-left: 40px;

        .divider {
            width: 100%;
            height: 1px;
            margin: 25px 0;
            background-color: $solitude;
        }
    }
}
