@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 20px;
    border-top: 1px solid $catskill-white;

    & > div {
        font-size: 14px;
        font-weight: 500;
        color: $fiord;
    }

    .timePeriod {
        font-weight: 700;
        width: 12%;
    }

    .tokenValue {
        width: 16%;
    }

    .totalSupply {
        width: 16%;
    }

    .circulatingSupply {
        width: 16%;
    }

    .circulatingSupplyPercentage {
        width: 20%;
    }

    .totalSupplyPercentage {
        width: 19%;
    }

    .holders {
        width: 13%;
    }
}
