@import 'src/styles/variables';
@import 'src/styles/mixins';

.tagContent {
    @include display-flex($align: center);
    @include font-style($size: 14px, $bold: 500, $color: $fiord);
    background-color: $ghostwhite;
    border-radius: 4px;
    padding: 6px 10px;
    margin-right: 10px;
    margin-top: 10px;

    @media (max-width: 767.98px) {
        font-size: 12px;
    }
}
