@import 'src/styles/variables';
@import 'src/styles/mixins';

.selectedElement {
    @include display-flex($align: center, $justify: space-between);
    background-color: $pattens-blue;
    margin-top: 14px;
    border-radius: 4px;
    padding: 2px 4px;
    overflow: hidden;

    .title {
        @include font-style($size: 14px, $bold: 700);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 10px;
    }

    .removeBtn {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        min-width: unset;
        padding: 0;
        background-color: $nepal;

        .removeIcon {
            @include font-style($size: 18px, $bold: 700, $color: $white);
        }
    }
}