@import 'src/styles/variables';
@import 'src/styles/mixins';

.description {
    @include font-style($size: 14px, $color: $fiord, $bold: 400, $lineHeight: 21px);
    margin-top: 10px;

    .link {
        color: $dodger-blue-darker;
    }
}

.form {
    margin-top: 50px;

    .userInfoFields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 90px;
        row-gap: 40px;

        .inputWrapper {
            position: relative;

            .input {
                @include font-style($size: 14px, $color: $fiord);
                width: 100%;
                height: 30px;
                border: none;
                border-bottom: 1px solid $nepal;
                background-color: transparent;
                padding-bottom: 6px;
                padding-left: 0;
                transition: border-color 0.3s;

                &:focus {
                    outline: none;
                    border-color: $dodger-blue-darker;
                }

                &::placeholder {
                    color: $nepal;
                }
            }

            .inputError {
                border-color: $tomato;

                &:focus {
                    border-color: $tomato;
                }

                &::placeholder {
                    color: $tomato;
                }
            }

            .errorText {
                @include font-style($size: 12px, $color: $tomato);
                position: absolute;
            }
        }
    }

    .textareaWrapper {
        margin-top: 40px;

        .textarea {
            @include font-style($size: 14px, $color: $fiord);
            width: 100%;
            height: 30px;
            padding-left: 0;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $nepal;
            resize: vertical;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $nepal;
            }
        }

        .textareaError {
            border-color: $tomato;

            &:focus {
                border-color: $tomato;
            }

            &::placeholder {
                color: $tomato;
            }
        }

        .errorText {
            @include font-style($size: 12px, $color: $tomato);
            position: absolute;
        }
    }

    .buttonsWrapper {
        @include display-flex($justify: space-between);
        margin-top: 77px;

        .cancelButton {
            @include font-style($size: 16px, $color: $nepal, $bold: 500, $transform: none);
            width: 90px;
            height: 44px;
            border: 2px solid $nepal;

            &:hover {
                background-color: transparent;
            }
        }

        .submitButton {
            @include font-style($size: 16px, $color: $white, $bold: 500, $transform: none);
            width: 90px;
            height: 44px;
            background-color: $dodger-blue-darker;

            &:disabled {
                background-color: $heather;
            }
        }
    }
}
