@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableContent {
    width: 100%;
    padding: 10px 16px 0;

    .tableOptions {
        @include display-flex($align: center, $justify: space-between);

        .title {
            @include font-style($size: 16px, $bold: 600, $color: $fiord);
        }
    }

    .tableWrapper {
        margin-top: 20px;
        overflow-x: scroll;

        .tableContainer {
            min-width: 1200px;
            background-color: #D7E4F4;
            border-radius: 10px;
            overflow-x: auto;
            white-space: nowrap;

            .table {
            }

            .tableData {
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
                background-color: $white;
                border: 1px solid #B0C2CD;

                margin: 15px 15px 15px 15px;
                display: grid;
                grid-template-columns: 4% 34% 62%;

                .ranks {
                    & > div:last-child {
                        border-bottom: 0;
                    }
                }

                .coalitionsWrapper {
                    width: 100%;
                    overflow-x: auto;
                    transform: rotateX(180deg);
                }

                .coalitions {
                    width: 100%;
                    transform: rotateX(180deg);
                }

                .metadata {
                    background-color: #EEF6FF;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: -6px 0 10px rgba(0, 0, 0, 0.15);

                    & > div:last-child {
                        border-bottom: 0;
                    }
                }

            }
        }
    }

    .loaderBox {
        @include display-flex($align: center, $justify: center);
        margin-top: 15px;

        .membersLoader {
            position: static;
        }
    }
}
