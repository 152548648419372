@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($direction: column);
    box-shadow: 0px 0px 30px rgba(64, 107, 179, 0.1);

    &Body {
        @include display-flex($justify: space-between, $align: flex-start);
        flex-grow: 1;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 12px 20px;
        border-radius: 6px;

        .total {
            @include font-style($size: 34px, $color: $white, $bold: 600, $lineHeight: 35px, $family: $font-koho);
            margin-right: 30px;
        }

        .totalTitle {
            @include font-style($size: 14px, $color: $white, $bold: 600, $lineHeight: 16px);
            margin-top: 8px;
        }

        .statsSection {
            @include display-flex($justify: center, $direction: column);
            height: 100%;

            & > div:first-child {
                margin-top: 0;
            }

            & > hr {
                border: 1px solid $lightblue;
                width: 100%;
                margin: 12px 0;
            }

            .statsInfo {
                @include display-flex($align: center, $direction: column);
                width: 80px;
                margin-top: 5px;

                & > .statsInfoTop {
                    @include display-flex($align: center);
                }

                .arrowIcon {
                    @include font-style($size: 20px, $color: $white);
                    margin-bottom: -2px;
                    transform: rotate(-45deg);
                }

                .increaseArrowIcon {
                    color: $turquoise-blue;
                }

                .decreaseArrowIcon {
                    color: $cornflower-lilac;
                    transform: rotate(45deg);
                }

                .aum {
                    @include font-style($size: 14px, $color: $white, $bold: 700);
                }

                .period {
                    @include font-style($size: 10px, $color: $white, $bold: 500, $lineHeight: 14px);
                    margin-left: 4px;
                }
            }
        }
    }
}
