@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.tableHeader {
    @include display-flex();
    padding: 12px 16px;

    & > div {
        @include display-flex($align: center);

        & > div {
            @include display-flex($align: center);

            & > span {
                @include table-header-font-styles();
                color: #447CF7;
            }
        }
    }
}
