@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    flex-grow: 1;
    padding: 50px;

    .pageHead {
        @include display-flex($align: flex-end, $justify: space-between);
        width: 100%;

        .apiWrapper {
            padding: 14px;
            background-color: $zircon;

            .api {
                @include font-style(
                    $size: 16px,
                    $bold: 500,
                    $lineHeight: 19px,
                    $color: $turquoise,
                    $family: $font-inconsolata
                );
            }
        }

        .getApiButton {
            width: 153px;
            height: 48px;
            background-color: $bright-turquoise;

            .buttonTitle {
                @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
            }

            .arrowIcon {
                @include font-style($size: 30px, $color: $white);
                margin-left: 10px;
                margin-right: -5px;
            }
        }
    }

    .mainContent {
        @include display-flex($align: flex-start, $direction: column);
        margin-top: 25px;
        max-width: 905px;

        .description {
            @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 21px);

            .address {
                color: $dodger-blue-darker;
            }
        }
    }
}
