@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalContainer {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 16;
    background: rgba(0, 0, 0, 0.5);

    .modal {
        @include display-flex($direction: column);
        max-width: 500px;
        padding: 20px;
        background-color: $white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;
        overflow: auto;

        &Header {
            @include display-flex($justify: flex-end);

            .closeBtn {
                min-width: unset;
                padding: 0;
                border-radius: 50%;

                .closeIcon {
                    @include font-style($size: 26px, $color: $fiord);
                }
            }
        }

        &Body {
            margin-top: 20px;

            .text {
                @include font-style($size: 20px, $color: $fiord);
            }

            .optionsButton {
                margin-top: 40px;
                @include display-flex($justify: space-around);

                & > button {
                    @include font-style($size: 16px, $bold: 500);
                    width: 120px;
                    height: 40px;
                }

                .cancelBtn {
                    color: $nepal;
                    border: 2px solid $nepal;
                }

                .confirmBtn {
                    color: $white;
                    background-color: $bright-turquoise;
                }
            }
        }
    }
}
