@import 'src/styles/variables';
@import 'src/styles/mixins';

.content {    
    overflow-x: scroll;

    .titleSection {
        @include display-flex($align: center);

        .title {
            @include font-style($size: 16px, $bold: 600, $color: $fiord);
        }

        .infoIcon {
            @include font-style($size: 22px, $color: $dodger-blue-darker);
            cursor: pointer;
            transition: color 0.2s;
            margin-left: 8px;
        }
    }

    .tableWrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: 20px;

        .tableContainer {
            background-color: $white;
            border-radius: 6px;
            min-width: 600px;
        }
    }

    .loaderBox {
        position: static;
        margin-top: 40px;
    }
}
