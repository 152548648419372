@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableContent {
    width: 100%;
    max-width: calc(1270px + 32px);
    padding: 10px 16px 0;

    .tableOptions {
        @include display-flex($align: center, $justify: space-between);

        .title {
            @include font-style($size: 16px, $bold: 600, $color: $fiord);
        }
    }
    .tableWrapper {
        margin-top: 20px;

        .tableContainer {
            background-color: $white;
            border-radius: 6px;
            box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
        }
    }

    .loaderBox {
        @include display-flex($align: center, $justify: center);
        margin-top: 15px;

        .membersLoader {
            position: static;
        }
    }
}
