@import 'src/styles/variables';
@import 'src/styles/mixins';

.scoreStatsContainer {
    @include display-flex($align: flex-end, $justify: space-between);
    color: $white;

    .mainInfo {
        .counter {
            @include font-style($size: 26px, $bold: 600, $lineHeight: 30px, $family: $font-koho !important);
        }

        .description {
            @include font-style($size: 16px, $bold: 500);

            @media (max-width: 575.98px) {
                @include font-style($size: 14px, $bold: 700);
            }
        }
    }

    .datesContainer {
        @include display-flex($justify: space-between);
        width: 140px;
        margin-left: 25px;
        margin-bottom: 1px;

        .dateInfo {
            .period {
                @include font-style($size: 13px, $bold: 700);
            }

            .date {
                @include font-style($size: 13px, $bold: 400);
                margin-top: 4px;
            }
        }

        @media (max-width: 575.98px) {
            flex-direction: column;

            & > div:last-child {
                margin-top: 14px;
            }

            .dateInfo {
                @include display-flex($justify: space-between);

                .date,
                .period {
                    font-size: 12px;
                    margin-top: 0;
                }
            }
        }
    }
}
