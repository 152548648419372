@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($justify: flex-start);
    padding: 12px 0;

    & > div {
        @include display-flex($justify: flex-start, $align: center);

        & > div {
            @include font-style($size: 14px, $bold: 600, $color: $fiord, $lineHeight: 21px);
        }
    }

    .people {
        width: 20%;

        img {
            height: 36px;
            width: 36px;
            margin-right: 9px;
            border-radius: 50%;
        }

        a {
            @include font-style($size: 14px, $bold: 600, $color: $fiord, $lineHeight: 15.5px);
            overflow-wrap: anywhere;
            padding-right: 10px;
        }
    }

    .score {
        width: 50%;
        @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 15.5px);
    }

    .following,
    .notifications,
    .remove {
        @include display-flex($justify: center);
        width: 15%;
    }

    .removeBtn {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        min-width: unset;
        background-color: $nepal;
        padding: 0;
        border: 0;
        outline: 0;
        cursor: pointer;
        @include display-flex($justify: center, $align: center);

        &:hover {
            background-color: $lavender-magenta;
        }

        &::before {
            content: '';
            display: block;
            height: 4px;
            width: 12px;
            border-radius: 1px;
            background-color: $white;
        }
    }

    @media (min-width: 576px) and (max-width: 991.98px) {
        .people {
            width: 35%;
        }

        .score {
            width: 30%;
        }

        .following {
            width: 20%;
        }
    }

    @media (max-width: 575.98px) {
        .score {
            display: none;
        }

        .people {
            width: 45%;
        }

        .following {
            width: 30%;
        }

        .notifications {
            width: 25%;
        }
    }
}

.followersTableRow {
    @media (max-width: 575.98px) {
        .people {
            width: 55%;
        }

        .following {
            width: 30%;
        }
    }
}
