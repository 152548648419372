@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgba(64, 107, 179, 0.1);
    transition: 0.3s all;

    &Header {
        padding: 16px 20px 35px;

        .title {
            @include font-style($size: 25px, $color: $fiord, $bold: 400, $family: $font-koho);
        }

        .planDescription {
            @include display-flex($align: flex-end);
            margin-top: 10px;

            .tokensCount {
                @include font-style($size: 32px, $color: $fiord, $bold: 600, $family: $font-koho);

                & > span {
                    @include font-style($size: 16px, $bold: 500, $family: $font-inter);
                }
            }

            .tokensSubscriptionPeriod {
                @include font-style($size: 14px, $color: $nepal, $bold: 700);
                margin-bottom: 4px;
                margin-left: 12px;
                position: relative;

                &::before {
                    content: '/';
                    position: absolute;
                    left: -8px;
                }
            }
        }
    }

    .monthCalls {
        background-color: $ghostwhite;
        padding: 8px 20px;

        & > p {
            @include font-style($size: 14px, $color: $fiord, $bold: 700);
        }
    }

    &Body {
        padding: 35px 20px;

        .description {
            @include font-style($size: 14px, $color: $fiord, $bold: 700, $lineHeight: 21px);
        }

        .optionsList {
            margin-top: 20px;

            .option {
                @include display-flex($align: center);
                @include font-style($size: 14px, $bold: 400, $color: $fiord);
                position: relative;
                margin-left: 14px;
                margin-top: 14px;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: $dodger-blue-darker;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    left: -14px;
                    top: 7px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .subscribeBtn {
            @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
            width: 100%;
            height: 44px;
            background-color: $dodger-blue-darker;
            margin-top: 40px;
            position: relative;
            transition: 0s all;

            .arrowIcon {
                font-size: 26px;
                position: absolute;
                right: 16px;
            }
        }
    }

    &:hover {
        background-color: $dodger-blue-darker;
        transform: translateY(12px);

        .cardHeader {
            .title {
                color: $white;
            }

            .planDescription {
                .tokensCount {
                    color: $white;
                }

                .tokensSubscriptionPeriod {
                    color: $ghostwhite;
                }
            }
        }

        .monthCalls {
            background-color: $white;

            & > p {
                color: $dodger-blue-darker;
            }
        }

        .cardBody {
            .description {
                color: $white;
            }

            .optionsList {
                .option {
                    color: $white;

                    &::before {
                        background-color: $ghostwhite;
                    }
                }
            }

            .subscribeBtn {
                background-color: $ghostwhite;
                color: $dodger-blue-darker;
            }
        }
    }
}