@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($direction: column);
    height: 290px;
    padding: 11px 14px 16px;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 6px;

    .treasuryBlock {
        @include display-flex($align: flex-end, $justify: space-between);
        min-width: 250px;
        padding: 0px 0px 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        .treasuryInfo {
            padding-right: 15px;

            .title {
                @include font-style($size: 16px, $bold: 500, $color: $aquamarine);
            }

            .treasuryValue {
                @include font-style(
                    $size: 33px,
                    $bold: 600,
                    $color: $aquamarine,
                    $lineHeight: 36px,
                    $family: $font-koho
                );
                margin-top: 4px;
            }

            .liquidVestingValues {
                @include display-flex();
                margin-top: 4px;

                & > div {
                    @include display-flex();
                    color: $aquamarine;

                    &:last-child {
                        margin-left: 12px;
                    }

                    & > span {
                        @include font-style($size: 13px, $bold: 400);
                    }

                    & > h6 {
                        @include font-style($size: 13px, $bold: 700);
                        margin-left: 4px;
                    }
                }
            }
        }

        .rankingsContainer {
            margin-bottom: 20px;

            .rankInfo {
                @include display-flex();

                & > span {
                    @include font-style($size: 11px, $bold: 700, $color: $white);
                }

                :last-child {
                    margin-left: 4px;
                }
            }

            .ranksChangesInfo {
                @include display-flex($align: center);
                margin-top: 4px;

                .rankChangesDefault {
                    @include display-flex($align: center);

                    .arrowIcon {
                        transform: rotate(45deg);
                        font-size: 14px;
                        color: $white;
                        margin-left: -3px;
                    }

                    .arrowIconPositive {
                        color: $turquoise-blue;
                    }

                    .arrowIconNegative {
                        color: $cornflower-lilac;
                        transform: rotate(135deg);
                    }

                    .rankShift {
                        @include font-style($size: 11px, $bold: 500, $color: $white);
                    }

                    .notFoundText {
                        @include font-style($size: 11px, $color: $white);
                    }
                }

                .period {
                    @include font-style($size: 10px, $bold: 400, $color: $white);
                    white-space: nowrap;
                    margin-left: 8px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -4px;
                        top: 1px;
                        width: 1px;
                        height: 10px;
                        background-color: rgba($white, 0.5);
                    }
                }
            }
        }
    }

    .statistics {
        @include display-flex($justify: space-between, $direction: column, $grow: 1);
        padding: 14px 0 0;

        .participationBlock {
            .participationInfo {
                @include display-flex($align: center, $justify: space-between);
                color: $white;

                .title {
                    @include font-style($size: 16px, $bold: 500);
                }

                .participationPercentage {
                    @include font-style($size: 14px, $bold: 600, $family: $font-koho);
                }
            }

            .progressBar {
                background: $blizzard-blue;
                border-radius: 6px;
                height: 3px;
                margin-bottom: 6px;
                margin-top: 10px;

                .participationStatus {
                    display: block;
                    background: $white;
                    height: 3px;
                    border-radius: 6px;
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 35px;
        height: 130px;
        padding: 14px 9px;

        .treasuryBlock {
            flex-direction: column;
            align-items: flex-start;
            border-bottom: none;
            min-width: auto;
            padding: 0;
            padding-right: 35px;

            .treasuryInfo {
                padding-right: 0;

                .title {
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: capitalize;
                }

                .treasuryValue {
                    font-size: 30px;
                }
            }

            .rankingsContainer {
                @include display-flex();
                margin-top: 14px;
                margin-bottom: 0;

                .rankInfo {
                    flex-direction: row-reverse;
                    margin-right: 6px;

                    & > span:first-child {
                        margin-left: 4px;
                    }

                    & > span:last-child {
                        margin-left: 0;
                    }
                }

                .ranksChangesInfo {
                    margin-top: 0;
                    border-left: 1px solid rgba($white, 0.5);
                    padding-left: 6px;

                    .period {
                        font-weight: 500;

                        &::before {
                            display: none;
                        }

                        &::after {
                            content: ',';
                            position: absolute;
                            left: -6px;
                        }
                    }
                }
            }
        }

        .statistics {
            height: 100%;
            max-width: 200px;
            padding-top: 0;

            .participationBlock {
                .participationInfo {
                    align-items: flex-start;

                    .title,
                    .participationPercentage {
                        @include font-style($size: 14px, $bold: 700);
                    }

                    .title {
                        text-transform: capitalize;
                        padding-right: 10px;
                    }
                }

                .progressBar {
                    display: none;
                }
            }
        }
    }
}
