@import 'src/styles/variables';
@import 'src/styles/mixins';

.notificationWrapperTable {
    width: 100%;
    max-width: 1270px;
    margin-top: 15px;
    padding: 20px 24px;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
}

.title {
    @include font-style($size: 14px, $color: $nepal, $bold: 700);
}

.removedMargins {
    margin-top: 0;
}

.notificationWrapperGraph {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
}

.overflowMarginsFirstVariant {
    margin-left: 16px;
    margin-right: 16px;
}

.overflowMarginsSecondVariant {
    max-width: none;

    @media (max-width: 767.98px) {
        width: auto;
        margin-left: 16px;
        margin-right: 16px;
    }
}

.widthAuto {
    width: auto;
}

.removeBoxShadow {
    box-shadow: none;
}
