@import 'src/styles/variables';
@import 'src/styles/mixins';

.statusNotFound {
    @include font-style($size: 14px, $color: $fiord);
    margin-left: 20px;
}

.removeForMobile {
    @media (max-width: 575.98px) {
        display: none;
    }
}
