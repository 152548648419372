@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    flex-grow: 1;
    width: 100%;
    margin-left: 10px;
    border-radius: 4px;
    background-color: $ghostwhite;

    textarea {
        &::placeholder {
            color: $nepal;
        }
    }
}
