@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($align: center, $justify: space-between);
    margin-top: 60px;

    .notificationText {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
        position: relative;
        padding-left: 12px;

        &::before {
            @include font-style($size: 14px, $color: $dodger-blue-darker, $bold: 700);
            content: "*";
            position: absolute;
            top: -1px;
            left: 0;
        }
    }

    .nextStepButton {
        width: 170px;
        height: 44px;
        border: 2px solid $bright-turquoise;
        position: relative;

        .title {
            @include font-style($size: 16px, $bold: 500, $color: $bright-turquoise, $transform: none);
        }

        .arrowIcon {
            @include font-style($size: 26px, $color: $bright-turquoise);
            position: absolute;
            right: 8px;
        }

        &:disabled {
            background-color: $ghostwhite;
            border: none;

            .title,.arrowIcon {
                color: $heather
            }
        }

        &:hover {
            background-color: $white;
        }
    }
}
