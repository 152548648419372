@import 'src/styles/mixins';

.container {
    @include display-flex($align: center, $direction: column);
    width: 100%;
    padding: 10px 0 60px;

    .tabsWrapper {
        align-self: flex-start;
        padding: 10px 0 0 24px;
    }

    .gridContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        padding: 24px 16px 0 16px;

        @media (max-width: 991.98px) {
            grid-template-columns: 1fr;
            padding-left: 16px;
            padding-right: 16px;
        }

        .gridItem {
            display: block;
            flex-direction: column;
            overflow: hidden;
            background-color: #D7E4F4;
            border-radius: 16px;
        }
    }

    .loaderBox {
        @include display-flex($align: center, $justify: center);
        margin-top: 15px;

        .membersLoader {
            position: static;
        }
    }
}
