@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    width: 100%;
    & > div {
        & > div {
            @include display-flex();
            @include font-style($size: 14px, $bold: 700, $color: $fiord);
        }
    }

    .people {
        width: 20%;
    }

    .score {
        width: 50%;
    }

    .following,
    .notifications,
    .remove {
        @include display-flex($justify: center, $align: center);
        width: 15%;
    }

    @media (min-width: 576px) and (max-width: 991.98px) {
        .people {
            width: 35%;
        }

        .score {
            width: 30%;
        }

        .following {
            width: 20%;
        }
    }

    @media (max-width: 575.98px) {
        .score {
            display: none;
        }

        .people {
            width: 45%;
        }

        .following {
            width: 30%;
        }

        .notifications {
            width: 25%;
        }
    }
}

.followersTableHeader {
    @media (max-width: 575.98px) {
        .people {
            width: 55%;
        }

        .following {
            width: 30%;
        }
    }
}


