@import 'src/styles/variables';
@import 'src/styles/mixins';

.userTokensContainer {
    margin-top: 30px;
    background: $white;
    box-shadow: 0 0 30px rgba(64, 107, 179, 0.1);
    border-radius: 6px;

    .textContainer {
        padding: 20px 20px 14px 20px;

        .title {
            @include font-style($size: 15px, $bold: 600, $color: $fiord);
            margin: 0;
        }

        .subTitle {
            @include font-style($size: 14px, $bold: 400, $color: $regent-gray);
            margin: 0;
            margin-top: 4px;
        }

        @media (max-width: 575.98px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
