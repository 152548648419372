@import 'src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, #337bff 34.12%, #1db3d2);
  font-family: $font-koho !important;
  color: white;

  .header {
    font-size: 60px;
    text-align: center;
  }

  .btn {
    margin-top: 20px;
    background-color: #0fdab5;
    font-size: 24px;
    color: white;

    &:hover {
      background-color: darken(#0fdab5, 10%);
    }
  }
}
