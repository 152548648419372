@import 'src/styles/variables';
@import 'src/styles/mixins';

.votingSection {
    @include display-flex($justify: space-between);
    width: 100%;
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid $pattens-blue;
    position: relative;

    .notificationTooltip {
        width: 100%;
        bottom: 50px;

        & > div {
            margin: 0 auto;
        }
    }

    .commentsBlock {
        @include display-flex($align: center);
        height: 38px;
        padding: 10px;
        margin-right: 16px;
        border: 1px solid $periwinkle-lighter;
        border-radius: 20px;

        .messageIcon {
            @include font-style($size: 24px, $color: $periwinkle-lighter);
        }

        .commentsCount {
            @include font-style($size: 12px, $color: $fiord, $bold: 400);
            margin-left: 4px;
            margin-bottom: 4px;
        }
    }

    .buttonsWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 14px;

        @media (max-width: 575.98px) {
            column-gap: 0;

            & > button {
                &:first-child {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 20px;
                    border-top-left-radius: 20px;
                    border-right: none;
                }

                &:last-child {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    border-bottom-right-radius: 20px;
                    border-top-right-radius: 20px;
                }
            }
        }
    }
}
