@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    background-color: $white;
    border-bottom: 1px solid $mystic;

    .tabsContainer {
        @include display-flex();
        @include max-device-width();
        height: 50px;
        padding: 0 85px;
        overflow-x: auto;
        overflow-y: hidden;

        .tabItem {
            @include display-flex($align: center, $justify: flex-start);
            text-align: center;
            width: 50%;
            min-width: 95px;
            cursor: pointer;
            position: relative;
            transition: 0.5s all;
            border-right: 1px solid $mystic;

            &:hover {
                & > div {
                    & > span {
                        color: $bright-turquoise;
                    }
                }
            }

            &:first-child {
                border-left: 1px solid $mystic;
            }
        }

        .activeTab {
            &::before {
                content: '';
                position: absolute;
                bottom: -1px;
                width: 100%;
                height: 3px;
                background-color: $bright-turquoise;
            }

            & > div {
                & > span {
                    color: $bright-turquoise;
                }
            }
        }

        .singleTab {
            justify-content: center;
            width: 100%;

            .itemContent {
                width: unset;
            }
        }

        .itemContent {
            @include display-flex($justify: center);
            width: 100%;
            padding: 16px;

            & > span {
                @include font-style($size: 16px, $bold: 700, $color: $fiord);
                transition: 0.3s color;

                @media (max-width: 767.98px) {
                    @include font-style($size: 14px, $transform: capitalize);
                }
            }

            @media (max-width: 767.98px) {
                justify-content: center;
            }

            .beta {
                @include display-flex($align: center, $justify: center);
                padding: 2px 10px;
                background: linear-gradient(127.03deg, #347cff 2.49%, #3d8dd8 28.11%, #1ac1e6 59.33%, #0fdab5 82.55%);
                border-radius: 12px;
                margin-left: 8px;

                .text {
                    @include font-style($size: 10px, $bold: 700, $color: $white, $transform: uppercase);
                }
            }
        }

        .item:last-child {
            border-right: 1px solid $mystic;
        }

        @media (max-width: 991.98px) {
            padding: 0;
        }

        @media (max-width: 767.98px) {
            height: 44px;
        }
    }
}
