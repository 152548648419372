@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    padding: 0 25px;

    @media (max-width: 767.98px) {
        padding-left: 0;
        padding-right: 0;

        & > div {
            border-bottom: none;
            padding-bottom: 45px;

            .similarPeopleContainer {
                height: 154px !important;

                & > div {
                    & > a {
                        &:first-child {
                            margin-left: 16px;
                        }

                        &:last-child {
                            margin-right: 16px;
                        }
                    }
                }
            }
        }
    }
}

.emptyData {
    display: none;
}
