@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;

    &Header {
        @include display-flex($justify: space-between, $align: flex-start);
        padding: 0 20px;

        .userInfo {
            @include display-flex($align: center);
            padding-top: 20px;

            .imageLinkWrapper {
                width: 48px;
                height: 48px;

                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                }

                @media (max-width: 575.98px) {
                    width: 36px;
                    height: 36px;

                    .avatar {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .userDetails {
                margin-left: 8px;
                margin-right: 16px;

                .options {
                    @include display-flex($align: center);

                    .brainstormLink {
                        width: 17px;
                        height: 17px;
                        margin-right: 6px;

                        .linkIcon {
                            @include font-style($size: 17px, $color: $regent-gray);
                            transition: 0.2s color;
                        }

                        &:hover {
                            .linkIcon {
                                color: $dodger-blue-darker;
                            }
                        }
                    }

                    .date {
                        @include font-style($size: 10px, $color: $regent-gray, $bold: 700);
                    }

                    @media (max-width: 575.98px) {
                        margin-bottom: 0;
                    }
                }

                .user {
                    @include font-style($size: 14px, $color: $fiord, $bold: 700);
                    transition: 0.3s color;
                    overflow-wrap: anywhere;

                    &:hover {
                        color: $dodger-blue-darker;
                    }
                }
            }

            @media (max-width: 575.98px) {
                align-items: flex-start;
            }
        }

        .info {
            @include display-flex($align: flex-end);

            .elementBlocker {
                margin-left: 0;
                margin-bottom: 0;
                margin-top: 30px;
            }
        }

        @media (max-width: 575.98px) {
            padding: 0 10px;
        }
    }

    &Body {
        padding: 20px;

        .textInfo {
            @include display-flex($align: flex-start, $direction: column);

            .title {
                @include font-style($size: 17px, $color: $fiord, $bold: 700, $lineHeight: 21px);
                overflow-wrap: anywhere;
                word-break: break-word;
            }

            @media (max-width: 575.98px) {
                .title {
                    font-size: 14px;
                }
            }
        }

        .mainInfo {
            @include display-flex($align: center, $justify: space-between);
            padding: 16px 0 0;

            .tokensInfo {
                @include display-flex($align: center);

                & > div {
                    &:last-child {
                        padding-left: 50px;
                    }
                }

                @media (max-width: 575.98px) {
                    width: 100%;
                    justify-content: space-between;

                    & > div {
                        &:last-child {
                            border-left: none;
                            padding-left: 5px;
                        }

                        &:first-child {
                            padding-right: 5px;
                        }
                    }
                }
            }

            .commentsInfo {
                @include display-flex($align: center);

                .commentsCount {
                    @include font-style($size: 10px, $color: $fiord, $bold: 700);
                }
            }
        }

        @media (max-width: 575.98px) {
            padding: 20px 10px 10px;
        }
    }
}
