@import 'src/styles/variables';
@import 'src/styles/mixins';

.reply {
    margin-top: 30px;

    &:first-child {
        margin-top: 16px;
    }

    &Details {
        @include display-flex();

        .link {
            width: 36px;
            height: 36px;

            .replierLogo {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }

        .replyTextBlock {
            @include display-flex($justify: space-between, $align: flex-start, $direction: column);
            flex-grow: 1;
            background-color: $ghostwhite;
            border-radius: 4px;
            margin-left: 10px;
            padding: 8px 10px;
            position: relative;

            &Header {
                @include display-flex($justify: space-between, $align: flex-end);
                width: 100%;

                .replier {
                    @include font-style($size: 12px, $color: $fiord, $bold: 700);
                    transition: 0.3s color;

                    &:hover {
                        color: $dodger-blue-darker;
                    }
                }

                .date {
                    @include font-style($size: 10px, $color: $regent-gray, $bold: 500);
                }
            }

            .text {
                @include font-style($size: 14px, $color: $fiord, $bold: 400, $lineHeight: 21px);
                white-space: pre-wrap;
                overflow-wrap: anywhere;
                word-break: break-word;
                margin-top: 4px;
                margin-right: 20px;

                a {
                    color: $dodger-blue-darker;
                    font-weight: 600;
                }
            }
        }
    }

    .info {
        @include display-flex($justify: flex-end);
        margin-top: 2px;

        & > span {
            @include font-style($size: 10px, $color: $regent-gray, $bold: 500);
        }
    }
}
