@import 'src/styles/variables';
@import 'src/styles/mixins';

.bannerWrapper {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($white, 0.9);

    .bannerContainer {
        @include display-flex($align: center, $justify: center, $direction: column);
        padding: 40px;

        .logoCircle {
            width: 77px;
            height: 77px;
            border-radius: 50%;
        }

        .title {
            @include font-style(
                $size: 32px,
                $bold: 600,
                $color: $dodger-blue-darker,
                $align: center,
                $family: $font-koho
            );
            margin-top: 20px;
        }

        .description {
            @include font-style(
                $size: 24px,
                $bold: 400,
                $color: $dodger-blue-darker,
                $family: $font-koho,
                $align: center
            );
            margin-top: 10px;
        }

        .bulletPointsWrapper {
            @include display-flex($wrap: wrap, $justify: space-around);

            .bulletPoint {
                @include display-flex($align: center, $justify: center);
                background-color: $dodger-blue-darker;
                width: 160px;
                padding: 32px 24px;
                border-radius: 16px;
                margin: 24px 12px 0;

                & > span {
                    @include font-style($size: 16px, $bold: 600, $color: $white, $family: $font-koho, $align: center);
                }
            }
        }

        .loginButton {
            @include font-style($size: 16px, $bold: 700, $color: $white, $transform: none);
            width: 235px;
            height: 60px;
            border-radius: 8px;
            background-color: $bright-turquoise;
            border: none;
            cursor: pointer;
            margin: 0 10px;
        }

        .buttonsContainer {
            margin-top: 20px;
        }
    }
}

.mediumBannerWrapper {
    .bannerContainer {
        .logoCircle {
            width: 44px;
            height: 44px;
        }

        .title {
            font-size: 24px;
            margin-top: 14px;
        }

        .description {
            font-size: 16px;
        }

        .bulletPointsWrapper {
            .bulletPoint {
                margin: 14px 12px 0;
            }
        }

        .buttonsContainer {
            margin-top: 14px;
        }

        .loginButton {
            width: 180px;
        }
    }
}
