@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: center);
    @include max-device-width();
    width: 100%;
    padding: 30px 16px 60px;

    .container {
        width: 100%;
        max-width: 740px;

        .options {
            @include display-flex($align: center, $justify: space-between);
            padding: 0 10px 10px;

            .brainstormLink {
                @include display-flex($align: center);
                margin-left: -4px;

                .arrowIcon {
                    @include font-style($size: 28px, $color: $nepal);
                    transform: rotate(180deg);
                    transition: 0.2s color;
                }

                &:hover {
                    .arrowIcon {
                        color: $dodger-blue-darker;
                    }
                }
            }

            .copy {
                @include display-flex($justify: space-between);
                cursor: pointer;

                .linkIcon {
                    @include font-style($size: 24px, $color: $regent-gray);
                    transition: 0.2s color;
                }

                &:hover {
                    .linkIcon {
                        color: $dodger-blue-darker;
                    }
                }
            }

            .title {
                @include font-style($size: 16px, $color: $fiord, $bold: 500);
                margin-left: 8px;
            }
        }
    }
}
