@import 'src/styles/mixins';
@import 'src/styles/variables';

.badges {
    &Container {
        @include display-flex($justify: space-between);
        position: relative;
        padding: 0 10px;
        margin-bottom: 25px;

        .badgesItem {
            @include font-style($align: center);
            margin-bottom: 10px;

            &Title {
                @include font-style($size: 14px, $bold: 600, $color: $nepal);
            }
        }

        .comingSoon {
            @include font-style($size: 10px, $bold: 500, $color: $white, $align: center);
            background-color: $java;
            padding: 4px 10px;
            border-radius: 0px 12px;
            position: absolute;
            left: 50%;
            top: calc(50% - 15px);
            transform: translate(-50%, -50%);
        }
    }
}
