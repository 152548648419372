@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    display: flex;
    align-items: center;
    width: 200px;
    min-width: 200px;
    height: 78px;
    padding: 5px 10px;
    border-top: 1px solid $catskill-white;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    .mainInfo {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;

        .organizationName {
            font-size: 14px;
            font-weight: 700;
            color: $fiord;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: 0.3s color;

            &:hover {
                color: $dodger-blue-darker;
            }
        }

        .description {
            @include font-style($size: 10px, $bold: 700, $color: $nepal);
            margin-top: 2px;

            @media (max-width: 767.98px) {
                display: none;
            }
        }

        .stats {
            @include display-flex($justify: space-between);
            width: 100%;
            margin-top: 2px;

            & > div {
                & > h6 {
                    @include font-style($size: 10px, $bold: 500, $color: $nepal);
                }

                &:last-child {
                    & > h6 {
                        text-align: right;
                    }
                }
            }

            @media (max-width: 767.98px) {
                display: none;
            }
        }
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        width: 105px;
        min-width: 105px;
        height: 88px;
        padding: 8px;

        .mainInfo {
            align-items: center;
            margin-top: 6px;
            margin-left: 0;

            .organizationName {
                font-size: 12px;
                max-width: 90px;
            }
        }
    }
}
