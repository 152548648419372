@import 'src/styles/mixins';

.sliderWrapper {
    @include display-flex();
    width: 100%;
    overflow-x: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);

    & > div > div:first-child {
        margin-left: 40px !important;
    }
}
