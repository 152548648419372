@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($align: center);
    background-color: $nepal;
    padding: 4px 12px;
    border-radius: 20px;
    margin: 20px 10px 0 0;
    max-width: 150px;
    overflow: hidden;

    .closeIcon {
        @include font-style($size: 18px, $color: $white);
        margin-left: -4px;
    }

    .text {
        @include font-style($size: 12px, $color: $white, $bold: 500);
        margin-left: 4px;
        margin-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
