@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex();
    padding: 12px;
    border: 1px solid $botticelli-lighter;
    border-top: none;

    & > div {
        & > span {
            @include font-style($size: 14px, $bold: 600, $color: $fiord, $lineHeight: 21px);
        }
    }

    .parameters {
        width: 30%;
    }

    .comments {
        width: 70%;
    }
}
