@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalContainer {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 15;
    background: rgba($fiord, 0.75);

    .modal {
        @include display-flex($direction: column);
        width: 100%;
        max-width: 890px;
        max-height: calc(100vh - 100px);
        position: relative;
        background-color: $white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;

        &Header {
            background: linear-gradient(180.71deg, rgba(51, 123, 255, 0.2) 22.77%, rgba(103, 183, 244, 0.2) 61.88%, rgba(255, 255, 255, 0.2) 99.39%);
            padding: 40px 20px 20px;

            .titleWithCloseBtn {
                @include display-flex($justify: center);
                position: relative;
                
                .closeButton {
                    width: 32px;
                    height: 32px;
                    min-width: unset;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: -20px;
    
                    .closeIcon {
                        font-size: 36px;
                        color: $fiord;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }

                .title {
                    @include font-style($size: 28px, $bold: 600, $color: $fiord, $family: $font-koho);
                }
            }
        }

        &Body {
            padding: 40px 80px;
            overflow-y: scroll;
        }
    }
}
