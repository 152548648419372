.wrapper {
    width: 100%;
    margin-top: 30px;

    header {
        padding-bottom: 12px;
        border-bottom: 1px solid #cfe1ff;

        .header {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            color: #3d536c;
        }
    }

    .toolsWrapper {
        .loaderBox {
            margin-top: 20px;

            .loader {
                position: relative;
            }
        }

        .carouselWrapper {
            & > button {
                display: none;
            }

            padding-bottom: 0;

            & > h4 {
                font-size: 18px;
                margin-left: 0;
            }
        }

        .carouselContainerClassName {
            height: 88px;

            & > div:last-child {
                margin-top: 0;

                & > div {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
