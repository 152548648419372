@import 'src/styles/mixins';
@import 'src/styles/variables';

.loader {
    @include display-flex($align: center, $justify: center);
    position: fixed;
    flex: 1;
    left: 0;
    right: 0;
    bottom: 50%;
    z-index: 1000;

    .spinner {
        display: block;
        position: relative;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $dodger-blue-darker;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;

        &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $java-lighter;
            -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
        }

        &::after {
            content: '';
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $bright-turquoise;
            -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
        }

        @keyframes spin {
            0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }
}
