@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    .premiumWindow {
        padding: 180px;
        position: relative;
    }

    .tablesWrapper {
        .topDelegates {
            padding: 30px 10px;
            position: relative;
        }

        .activeDelegates {
            padding: 0 10px 30px 10px;
            position: relative;
        }

    }

}
