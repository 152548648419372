@import 'src/styles/variables';
@import 'src/styles/mixins';

.detailedDescriptionSection {
    @include display-flex($align: center);
    margin-bottom: 8px;

    .title {
        @include font-style($size: 14px, $bold: 700, $color: $fiord);
    }

    .infoIcon {
        @include font-style($size: 20px, $color: $nepal);
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;

        &:hover {
            color: $dodger-blue-darker;
        }
    }

    .description {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
        margin-left: 10px;
    }
}