@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($align: flex-start);
    width: 100%;
    max-width: 880px;
    background: $white;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
    padding: 25px 0 50px;

    .textContainer {
        padding: 0 80px 0 30px;

        .title {
            @include font-style($size: 17px, $bold: 700, $color: $fiord, $lineHeight: 25px);
            margin-top: -4px;
            white-space: pre-wrap;
            overflow-wrap: anywhere;
        }

        .description {
            @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 21px);
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            margin-top: 30px;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            padding: 0 25px 0 10px;
        }

        @media (max-width: 767.98px) {
            padding: 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 50px 20px;
        border-radius: 0px;

        & > div:first-child {
            display: none;
        }
    }
}
