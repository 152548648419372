@import 'src/styles/variables';
@import 'src/styles/mixins';

.tooltipContent {
    & > div {
        @include display-flex($justify: space-between);

        & > span {
            @include font-style($size: 14px, $bold: 400, $color: $fiord);
        }

        &:last-child {
            margin-top: 8px;
        }
    }
}
