@import 'src/styles/variables';
@import 'src/styles/mixins';

.searchContainer {
    @include display-flex($align: center);

    .searchButton {
        min-width: unset;
        border-radius: 50%;

        .title {
            @include font-style($size: 14px, $color: $fiord, $transform: none, $bold: 400, $lineHeight: 18px);
            margin-left: 10px;
        }

        &:hover {
            background-color: transparent;
        }

        .searchIcon {
            color: $fiord;
        }

        .searchIconTable {
            color: $nepal;
        }
    }

    .inputContainer {
        @include display-flex();
        position: relative;

        .input {
            @include font-style($size: 14px, $color: $fiord);
            border: none;
            border-bottom: 2px solid $fiord;
            background-color: transparent;
            caret-color: $fiord;
            padding: 0;
            padding-bottom: 5px;
            width: 0;
            transition: 0.5s all;

            &:focus {
                outline: none;
            }
        }

        .inputTable {
            border-color: $fiord;
            caret-color: $nepal;
        }

        .inputOpen {
            width: 250px;
            padding: 0 20px 5px 5px;

            @media (max-width: 575.98px) {
                width: 150px;
            }

            @media (min-width: 576px) and (max-width: 1199.98px) {
                width: 200px;
            }
        }

        .clearFieldButton {
            display: none;
            width: 15px;
            height: 15px;
            min-width: unset;
            padding: 0;
            position: absolute;
            right: 0;
            bottom: 7px;

            &:hover {
                background-color: transparent;
            }

            .clearFieldIcon {
                @include font-style($size: 18px, $color: $fiord);
            }

            .clearFieldIconTable {
                color: $fiord;
            }
        }

        .clearButtonActive {
            display: block;
        }
    }
}

.searchContainerTable {
    margin-top: -2px;

    .searchButton {
        &:hover {
            background-color: transparent;
        }

        .searchIcon {
            color: $nepal;
        }
    }

    .inputContainer {
        .input {
            border-color: $fiord;
            caret-color: $nepal;
        }

        .clearFieldButton {
            .clearFieldIcon {
                color: $fiord;
            }
        }
    }
}
