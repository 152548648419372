@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    .titleWrapper {
        padding-bottom: 16px;
        @include display-flex($align: center);

        .infoIcon {
            margin: 0 8px;
        }

        .tableTitle {
            @include font-style($size: 18px, $bold: 700, $color: #1F3B82);
        }
    }

    .dataContainer {
        overflow-x: scroll;
        overflow-y: hidden;

        .tableContainer {
            min-width: 600px;

            .rowsWrapper {
                background-color: $white;
                padding-right: 15px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

                .rowsContainer {
                    max-height: 570px;
                    overflow-y: auto;

                    & > div {
                        border-bottom: 1px solid $catskill-white;

                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 3px;
                        background-color: $catskill-white;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $nepal;
                    }
                }
            }

            @media (max-width: 991.98px) {
                width: auto;
            }

            @media (max-width: 767.98px) {
                min-width: 600px;
            }
        }
    }
}

.loaderBox {
    @include display-flex($align: center, $justify: center);
    height: 98px;
    margin-top: 8px;

    .similarUsersLoader {
        position: static;
    }
}