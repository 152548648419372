.mentions {
    margin: 10px 0;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
}

.mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
    padding: 1px;
    border: 2px inset;
}

.mentions--multiLine .mentions__control {
    font-size: 14px;
}

.mentions--multiLine .mentions__highlighter {
    padding: 0 9px;
    border: 0;
    font-size: 14px;
}

.mentions--multiLine .mentions__input {
    padding: 0 9px;
    outline: 0;
    border: 0;
    font-size: 14px;
    color: #3d536c;
}

.mentions__suggestions__list {
    background-color: #ffffff;
    border: 1px solid #337bff;
    font-size: 10px;
    max-height: 150px;
    overflow-y: scroll;
}

.mentions__suggestions {
    background-color: transparent !important;
}

.mentions__suggestions__item {
    padding: 5px 15px;
}

.mentions__suggestions__item--focused {
    background-color: #f1f6ff;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: #337bff;
    pointer-events: none;
}
