@import 'src/styles/variables';
@import 'src/styles/mixins';

.fieldsWrapper {
    @include display-flex();
    height: 40px;
    position: relative;

    .searchButton {
        padding: 0;
        min-width: unset;
        position: absolute;
        left: 15px;
        top: 13px;

        .searchIcon {
            width: 20px;
            height: 20px;

            @media (min-width: 768px) and (max-width: 991.8px) {
                width: 25px;
                height: 25px;
                margin-top: -2px;
            }
        }

        .closeArrowFiord {
            width: 20px;
            height: 20px;
        }

        @media (max-width: 991.98px) {
            padding: 6px 8px;
            position: static;
        }
    }

    .closeButton {
        margin-right: 10px;
    }

    .inputContainer {
        width: 340px;
        position: relative;

        .searchInput {
            @include font-style($size: 20px, $color: $fiord);
            width: 100%;
            height: 100%;
            padding: 0 35px 0 10px;
            background: $aliceblue;
            border: 2px solid $nepal;
            border-left-width: 0;
            caret-color: $nepal;
            border-radius: 0 20px 20px 0;

            &:focus {
                outline: none;
            }

            &::placeholder {
                @include font-style($size: 14px, $color: $nepal);
            }

            @media (max-width: 991.98px) {
                display: none;
                padding-left: 15px;
            }
        }

        .searchInputActive {
            display: block;
            width: 100%;
        }

        .clearFieldButton {
            position: absolute;
            right: 10px;
            bottom: 10px;
            min-width: unset;
            width: 20px;
            height: 20px;
            padding: 0px;
            border: 0;
            border-radius: 50%;

            &:hover {
                background-color: transparent;
            }

            .clearFieldIcon {
                @include font-style($size: 24px, $color: $nepal);
            }

            @media (max-width: 767.98px) {
                bottom: 7px;
            }
        }

        .autocompleteWrapper {
            width: 200px;
            position: absolute;
            top: 55px;
            left: 0px;
            border: 2px solid $malibu-lighter;
            box-shadow: 0px 4px 10px rgba(51, 123, 255, 0.2);
            border-radius: 4px;
            z-index: 4;
            background-color: $white;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 40px;
                bottom: 100%;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 9px solid $malibu-lighter;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 43px;
                bottom: 100%;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 6px solid $white;
            }

            .autocompleteContainer {
                max-height: 385px;
                padding: 10px 0;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $darkslategray;
                }

                .divider {
                    height: 1px;
                    background-color: $snuff;
                    margin: 2px 12px 15px 20px;
                }
            }

            @media (max-width: 991.98px) {
                box-shadow: 0px 4px 10px rgba(51, 123, 255, 0.4);
            }

            @media (max-width: 767.98px) {
                top: 58px;
            }

            @media (max-width: 575.98px) {
                width: 100%;
            }
        }

        .autocompleteEmpty {
            display: none;
        }

        .notFoundText {
            @include font-style($size: 10px, $bold: 500, $color: $lavender-magenta);
            position: absolute;
            bottom: -10px;
            left: 40px;
        }

        @media (max-width: 1199.98px) {
            width: 100%;
        }

        @media (max-width: 991.98px) {
            align-items: center;
            margin-top: 0;
        }

        @media (max-width: 767.98px) {
            margin-right: 0;
        }
    }

    @media (max-width: 1199.98px) {
        margin-right: 0;
    }
}

.inputContainerFullWidth {
    width: 100%;
    margin-top: 0;
}
