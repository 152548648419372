@import 'src/styles/variables';
@import 'src/styles/mixins';

.legend {
    padding: 20px;

    .barColoringInfo {
        @include display-flex($justify: center);

        span {
            @include font-style($size: 11px, $bold: 700, $color: $nepal);
            position: relative;
        }

        .firstColoring {
            &::before {
                content: '';
                position: absolute;
                left: -15px;
                top: 2px;
                width: 10px;
                height: 10px;
                background: #FFA500;
            }
        }

        .secondColoring {
            margin-left: 30px;

            &::before {
                content: '';
                position: absolute;
                left: -15px;
                top: 2px;
                width: 10px;
                height: 10px;
                background: #337BFF;
            }
        }
    }
}
