@import 'src/styles/variables';
@import 'src/styles/mixins';

.optionsWrapper {
    width: 110px;
    padding: 10px;
    position: absolute;
    top: 55px;
    left: 10px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(64, 107, 179, 0.15);
    border: 2px solid $dodger-blue-darker;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 9px solid $dodger-blue-darker;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 52px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid $white;
    }

    .link {
        @include font-style($size: 13px, $bold: 500, $color: $fiord);
        margin-bottom: 10px;
        transition: 0.3s color;

        &:hover {
            color: $dodger-blue-darker;
        }
    }

    .premiumUserOptions {
        @include display-flex($direction: column);

        .logoutOptionWrapper {
            @include display-flex($align: center);
            padding-top: 10px;
            border-top: 1px solid $nepal;
            cursor: pointer;

            .logoutIcon {
                @include font-style($size: 20px, $color: $fiord);
                transition: color 0.3s;
                margin-left: -2px;
            }

            .logoutText {
                @include font-style($size: 13px, $bold: 500, $color: $fiord);
                transition: color 0.3s;
                margin-left: 4px;
            }

            &:hover {
                .logoutText,
                .logoutIcon {
                    color: $dodger-blue-darker;
                }
            }

            @media (max-width: 767.98px) {
                border-top: 1px solid $heather;
                padding-top: 26px;
                margin-top: 16px;

                .logoutText {
                    font-size: 16px;
                }
            }
        }
    }

    .headingText {
        @include font-style($size: 13px, $bold: 400, $color: $fiord);
    }

    .premiumLoginButtonWrapper {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid $nepal;

        .premiumLoginButton {
            @include font-style($size: 11px, $bold: 500, $color: $dodger-blue-darker);
            width: 100%;
            background-color: transparent;
            height: 24px;
            border: 1px solid $dodger-blue-darker;
            border-radius: 2px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}
