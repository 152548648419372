@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsContainer {
    @include display-flex($align: flex-end, $justify: space-between);
    width: 100%;

    .tabsWrapper {
        @include display-flex();
        font-size: 14px;

        .mainTitle {
            @include font-style($bold: 600, $color: $fiord);
            margin-right: 30px;
        }

        .titleMarginBottom {
            @media (min-width: 768px) and (max-width: 991.98px) {
                margin-bottom: 10px;
            }
        }

        .tabsItems {
            @include display-flex($wrap: wrap);

            .tab {
                @include font-style($bold: 600, $color: $nepal);
                white-space: nowrap;
                margin-right: 28px;
                padding-bottom: 5px;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                transition: 0.1s linear all;

                @media (max-width: 991.98px) {
                    margin-top: 10px;
                }
            }

            .activeTab {
                color: $fiord;
                border-bottom: 2px solid $fiord;
            }

            .infoIcon {
            }

            @media (max-width: 991.98px) {
                @include display-flex($align: flex-start);
                margin-top: 10px;
            }
        }

        @media (max-width: 991.98px) {
            flex-direction: column;
        }
    }

    @media (max-width: 767.98px) {
        .tabsWrapper {
            width: 100%;

            .mainTitle {
                font-size: 16px;
                margin-right: 0;
                margin-bottom: 8px;
            }

            .tabsItems {
                margin-top: 0;
                padding-top: 6px;
                border-top: 1px solid $mystic;
                overflow-x: scroll;
                flex-wrap: nowrap;

                .tab {
                    margin-right: 20px;
                    padding-bottom: 10px;
                    border-width: 3px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
