@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;

    .container {
        padding: 60px 20px;

        .accountSettingsLink {
            @include display-flex($align: center);
            color: $fiord;
            transition: 0.3s color;

            .arrowIcon {
                @include font-style($size: 14px);
            }

            .text {
                @include font-style($size: 14px, $bold: 400);
            }

            &:hover {
                color: $dodger-blue-darker;
            }
        }

        .title {
            @include font-style($size: 40px, $bold: 500, $color: $fiord, $family: $font-koho);
            margin-top: 20px;
        }
    }
}
