@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalContainer {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0.5);

    .modal {
        @include display-flex($direction: column);
        width: 710px;
        min-height: 600px;
        max-height: calc(100vh - 30px);
        background-color: $white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;
        overflow: auto;

        &Header {
            @include display-flex($align: flex-start, $justify: space-between);
            background-color: $white;
            padding: 20px 0 10px;
            position: sticky;
            top: 0;
            z-index: 1;

            .titleSection {
                @include display-flex($align: center, $justify: center);

                .iconStyles {
                    background-color: $heliotrope-lighter;

                    &::before {
                        border-right-color: $indigo;
                    }
                }

                .title {
                    @include font-style($size: 26px, $bold: 400, $color: $bright-turquoise, $family: $font-quicksand);
                    margin-left: 24px;

                    @media (max-width: 575.98px) {
                        font-size: 20px;
                    }
                }
            }

            .closeButton {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 20px;
                min-width: unset;

                svg {
                    font-size: 25px;
                    color: $bright-turquoise;
                }
            }
        }

        &Body {
            padding: 30px;

            .select {
                @include display-flex($justify: space-between, $align: center);
                width: 200px;
                height: 26px;
                border-bottom: 2px solid $fiord;
                transition: 0.3s all;
                cursor: pointer;

                &Icon {
                    font-size: 14px;
                    color: $fiord;
                    margin-right: 6px;
                    font-size: 26px;
                    color: $heliotrope;
                    margin-right: -5px;
                }

                &IconActive {
                    color: $blue-gem;
                }

                &Title {
                    @include font-style($size: 13px, $bold: 400, $color: $nepal);
                }
            }

            .section {
                margin-bottom: 50px;

                &Title {
                    @include font-style(17px, $bold: 700, $color: $fiord);
                    margin-bottom: 10px;
                }

                &Subtitle {
                    @include font-style(14px, $bold: 400, $color: $nepal);
                }

                .textarea {
                    @include font-style(14px, $bold: 400, $color: $fiord);
                    padding: 8px 0;
                    outline: none;
                    border: none;
                    border-bottom: 1px solid $nepal;
                    min-width: 100%;
                    max-width: 100%;

                    &::placeholder {
                        color: $nepal;
                    }
                }
            }

            .formOptionsContainer {
                @include display-flex($justify: space-between);

                .uploaderContainer {
                    position: static;

                    & > label {
                        @include display-flex($justify: space-between);
                        background-color: $white-ice;
                        width: 160px;
                        height: 44px;
                        border-radius: 4px;
                        padding: 10px;
                        box-shadow: none;

                        svg {
                            color: $bright-turquoise;
                            margin-left: -4px;
                            margin-top: 4px;
                        }
                    }
                }

                .buttonsContainer {
                    .cancelButton {
                        @include font-style($size: 16px, $bold: 500, $color: $nepal, $transform: none);
                        width: 86px;
                        height: 44px;
                        padding: 10px;
                        background-color: transparent;
                        border: 2px solid $nepal;
                    }

                    .saveButton {
                        @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
                        width: 200px;
                        height: 44px;
                        padding: 10px;
                        margin-left: 20px;
                        background-color: $bright-turquoise;
                        filter: drop-shadow(0px 4px 4px rgba(6, 102, 246, 0.2));

                        &:disabled {
                            background-color: $silver-sand;
                        }
                    }
                }
            }
        }

        .maxWordsLength {
            font-size: 10px;
            font-weight: 500;
            color: $heather;
        }

        @media (max-width: 767.98px) {
            width: 100%;
            margin: 0 20px;

            &Body {
                padding: 10px;
            }
        }
    }
}
