@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    margin-left: 8px;
    margin-bottom: 8px;
    position: relative;

    .button {
        min-width: unset;
        height: 18px;
        padding: 0;

        .moreIcon {
            @include font-style($size: 26px, $color: $nepal);
            transition: color 0.2s;
        }

        &:hover {
            background-color: transparent;

            .moreIcon {
                color: $dodger-blue-darker;
            }
        }
    }

    .buttonActive {
        .moreIcon {
            color: $dodger-blue-darker;
        }
    }

    .optionsBlock {
        position: absolute;
        right: -9px;
        top: 35px;
        z-index: 3;
        border: 3px solid $dodger-blue-darker;
        border-radius: 4px;
        background-color: $dodger-blue-darker;
        padding: 2px 4px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            right: 13px;
            bottom: 104%;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid $dodger-blue-darker;
        }

        .elementBlock {
            @include display-flex($align: center, $justify: space-between);
            padding: 2px 4px;
            color: $white;
            border-radius: 4px;
            cursor: pointer;
            transition: 0.2s background-color;

            &:hover {
                background-color: $anakiwa;
            }

            .deleteIcon {
                @include font-style($size: 24px);
                margin-left: -4px;
            }

            .editIcon {
                @include font-style($size: 22px);
                margin-left: -2px;
            }

            .text {
                @include font-style($size: 14px, $bold: 400);
                white-space: nowrap;
                margin-left: 6px;
            }
        }

        .elementActive {
            background-color: $white;
            color: $dodger-blue-darker;
        }

        .editBlock {
            margin-bottom: 16px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: -8px;
                right: 0;
                width: 100%;
                height: 1px;
                background-color: $white;
            }
        }
    }
}

.insideComment {
    position: absolute;
    margin-bottom: 0;
    margin-left: 0;
    right: 10px;
    top: 25px;
}
