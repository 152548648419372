@import 'src/styles/variables';
@import 'src/styles/mixins';

.selectWrapper {
    position: relative;

    .select {
        @include display-flex($justify: space-between, $align: center);
        height: 28px;
        border-bottom: 2px solid $fiord;
        transition: 0.3s all;
        cursor: pointer;

        &Icon {
            font-size: 14px;
            margin-right: 6px;
            font-size: 26px;
            color: $dodger-blue-darker;
            margin-right: -5px;
        }

        &Title {
            font-size: 13px;
            font-weight: normal;
            color: $fiord;
        }
    }

    .selectActive {
        border-color: $dodger-blue-darker;
    }

    .selectItems {
        width: 100%;
        position: absolute;
        top: 28px;
        border: solid $dodger-blue-darker;
        box-shadow: 0px 4px 4px rgba(51, 123, 255, 0.15);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: $white;
        z-index: 3;
        padding: 5px;
        max-height: 200px;
        overflow-y: scroll;
        transition: 0.3s all;
        visibility: hidden;
        opacity: 0;

        .menuItem {
            @include font-style($size: 13px, $bold: 400);
            padding: 4px;
            margin-top: 4px;
            color: $fiord;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            &:first-child {
                margin-top: 0;
            }

            &:hover {
                @include font-style($bold: 700);
                background-color: $catskill-white;
            }
        }
    }

    .fadeIn {
        visibility: visible;
        opacity: 1;
    }
}
