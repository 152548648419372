@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    .premiumWindow {
        padding: 180px;
        position: relative;
    }

    .content {
        padding: 10px 0 60px;

        .mainInfoContent {
            column-gap: 10px;
            padding: 10px 0 0 0;

            .voterActivity {
                width: 100%;
                padding: 6px 10px 0 10px;
                position: relative;
            }

            .yearByYearTable {
                width: 100%;
                padding: 24px 10px 0 10px;
                position: relative;

                @media (max-width: 767.98px) {
                    padding: 24px 10px 0 10px;
                }
            }

            @media (max-width: 991.98px) {
                @include display-flex($align: center, $direction: column);
            }
        }
    }

    .trendsTablesWrapper {
        padding: 0 10px;
        position: relative;
    }
}
