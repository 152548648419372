@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .infoDescription {
        @include font-style($size: 14px, $bold: 600, $color: $white);
    }

    .container {
        @include display-flex($justify: space-between, $align: flex-start);
        height: 100%;

        .countersContainer {
            @include display-flex($justify: space-between, $direction: column);
            height: 100%;

            .total {
                @include display-flex($justify: flex-start, $direction: column);
                font-family: $font-quicksand;
                font-size: 34px;
                font-weight: 500;
                color: $white;
            }

            .counterValues {
                margin-top: 2px;

                & > div {
                    margin-top: 2px;
                    @include display-flex($justify: space-between);

                    &:nth-child(2) {
                        margin-bottom: 13px;
                    }

                    &:nth-child(-n+2) > h6 {
                        @include font-style($size: 13px, $bold: 600, $color: $white);
                        font-family: $font-inter;

                        &:last-child {
                            margin-left: 16px;
                        }
                    }

                    &:nth-child(n+3) > h6 {
                        @include font-style($size: 13px, $bold: 500, $color: #25407C);
                        font-family: $font-quicksand;
                        opacity: .7;

                        &:last-child {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }

        .changesWrapper {
            @include display-flex($justify: flex-start, $direction: column);
            font-family: $font-inter;

            & > hr {
                // border-top: 1px solid rgba(255, 255, 255, 0.1);
                border: 0.5px solid rgba(255, 255, 255, 0.5);
                width: 100%;
                margin: 12px 0;
            }

            & .changesPerPeriod:first-child div {
                margin-left: 0;
            }

            .changesPerPeriod {
                display: inline-flex;
                font-size: 14px;
                // justify-content: flex-end;
                justify-content: flex-start;
                flex-direction: column;

                & > div {
                    @include display-flex($align: center);
                    // margin-left: 6px;
                }

                & > .changesDirectionArrowIcon {
                    margin-left: 0;
                }

                .changesDirectionArrowIcon {
                    width: 20px;
                    height: 20px;
                    color: $white;
                    transform: rotate(-45deg);
                }

                .changesDirectionArrowIconRise {
                    color: $turquoise-blue;
                    transform: rotate(-45deg);
                }

                .changesDirectionArrowIconDown {
                    color: $cornflower-lilac;
                    transform: rotate(45deg);
                }

                .changes {
                    font-size: 14px;
                    font-weight: 700;
                    color: $white;
                }

                .period {
                    font-size: 10px;
                    color: $white;
                    margin-top: 4px;
                    font-weight: 500;
                    text-align: center;
                }
            }
        }
    }

    .organizationsExtendedInfoContainer {
        .changesWrapper {
            flex-direction: column;

            & > div:last-child {
                margin-left: 0;
            }
        }
    }

    .countContainer {
        margin-left: 17px;
        margin-top: 5px;

        & > h5 {
            @include font-style($size: 14px, $bold: 700, $color: $fiord);
            margin: 0;

            & > span {
                font-size: 10px;
            }

            &:last-child {
                margin-top: 5px;
            }
        }
    }
}
