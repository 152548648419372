@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputWrapper {
    position: relative;

    .input {
        @include font-style($size: 14px, $color: $fiord);
        width: 100%;
        border: none;
        border-bottom: 1px solid $nepal;
        padding: 6px 2px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $nepal;
        }

        &:disabled {
            background-color: transparent;
        }
    }

    .placeholderCapitalize {
        &::placeholder {
            text-transform: capitalize;
        }
    }

    .requiredField {
        padding-left: 16px;
    }

    .asteriskSymbol {
        @include font-style($size: 14px, $color: $dodger-blue-darker, $bold: 700);
        position: absolute;
        left: 1px;
    }

    .error {
        @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
        position: absolute;
        right: 0;
        bottom: -15px;
    }
}
