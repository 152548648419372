@import 'src/styles/variables';
@import 'src/styles/mixins';

.followButton {
    width: 76px;
    height: 34px;
    background-color: $white !important;

    & > span {
        @include font-style(
            $size: 16px !important,
            $color: $fiord !important,
            $bold: 600 !important,
            $transform: none !important
        );
    }

    &:hover {
        background-color: transparent;
    }
}

.unfollowButton {
    width: 91px;
    background-color: transparent !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: $white !important;

    & > span {
        color: $white !important;
    }
}
