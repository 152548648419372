@import 'src/styles/variables';
@import 'src/styles/mixins';

.listContainer {
    margin: 0;
    padding: 0;

    & > li:first-child {
        margin-top: 0;
    }
}
