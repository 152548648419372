@import 'src/styles/variables';
@import 'src/styles/mixins';

.fundsGraphCard {
    padding: 10px;

    &Wrapper {
        @include display-flex($direction: column);
        width: 100%;
        border-radius: 6px;
        background-color: $white;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);

        .titleWrapper {
            padding: 10px 20px 0;

            .tableTitle {
                @include font-style($size: 14px, $bold: 600, $color: $fiord);

                @media (max-width: 767.98px) {
                    font-size: 16px;
                }
            }

            @media (max-width: 767.98px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .header {
            @include display-flex($align: center, $justify: space-between);
            height: 46px;
            padding: 0 18px;
            background: $pattens-blue;

            & > span {
                @include font-style($size: 15px, $bold: 700, $color: $fiord);
            }

            & > svg {
                color: $heliotrope-lighter;
            }
        }

        .content {
            flex-grow: 1;
            padding: 40px 10px 0;

            .axisTick {
                @include font-style($size: 10px, $bold: 700, $fill: $nepal);
            }

            @media (max-width: 767.98px) {
                padding: 16px 8px 0 0;
            }
        }

        .loader {
            position: absolute;
        }
    }
}
