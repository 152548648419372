@import 'src/styles/variables';
@import 'src/styles/mixins';

.hashtagContent {
    @include display-flex($align: center);
    @include font-style($size: 14px, $bold: 400, $color: $dodger-blue-darker);
    cursor: pointer;

    & :last-child {
        font-size: 7px;
        margin-left: 3px;
    }

    &:hover {
        font-weight: 600;
    }
}
