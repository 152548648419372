@import 'src/styles/variables';
@import 'src/styles/mixins';

.commentsSectionWrapper {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid $pattens-blue;

    .inputSection {
        @include display-flex();
        position: relative;

        .link {
            width: 36px;
            height: 36px;
        }

        .userLogo {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        .input {
            @include font-style($size: 14px, $color: $fiord);
            flex-grow: 1;
            height: 36px;
            border: none;
            border-radius: 4px;
            background-color: $ghostwhite;
            margin-left: 10px;
            padding: 12px 10px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $regent-gray;
            }
        }

        .commentBlock {
            @include display-flex($align: center);
            flex-grow: 1;
            height: 36px;
            border-radius: 4px;
            background-color: $ghostwhite;
            margin-left: 10px;
            padding: 12px 10px;
            cursor: pointer;

            & > span {
                @include font-style($size: 14px, $color: $regent-gray);
            }
        }

        .notificationTooltip {
            bottom: 30px;
            left: -20px;

            @media (max-width: 575.98px) {
                left: 0;
            }
        }
    }

    .showMoreCommentsOptions {
        @include display-flex($align: center);
        margin-top: 25px;
        margin-left: 46px;

        .title {
            @include font-style($size: 12px, $bold: 700, $color: $fiord);
            cursor: pointer;
        }

        .line {
            flex-grow: 1;
            height: 1px;
            background-color: $pattens-blue;
            margin-left: 12px;
        }
    }
}
