@import 'src/styles/variables';
@import 'src/styles/mixins';

.textareaWrapper {
    margin-top: 40px;
    position: relative;

    .textarea {
        @include font-style($size: 14px, $color: $fiord);
        width: 100%;
        height: 30px;
        padding-left: 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $nepal;
        resize: vertical;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $nepal;
        }
    }

    .textareaError {
        border-color: $tomato;
        transition: border-color 0.3s;

        &:focus {
            border-color: $tomato;
        }

        &::placeholder {
            color: $tomato;
        }
    }

    .errorText {
        @include font-style($size: 12px, $color: $tomato);
        position: absolute;
    }
}
