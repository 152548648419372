@import 'src/styles/variables';
@import 'src/styles/mixins';

.tagContainer {
    margin-top: 15px;
    margin-right: 10px;

    .tagButton {
        @include font-style($size: 12px, $bold: 500, $lineHeight: 18px, $transform: none, $color: $regent-gray);
        padding: 2px 10px;
        border: 1px solid $regent-gray;
        border-radius: 4px;
    }

    .tagActive {
        background-color: $regent-gray;
        color: $white;

        &:hover {
            background-color: $regent-gray;
        }
    }
}
