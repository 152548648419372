@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeaderWrapper {
    .titleSection {
        padding: 10px 15px;
        border-bottom: 1px solid $catskill-white;

        .tableTitle {
            @include font-style($size: 14px, $bold: 600, $color: $fiord);
        }
    }

    .tableHeaderContainer {
        @include display-flex();
        padding: 5px 15px;

        & > div {
            @include display-flex($align: center);

            & > div {
                @include display-flex($align: center);

                span {
                    @include table-header-font-styles();
                }
            }
        }

        .organization {
            width: 35%;
        }

        // .votingPower {
        //     width: 12%;
        // }

        .proposals {
            width: 16%;
        }

        .successProposals {
            width: 17%;
        }

        .votes {
            width: 15%;
        }

        .successfulVotes {
            width: 17%;
        }
    }
}
