@import 'src/styles/variables';
@import 'src/styles/mixins';

.followingButtonWrapper {
    margin-left: 14px;

    .followingButton {
        min-width: unset;
        padding: 0;

        .followingIcon {
            @include font-style($size: 39px, $color: $nepal);
            transition: 0.3s color;
        }

        &:hover {
            background-color: transparent;

            .followingIcon {
                color: $fiord;
            }
        }
    }

    .followingPageOpen {
        .followingIcon {
            color: $dodger-blue-darker;

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }

    @media (max-width: 767.98px) {
        margin-left: 10px;
    }
}
