@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex();
    padding: 10px 14px;
    border-top: 1px solid $catskill-white;

    & > div {
        @include display-flex($align: center);
        padding-right: 20px;
        width: calc(100% / 4);

        & > div > span {
            @include font-style($size: 14px, $bold: 400, $color: $fiord);
        }

        .link {
            @include font-style($size: 14px, $color: $fiord, $bold: 700);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: 0.3s color;

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }
}
