@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: center);
    position: relative;

    .blurBox {
        background: linear-gradient(
            180.71deg,
            $dodger-blue-darker 22.77%,
            $cornflowerblue 79.44%,
            $lightseagreen 99.39%
        );
        filter: blur(120px);
        position: absolute;
        width: 100%;
        height: 120px;
    }

    .container {
        width: 100%;
        max-width: 950px;
        margin: 60px auto 50px;
        z-index: 2;
        min-height: calc(100vh - #{$header-tablet-height} - 110px);
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0px 0px 20px rgba(51, 123, 255, 0.2);
        overflow: hidden;

        @media (max-width: 991.98px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (max-width: 767.98px) {
            min-height: calc(100vh - #{$header-mobile-height} - 110px);
        }
    }
}
