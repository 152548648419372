@import 'src/styles/variables';
@import 'src/styles/mixins';

.substormsFilter {
    margin-bottom: 20px;

    &Header {
        @include display-flex($align: center);
        cursor: pointer;

        .substormsIcon {
            width: 24px;
            height: 24px;
        }

        .title {
            @include font-style($size: 14px, $bold: 400, $color: $fiord);
            margin-left: 10px;

            @media (max-width: 575.98px) {
                font-size: 16px;
                margin-left: 14px;
            }
        }

        .substormsWrapperOpen {
            font-weight: 700;
        }
    }

    .selectedSubstormsWrapper {
        margin-left: 34px;
    }
}
