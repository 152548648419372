@import 'src/styles/variables';
@import 'src/styles/mixins';


.form {
    margin-top: 40px;

    .selectFieldContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 90px;
    }

    .userInfoFields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 90px;
        row-gap: 40px;
        margin-top: 40px;
    }

    .buttonsWrapper {
        @include display-flex($justify: space-between);
        margin-top: 77px;

        .cancelButton {
            @include font-style($size: 16px, $color: $nepal, $bold: 500, $transform: none);
            width: 90px;
            height: 44px;
            border: 2px solid $nepal;

            &:hover {
                background-color: transparent;
            }
        }

        .submitButton {
            @include font-style($size: 16px, $color: $white, $bold: 500, $transform: none);
            width: 90px;
            height: 44px;
            background-color: $bright-turquoise;

            &:hover {
                background-color: $lightseagreen;
            }

            &:disabled {
                background-color: $heather;
            }
        }
    }

    @media (max-width: 575.98px) {
        .selectFieldContainer,
        .userInfoFields {
            grid-template-columns: 1fr;
        }

        .buttonsWrapper {
            margin-top: 40px;
            
            .cancelButton {
                display: none;
            }

            .submitButton {
                width: 100%;
            }
        }
    }
}
