@import 'src/styles/mixins';
@import 'src/styles/variables';

.tagButtonsWrapper {
    @include display-flex($align: flex-start, $direction: column);

    &:first-child {
        margin-top: 0;
    }

    .tagButton {
        @include display-flex($align: center);
        color: $white;
        margin-top: 10px;
        cursor: pointer;

        &:first-child {
            margin-top: 0;
        }

        .tagTitle {
            @include font-style($size: 14px, $bold: 500, $lineHeight: 21px);
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .tagCount {
            @include font-style($size: 8px, $bold: 700, $lineHeight: 12px);
            margin-left: 3px;
        }
    }

    .navigation {
        @include display-flex($align: center);
        @include font-style($size: 14px, $color: $white, $decoration: none);
        margin-top: 10px;
    }

    @media (max-width: 767.98px) {
        margin-top: 40px;
    }
}
