@import 'src/styles/variables';
@import 'src/styles/mixins';

.graphCard {
    overflow: hidden;
    background-color: #D7E4F4;
    padding: 16px;
    border-radius: 16px;

    .titleWrapper {
        padding-bottom: 16px;
        @include display-flex($align: center);

        .infoIcon {
            margin: 0 8px;
        }

        .tableTitle {
            @include font-style($size: 18px, $bold: 700, $color: #1F3B82);
        }

        @media (max-width: 767.98px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .wrapper {
        @include display-flex($direction: column);
        width: 100%;
        border-radius: 6px;
        background-color: $white;
        position: relative;
        overflow: hidden;
        border: 1px solid #B0C2CD;

        .header {
            @include display-flex($align: center, $justify: space-between);
            height: 46px;
            padding: 0 18px;
            background: $pattens-blue;

            & > span {
                @include font-style($size: 15px, $bold: 700, $color: $fiord);
            }

            & > svg {
                color: $heliotrope-lighter;
            }
        }

        .content {
            flex-grow: 1;
            padding: 40px 10px 0;

            .axisTick {
                @include font-style($size: 10px, $bold: 700, $fill: $nepal);
            }

            @media (max-width: 767.98px) {
                padding: 16px 8px 0 0;
            }
        }

        .loader {
            position: absolute;
        }
    }
}
