@import 'src/styles/variables';
@import 'src/styles/mixins';

.repliesSectionWrapper {
    margin-top: 16px;
    margin-left: 46px;

    .inputSection {
        @include display-flex();
        position: relative;

        .link {
            width: 36px;
            height: 36px;

            .userLogo {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }
    }

    .showMoreRepliesOptions {
        @include display-flex($align: center);
        margin-top: 25px;
        margin-left: 46px;
    
        .title {
            @include font-style($size: 12px, $bold: 700, $color: $fiord);
            cursor: pointer;
        }
    
        .line {
            flex-grow: 1;
            height: 1px;
            background-color: $pattens-blue;
            margin-left: 12px;
        }
    }
}