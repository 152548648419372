@import 'src/styles/variables';
@import 'src/styles/mixins';

.topOrganizationsTableWrapper {
    padding: 15px 0 20px;
    background-color: $white;
    box-shadow: 0 0 30px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
    margin-top: 30px;

    .tableTitle {
        @include font-style($size: 15px, $bold: 600, $color: $fiord);
        margin: 0;
        margin-left: 20px;
    }
}

.tableRowsEmpty {
    padding-bottom: 0;
}
