@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardWrapper {
    @include display-flex($direction: column);

    .card {
        @include display-flex($justify: space-between, $direction: column);
        height: 100%;
        background: rgba($white, 0.25);
        border-radius: 6px;
        padding: 16px;

        &Header {
            .title {
                @include font-style($size: 14px, $bold: 600, $color: $white);
            }
        }

        &Body {
            @include display-flex($align: flex-start, $direction: column);

            .value {
                @include font-style($size: 40px, $bold: 400, $color: $white, $family: $font-koho);
            }

            .changesWrapper {
                @include display-flex($justify: flex-start, $direction: column);
                margin-top: 16px;

                & > hr {
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    width: 100%;
                    margin: 12px 0;
                }

                & .changesPerPeriod:first-child div {
                    margin-left: 0;
                }

                .changesPerPeriod {
                    display: inline-flex;
                    font-size: 14px;
                    justify-content: flex-start;
                    flex-direction: column;
                    margin-left: -4px;

                    & > div {
                        @include display-flex($align: center);
                    }

                    & > .changesDirectionArrowIcon {
                        margin-left: 0;
                    }

                    .changesDirectionArrowIcon {
                        width: 20px;
                        height: 20px;
                        color: $white;
                        transform: rotate(-45deg);
                    }

                    .changesDirectionArrowIconRise {
                        color: $turquoise-blue;
                        transform: rotate(-45deg);
                    }

                    .changesDirectionArrowIconDown {
                        color: $cornflower-lilac;
                        transform: rotate(45deg);
                    }

                    .changes {
                        font-size: 14px;
                        font-weight: 400;
                        color: $white;
                    }

                    .period {
                        font-size: 10px;
                        color: $white;
                        margin-top: 4px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
        }
    }
}
