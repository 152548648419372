@import 'src/styles/variables';
@import 'src/styles/mixins';

.searchContainer {
    @include display-flex($align: center);

    .searchButton {
        min-width: unset;
        border-radius: 50%;
        padding: 0;

        .title {
            @include font-style($size: 14px, $color: $fiord, $transform: none, $bold: 400, $lineHeight: 18px);
            margin-left: 10px;

            @media (max-width: 575.98px) {
                font-size: 16px;
                margin-left: 14px;
            }
        }

        &:hover {
            background-color: transparent;
        }

        .searchIcon {
            color: $fiord;
        }

        .searchIconTable {
            color: $nepal;
        }
    }

    .inputContainer {
        @include display-flex();
        position: relative;

        .input {
            @include font-style($size: 14px, $color: $fiord);
            border: none;
            border-bottom: 2px solid $fiord;
            background-color: transparent;
            caret-color: $fiord;
            padding: 0;
            padding-bottom: 5px;
            width: 0;
            margin-left: 10px;

            &:focus {
                outline: none;
            }

            @media (max-width: 575.98px) {
                font-size: 16px;
                background-color: $ghostwhite;
                border-bottom: none;
                border-radius: 3px;
                height: 36px;

                &::placeholder {
                    color: $nepal;
                }
            }
        }

        .inputTable {
            border-color: $fiord;
            caret-color: $nepal;
        }

        .inputOpen {
            width: 100%;
            padding: 0 20px 5px 5px;

            @media (max-width: 575.98px) {
                padding: 6px 20px 6px 8px;
            }
        }

        .clearFieldButton {
            display: none;
            width: 15px;
            height: 15px;
            min-width: unset;
            padding: 0;
            position: absolute;
            right: 0;
            bottom: 7px;

            &:hover {
                background-color: transparent;
            }

            .clearFieldIcon {
                @include font-style($size: 18px, $color: $fiord);
            }

            .clearFieldIconTable {
                color: $fiord;
            }

            @media (max-width: 575.98px) {
                bottom: 12px;
                right: 2px;
            }
        }

        .clearButtonActive {
            display: block;
        }
    }

    @media (max-width: 575.98px) {
        width: 100%;
        margin-bottom: 20px;

        .inputContainer {
            width: 100%;
        }
    }
}

.searchContainerMobile {
    @media (max-width: 575.98px) {
        margin-bottom: 0;

        .searchButton {
            .searchIcon {
                font-size: 30px;
            }
        }
    }
}
