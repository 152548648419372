@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    @include display-flex($align: center, $justify: space-between);
    height: 30px;
    border-bottom: 1px solid $athens-gray;

    .tabsList {
        @include display-flex();
        width: 100%;
        height: 100%;

        .tabItems {
            @include display-flex($align: center, $justify: center);
            width: 50%;
            cursor: pointer;
            position: relative;

            .text {
                @include font-style($size: 11px, $bold: 400, $color: $white);
            }
        }

        .activeTab {
            &::before {
                content: '';
                width: 100%;
                height: 3px;
                background-color: $aquamarine;
                position: absolute;
                bottom: -1px;
            }

            & > span {
                color: $aquamarine !important;
                font-weight: 700 !important;
            }
        }
    }
}
