@import 'src/styles/variables';
@import 'src/styles/mixins';

.dateFilterWrapper {
    display: flex;
    flex-direction: column;

    .dateFilterHeader {
        color: #3d536c;
        font-family: 'Inter';
        font-style: normal;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: center;
        font-weight: normal;
        cursor: pointer;

        .dateFilterHeaderIcon {
            font-size: 24px;
        }

        & > span {
            margin-left: 10px;
        }
    }

    .selectedDateWrapper {
        margin-left: 34px;

        & > div {
            & > span {
                overflow: visible;
                white-space: initial;
            }
        }
    }

    .dateFilterOptions {
        display: flex;
        flex-direction: column;
        margin-left: 34px;
        margin-top: 20px;
        color: #3d536c;
        border-bottom: 1px solid #cfe1ff;

        .dateFilterOption {
            margin-bottom: 14px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }
    }

    .rangeWrapper {
        display: flex;
        flex-direction: column;
        margin-top: 14px;
        margin-left: 34px;

        .from,
        .to {
            display: flex;
            flex-direction: column;

            h5 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 120%;
                text-transform: uppercase;
            }

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #9cb4c2;
                cursor: pointer;
                user-select: none;
            }
        }

        .to {
            margin-top: 14px;
        }
    }
}

.bold {
    font-weight: bold !important;
}
