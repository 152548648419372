@import 'src/styles/variables';
@import 'src/styles/mixins';

.sortingWrapper {
    cursor: pointer;

    .name {
        white-space: break-spaces;
        line-height: 14px;
    }

    .infoIcon {
        @include font-style($size: 18px, $color: $nepal);
        margin-left: 2px;
        visibility: hidden;
        transition: color 0.2s;

        &:hover {
            color: $dodger-blue-darker;
        }
    }

    &:hover {
        .infoIcon {
            visibility: visible;
        }

        & > div > span {
            border-top-color: $bright-turquoise;
            border-bottom-color: $bright-turquoise;
        }
    }
}
