@import 'src/styles/variables';
@import 'src/styles/mixins';

.labelsWrapper {
    margin-top: 16px;

    .labelsContainer {
        @include display-flex($wrap: wrap, $rowGap: 8px, $columnGap: 16px);

        .labelWrapper {
            display: flex;
            max-width: 150px;
            padding: 6px 16px;
            border: 1px solid $white;
            overflow: hidden;

            .labelName {
                @include font-style($size: 14px, $bold: 600, $color: $white, $family: $font-koho);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .showMoreLabelsButton {
        @include font-style($size: 14px, $bold: 600, $color: $white, $family: $font-koho, $transform: none);
        min-width: unset;
        padding: 0;
        margin-top: 10px;

        &:hover {
            background-color: transparent;
        }
    }
}
