@import 'src/styles/variables';
@import 'src/styles/mixins';

.etherscanButton {
    padding: 0;
    border-radius: 50%;

    .etherscanLogo {
        width: 21.25px;
        height: 21.25px;
        border-radius: 50%;
    }
}
