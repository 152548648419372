@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .graphsContainer {
        display: grid;
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        margin-top: 4px;

        & > div {
            &:first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            &:last-child {
                border-right: none;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }
    }

    @media (max-width: 767.98px) {
        .graphsContainer {
            grid-template-columns: minmax(200px, 1fr);
            grid-template-rows: repeat(2, minmax(200px, 1fr));

            & > div {
                &:first-child {
                    border-right: none;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 6px;
                    border-top-left-radius: 6px;
                    border-bottom: 1px solid rgba($white, 0.5);
                }

                &:last-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 6px;
                    border-bottom-left-radius: 6px;
                }
            }
        }
    }
}
