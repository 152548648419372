@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    width: 184px;
    min-width: 186px;
    height: 73px;
    background: $white;
    border-radius: 8px;
    border: 1px solid #B0C2CD;
    position: relative;
    transition: 0.3s all;

    &:hover {
        background: $polar-white;
    }

    .userInfo {
        @include display-flex($align: center);
        padding: 13px 14px;
        overflow: hidden;

        .avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }

        .userDetails {
            @include display-flex($direction: column);
            margin-left: 8px;
            overflow: hidden;

            .shares {
                font-size: 20px;
                font-weight: 500;
                font-family: $font-koho;
                color: $nepal;

                & > span {
                    font-size: 18px;
                    font-family: $font-koho;
                }
            }

            .userName {
                font-weight: 700;
                color: $fiord;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: color 0.3s;

                &:hover {
                    color: $dodger-blue-darker;
                }
            }
        }

        @media (max-width: 767.98px) {
            flex-direction: column;
            padding: 11px 8px 5px;

            .userDetails {
                flex-direction: column-reverse;
                align-items: center;
                width: 100%;
                margin-left: 0;

                .userName {
                    max-width: 90px;
                    font-size: 12px;
                    margin-top: 3px;
                }

                .shares {
                    font-size: 15px;
                    font-weight: 500;
                    margin-top: 2px;

                    & > span {
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .moreButton {
        @include display-flex($align: center, $justify: center);
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 32px;
        height: 16px;
        position: absolute;
        right: 0;
        bottom: 0;

        .moreIcon {
            color: $dodger-blue-darker;
        }

        @media (max-width: 767.98px) {
            display: none;
        }
    }

    @media (max-width: 767.98px) {
        width: 105px;
        min-width: 105px;
        height: 104px;
    }
}
