@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($align: flex-start);

    .cardBody {
        @include display-flex($align: center);
        cursor: pointer;

        &:hover {
            .mainInfo {
                .name {
                    color: $dodger-blue-darker;
                }
            }
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid transparent;
            transition: 0.3s border-color;
        }

        .hoveredCircle {
            @include display-flex($align: center, $justify: center);
            width: 40px;
            height: 40px;
            background-color: $white;
            border-radius: 50%;
            border: 2px solid $dodger-blue-darker;
            animation: fadeIn 0.5s;

            .arrowImage {
                width: 20px;
                height: 16px;
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        .mainInfo {
            @include display-flex($align: flex-start, $direction: column);
            margin-left: 14px;

            .name {
                @include font-style($size: 14px, $bold: 700, $color: $fiord);
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: 0.3s color;
            }

            .nameSelected {
                color: $dodger-blue-darker;
            }

            .statisticsInfo {
                @include display-flex();

                & div:last-child {
                    margin-left: 10px;

                    h6 {
                        text-align: right;
                    }
                }

                h6 {
                    @include font-style($size: 10px, $bold: 500, $color: $nepal);
                    margin: 0;
                    margin-top: 2px;
                }
            }
        }
    }

    .cardBodyInactive {
        cursor: auto;
    }
}
