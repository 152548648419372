@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 10px 25px;

    & > div {
        @include display-flex($align: center);

        & > div {
            @include display-flex($align: center);

            span {
                @include table-header-font-styles();
            }
        }
    }

    .rank {
        width: 4%;
    }

    .title {
        width: 35%;
    }

    .date {
        width: 12%;
    }

    .proposedBy {
        width: 15%;
    }

    .outcomeStatus {
        width: 9%;
    }

    .quorum {
        width: 9%;
    }

    .votes {
        width: 9%;
    }

    .forShares {
        width: 8%;
    }

    .againstShares {
        width: 8%;
    }

    .abstainShares {
        width: 8%;
    }

    @media (max-width: 991.98px) {
        padding: 5px 10px;
    }
}
