@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 10px 20px 10px;
    border-bottom: 1px solid $catskill-white;

    & > div {
        @include display-flex($align: center);
        @include font-style($size: 10px, $bold: 700, $color: $nepal);

        @media (max-width: 767.98px) {
            font-size: 11px;
        }
    }

    .number {
        width: 10%;
    }

    .name {
        width: 45%;
    }

    .holding {
        width: 25%;
    }

    .usdValue {
        width: 20%;
    }
}
