@import 'src/styles/variables';
@import 'src/styles/mixins';

.pageContainer {
    @include display-flex($justify: center);
    @include max-device-width();
    width: 100%;
    min-height: 450px;
    padding: 55px 16px;

    .feedsSection {
        @include display-flex($align: center, $direction: column);
        width: 100%;
        max-width: 800px;

        .infinityScrollWrapper {
            width: 100%;
            margin-top: 50px;

            .cardsContainer {
                @include display-flex($direction: column);

                & > div:first-child {
                    margin-top: 0;
                }
            }

            .loaderWrapper {
                margin-top: 15px;
            }

            @media (max-width: 575.98px) {
                padding-top: 25px;
                margin-top: 0;
                border-top: 1px solid $mystic;
            }
        }
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .filtersSection {
            max-width: none;
            margin-right: 0;
        }
    }
}
