@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 14;
    background: $fiord;

    .modal {
        @include display-flex($align: center, $direction: column);
        height: 100%;

        &Header {
            @include display-flex($justify: flex-end);
            width: 100%;
            padding: 20px;

            .closeButton {
                min-width: unset;
                padding: 0;

                .closeIcon {
                    @include font-style($size: 36px, $color: $white);
                }
            }
        }

        &Body {
            @include display-flex($align: center, $direction: column);
            width: 400px;
            padding: 0px 50px 20px;

            .navigationAndOptions {
                @include display-flex($align: center, $justify: center, $direction: column);
                width: 100%;

                .pagesLinksContainer {
                    width: 100%;

                    & > a > div {
                        width: 100%;
                        justify-content: space-between;

                        & > img {
                            width: 60px;
                            height: 60px;
                            opacity: 1;
                        }

                        & > h6 {
                            font-size: 20px;
                            opacity: 1;
                        }
                    }

                    @media (max-width: 575.98px) {
                        & > a > div {
                            justify-content: flex-start;

                            & > img {
                                width: 40px;
                                height: 40px;
                            }

                            & > h6 {
                                font-size: 16px;
                                margin-left: 24px;
                            }
                        }
                    }
                }

                .aboutOptions {
                    @include display-flex($align: center, $justify: space-between);
                    width: 100%;
                    margin-top: 24px;

                    & > div {
                        @include display-flex($align: center);

                        .moreIcon {
                            width: 60px;
                            height: 60px;
                        }

                        .title {
                            @include font-style($size: 20px, $bold: 500, $color: $white);
                            margin-left: 14px;
                        }
                    }

                    .arrowIcon {
                        @include font-style($size: 30px, $bold: 500, $color: $white);
                    }

                    @media (max-width: 575.98px) {
                        & > div {
                            .moreIcon {
                                width: 40px;
                                height: 40px;
                            }

                            .title {
                                font-size: 16px;
                                margin-left: 24px;
                            }
                        }

                        .arrowIcon {
                            font-size: 24px;
                        }
                    }
                }
            }

            .socialsPricingLinksWrapper {
                @include display-flex($direction: column);
                width: 100%;
                padding-top: 25px;
                margin-top: 15px;
                border-top: 1px solid $heather;

                .mainSocialLinks {
                    margin-left: 0;

                    & > a {
                        margin-left: 16px;

                        &:first-child {
                            margin-left: 0;
                        }

                        & > img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                .pricingLink {
                    @include font-style($size: 20px, $bold: 700, $color: $white, $transform: uppercase);
                    margin-top: 25px;

                    @media (max-width: 575.98px) {
                        font-size: 16px;
                    }
                }
            }

            @media (max-width: 575.98px) {
                width: 100%;
            }
        }

        &BodyAbout {
            align-items: flex-start;
            padding-left: 70px;
            padding-right: 70px;

            .aboutHeader {
                @include display-flex($align: center);
                width: 100%;
                padding-bottom: 20px;
                border-bottom: 1px solid $heather;

                .arrowBackIcon {
                    @include font-style($size: 24px, $color: $white);
                    transform: rotate(180deg);
                    margin-left: -45px;
                }

                .aboutTitle {
                    @include font-style($size: 16px, $bold: 700, $color: $nepal);
                    margin-left: 20px;
                }
            }

            .supportingPageLink {
                @include font-style($size: 20px, $bold: 500, $color: $white);
                margin-top: 24px;

                @media (max-width: 575.98px) {
                    font-size: 16px;
                }
            }
        }
    }
}
