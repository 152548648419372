@import 'src/styles/variables';
@import 'src/styles/mixins';

.bannerWrapper {
    @include display-flex($justify: flex-end);
    width: 100%;
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 10px;

    .banner {
        width: 536px;
        height: 100px;
        border-radius: 4px;
        border: 2px solid $white;

        @media (max-width: 767.98px) {
            width: 100%;
        }
    }

    @media (max-width: 575.98px) {
        & > a {
            width: 100%;

            .banner {
                height: auto;
            }
        }
    }
}
