@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    margin-top: 30px;

    .moreInfoTableWrapper {
        margin-top: 10px;
        background-color: rgba(238, 241, 245, 0.4);
    }
}
