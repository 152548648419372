@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
    @include display-flex($justify: space-between, $gap: 25px);
    width: 700px;
    height: 380px;
    background-color: rgba($white, 0.25);
    border-radius: 20px;
    padding: 20px;
    border: 1px solid rgba($white, 0.5);

    .statsWrapper {
        margin-top: 10px;

        .titleWrapper {
            .title {
                @include font-style($size: 30px, $bold: 700, $color: $white, $family: $font-koho !important);
            }
        }

        .statsContainer {
            @include display-flex($gap: 30px);
            padding: 40px 0;

            .mainStats {
                color: $aquamarine;

                & > div {
                    .description {
                        @include font-style($size: 16px, $bold: 500);

                        @media (max-width: 575.98px) {
                            @include font-style($size: 14px, $bold: 700);
                        }
                    }

                    .counter {
                        @include font-style($size: 30px, $bold: 600, $family: $font-koho);
                    }

                    &:last-child {
                        margin-top: 30px;
                    }
                }
            }

            .mainCounter {
                padding-left: 20px;
                border-left: 1px solid rgba($white, 0.5);

                & > div {
                    padding: 16px 0;
                    border-top: 1px solid rgba($white, 0.5);

                    &:first-child {
                        padding-top: 0;
                        border-top: none;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            @media (max-width: 575.98px) {
                flex-direction: column;

                .mainStats {
                    @include display-flex($justify: space-between);

                    & > div {
                        &:last-child {
                            margin-top: 0;
                        }
                    }
                }

                .mainCounter {
                    padding-left: 0;
                    border-left: none;
                }
            }
        }
    }

    @media (max-width: 991.98px) {
        margin-top: 20px;
        width: 100%;
        height: auto;

        .statsContainer {
            .mainCounter {
                width: 100%;
            }
        }
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
    }
}
