@import 'src/styles/variables';
@import 'src/styles/mixins';

.voteSection {
    @include display-flex($align: center);
    margin-right: 10px;
    margin-bottom: 3px;

    .voteCircle {
        @include display-flex($align: center, $justify: center);
        width: 30px;
        height: 30px;
        border-radius: 50%;

        & > img {
            width: 21px;
            height: 15px;
        }
    }

    .votedFor {
        background-color: $white-ice-lighter;
    }

    .votedAgainst {
        background-color: $wisp-pink-lighter;
    }

    .text {
        @include font-style(
            $size: 10px,
            $color: $fiord,
            $bold: 700,
            $lineHeight: 12px,
            $transform: uppercase
        );
        margin-left: 8px;
    }
}

.voteSectionMobile {
    flex-direction: row-reverse;
    margin-right: 0;
    margin-bottom: 0;

    .voteCircle {
        margin-left: 10px;
    }

    .text {
        margin-left: 0;
    }
}