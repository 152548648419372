@import 'src/styles/variables';
@import 'src/styles/mixins';

.tokenInfoWrapper {
    .fieldsWrapper {
        display: grid;
        grid-template-columns: minmax(120px, 120px) minmax(80px, 140px) minmax(60px, 60px) minmax(120px, 320px);
        column-gap: 30px;
        margin-top: 30px;
    }
}