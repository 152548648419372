@import 'src/styles/variables';
@import 'src/styles/mixins';

.contentWrapper {
    @include display-flex($justify: center);

    .statsGraphCardsWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;

        .organizationsStatsCardContainer {
            @include display-flex($direction: column);

            & .organizationsStatsCardWrapper {
                & > hr {
                    border: 1px solid $lightblue;
                    width: 100%;
                    margin: 0;
                }

                & > div[class*='card']:first-child div[class*='Body'] {
                    border-radius: unset;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }

                & > div[class*='card']:last-child div[class*='Body'] {
                    border-radius: unset;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }

                & > div {
                    &:first-child {
                        height: 125px;
                    }

                    &:last-child {
                        height: 140px;
                    }
                }
            }
        }

        .lineGraph {
            height: 75px;
            margin-top: 5.36px;
        }

        @media (min-width: 768px) and (max-width: 1199.98px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 767.98px) {
            grid-template-columns: 1fr;
            row-gap: 40px;
        }
    }
}
