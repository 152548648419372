@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($justify: flex-start);
    padding: 12px 0;

    & > div {
        @include display-flex($justify: flex-start, $align: center);
        @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 14px);
    }

    .organization {
        width: 20%;

        img {
            height: 36px;
            width: 36px;
            margin-right: 9px;
            border-radius: 50%;
        }

        a {
            @include font-style($size: 14px, $bold: 600, $color: $fiord, $lineHeight: 15.5px);
            overflow-wrap: anywhere;
            padding-right: 10px;
        }
    }

    .treasury {
        width: 20%;

        span {
            @include font-style($size: 14px, $bold: 700, $color: $fiord, $lineHeight: 14px);
        }
    }

    .members {
        width: 30%;
    }

    .notifications,
    .following {
        @include display-flex($justify: center);
        width: 15%;
    }

    @media (max-width: 767.98px) {
        .organization {
            width: 30%;
        }

        .treasury {
            width: 20%;
        }

        .members {
            width: 20%;
        }
    }

    @media (max-width: 575.98px) {
        .organization {
            width: 50%;
        }

        .notifications,
        .following {
            width: 25%;
        }

        .treasury,
        .members {
            display: none;
        }
    }
}
