@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    background-color: $bright-turquoise;
    z-index: 2;

    .container,
    .mobileContainer {
        @include max-device-width();
        display: none;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 0 50px;
        color: $white;
        font-size: 14px;
        font-weight: 700;

        .closeIconWrapper {
            display: flex;
            justify-content: flex-end;

            .closeIcon {
                font-size: 30px;
                cursor: pointer;
            }
        }

        .message {
            flex-grow: 2;
            display: flex;
            justify-content: center;
        }
    }

    .container {
        @media screen and (min-width: 866px) {
            display: flex;
        }
    }

    .mobileContainer {
        flex-direction: column;
        height: 100px;
        padding: 20px 16px;

        @media screen and (max-width: 865px) {
            display: flex;

            .closeIconWrapper {
                margin-top: -10px;
            }

            .message {
                text-align: center;
                margin-top: 10px;
            }
        }

        @media (max-width: 575.98px) {
            .message {
                font-weight: 400;
            }
        }

        .mobileHeaderWrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.hidden {
    display: none;
}
