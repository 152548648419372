@import 'src/styles/variables';
@import 'src/styles/mixins';

.blurBox {
  background: linear-gradient(180deg, #337BFF 0%, #67B7F4 53.12%, rgba(61, 200, 208, 0) 100%);
  opacity: 0.2;
  position: absolute;
  left: 0;
  right: 0;
  top: $header-height;
  height: 120px;

  @media (max-width: 991.98px) {
    top: $header-tablet-height
  }

  @media (max-width: 767.98px) {
    top: $header-mobile-height;

  }
}

.pageWrapper {
  max-width: calc(1275px + 100px);
  padding: 0 50px;
  margin: 0 auto;

  color: #3D536C;
  position: relative;

  @media (max-width: 575.98px) {
    width: 100%;
    padding: 0 16px 16px;
  }
  
  @media (max-width: 767.98px) {
    padding: 0 20px 16px;
  }

  .header {
    margin: 72px 0 40px;
    color: #3D536C;

    display: flex;
    align-items: baseline;

    filter: blur(0);

     @media (max-width: 575.98px) {
      margin: 36px 0;
    }

    h1 {
      font-family: $font-koho;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 110%;
    }

    h5 {
      margin-top: 10px;
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin-left: 14px;

       @media (max-width: 575.98px) {
        display: none;
      }
    }
  }

  .content {
    .contentHeader {
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #3D536C;

      h5 {
        font-family: 'Inter', serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #3D536C;
      }
    }
  }

  .mainPost {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 40px;
    cursor: pointer;

     @media (max-width: 575.98px) {
      display: none;
      gap: 0;

    }
    
    @media (max-width: 991.98px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 40px;
    }

    .image {
      width: 100%;
      min-width: 100%;

      object-fit: cover;

      background: linear-gradient(253.28deg, #FFCEDB 0%, #DE97EF 50.43%, #6A82FB 98.81%);
      box-shadow: 0 0 10px rgba(6, 102, 246, 0.1);
      border-radius: 12px;
    }

    .postPreview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 30px;
      color: #3D536C;
      width: 75%;

       @media (max-width: 575.98px) {
        margin-left: 0;
      }
     
      @media (max-width: 1150px) {
        width: 90%;
      }

      @media (max-width: 991.98px) {
        margin-left: 0;

      }

      .category {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
      }

      .title {
        margin-top: 12px;
        font-family: $font-koho;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 120%;
      }

      .text {
        margin-top: 20px;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }

  .recentPostsWrapper {
    margin: 40px 0 65px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

     @media (max-width: 575.98px) {
      margin-top: 0;
      gap: 0;
    }

    @media (max-width: 1191.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767.98px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .postPreviewSmall {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 100%;
      cursor: pointer;

       @media (max-width: 575.98px) {
        margin-left: 0;
        width: 100%;
        min-width: 100%;
        height: initial;
        margin-top: 45px !important;

        &:first-child {
          margin-top: 0;
        }
      }

      &:first-child, &:nth-child(4n) {
        margin-left: 0;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        margin-top: 0;
      }

      .image {
        width: 100%;
        height: 224px;
        background: linear-gradient(253.28deg, #FFCEDB 0%, #DE97EF 50.43%, #6A82FB 98.81%);
        box-shadow: 0 0 10px rgba(6, 102, 246, 0.1);
        border-radius: 12px;
        
        @media (max-width: 575.98px) {
          width: 100%;
          min-width: 100%;
        }
        
        @media (max-width: 1391.98px) {
          height: 210px;
        }
        @media (max-width: 1341.98px) {
          height: 195px;
        }
        @media (max-width: 1291.98px) {
          height: 180px;
        }
        @media (max-width: 1241.98px) {
          height: 170px;
        }
        @media (max-width: 1191.98px) {
          height: 275px;
        }
        @media (max-width: 1141.98px) {
          height: 262px;
        }
        @media (max-width: 1091.98px) {
          height: 248px;
        }
        @media (max-width: 1041.98px) {
          height: 241px;
        }
        @media (max-width: 991.98px) {
          height: 234px;
        }
        @media (max-width: 941.98px) {
          height: 222px;
        }
        @media (max-width: 891.98px) {
          height: 210px;
        }
        @media (max-width: 817.98px) {
          height: 180px;
        }
        @media (max-width: 767.98px) {
         height: 100%;
        }
        
      }

      .category {
        margin-top: 25px;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;

         @media (max-width: 575.98px) {
          margin-top: 15px;
        }
      }

      .title {
        margin-top: 14px;
        font-family: $font-koho;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 120%;

         @media (max-width: 575.98px) {
          margin-top: 8px;
        }
      }

      .description {
        margin-top: 16px;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

         @media (max-width: 575.98px) {
          display: none;
        }
      }
    }
  }

  .postsByCategories {
    .headerCategories {
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #3D536C;

      h5 {
        font-family: 'Inter', serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #3D536C;

         @media (max-width: 575.98px) {
          display: block;
          margin-left: 0;
        }
      }
    }

    .categoriesWrapper {
      margin: 5px 50px 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

       @media (max-width: 575.98px) {
        margin: 5px 0 0;
      }

      .categoryChip {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 14px;
        border: 1px solid #9CB4C2;
        border-radius: 20px;
        margin-left: 12px;
        cursor: pointer;
        white-space: nowrap;
        margin-top: 21px;

        span {
          font-family: 'Inter', serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }
      }

      .selectedCategoryChip {
        color: white;
        background-color: #0FDAB5;
        border: none;
      }
    }

    .postsByCategoriesWrapper {
      margin-top: 60px;

       @media (max-width: 575.98px) {
        margin-top: 0;
      }
    }
  }
}
