@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    max-width: calc(1275px + 90px);
    padding: 0 45px;
    margin: 0 auto;

    color: #3d536c;
    position: relative;

    @media (max-width: 575.98px) {
        width: 100%;
        padding: 0 16px 16px;
    }

    @media (max-width: 767.98px) {
        padding: 0 20px 16px;
    }

    .blurBox {
        background: linear-gradient(180deg, #337bff 0%, #67b7f4 53.12%, rgba(61, 200, 208, 0) 100%);
        opacity: 0.2;
        position: absolute;
        left: 0;
        right: 0;
        height: 300px;
    }

    .header {
        margin: 72px 0 0;
        color: #3d536c;

        display: flex;
        align-items: baseline;
        flex-direction: column;

        filter: blur(0);

        @media (max-width: 575.98px) {
            margin: 26px 0 0;
        }

        .category {
            @media (max-width: 575.98px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .categoryWrapper {
                display: flex;
                align-items: center;

                .backButton {
                    &:hover {
                        color: #337bff;
                    }
                }

                .category {
                    font-family: 'Inter', serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    margin-left: 9px;
                }
            }

            .shareLinkIcon {
                margin-left: 10px;
                background-color: #3d536c;
                color: white;
                font-size: 23px;
                border-radius: 50%;
                padding: 4px;
                margin-bottom: -3px;
                cursor: pointer;

                &:hover {
                    background-color: lighten(#3d536c, 20%);
                }

                @media screen and (min-width: 576px) {
                    display: none;
                }
            }
        }

        .headerWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @media (max-width: 575.98px) {
                margin-top: 23px;
            }

            .postHeader {
                font-family: $font-koho;
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                line-height: 110%;
                max-width: 897px;
                margin-top: 16px;
                margin-right: 10px;

                @media (max-width: 575.98px) {
                    font-size: 25px;
                }
            }

            .shareLinkIcon {
                background-color: #3d536c;
                color: white;
                font-size: 30px;
                border-radius: 50%;
                padding: 4px;
                margin-bottom: -3px;
                cursor: pointer;

                &:hover {
                    background-color: #337bff;
                }

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            .socialsWrapper {
                display: none;

                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 16px;
                }

                .firstSocial {
                    margin-left: 0;
                }
            }
        }

        .authorWrapper {
            display: flex;
            margin-top: 16px;
            align-items: center;

            .authorPhoto {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: linear-gradient(253.28deg, #ffcedb 0%, #de97ef 50.43%, #6a82fb 98.81%);
                object-fit: cover;

                @media (max-width: 575.98px) {
                    width: 36px;
                    height: 36px;
                }
            }

            .authorName {
                font-family: 'Inter', serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                margin-left: 8px;

                @media screen and (min-width: 450px) {
                    font-size: 14px;
                }
            }
        }
    }

    .postWrapper {
        margin: 30px 0 0;

        .mainLine {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            cursor: pointer;

            @media (max-width: 991.98px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 40px;
            }
            @media (max-width: 575.98px) {
                gap: 0;
            }

            .titleMainLineWrapper {
                @media screen and (min-width: 1000px) {
                    height: 352px;
                    display: flex;
                    flex-direction: column;
                    margin-left: 30px;
                }

                @media (max-width: 575.98px) {
                    margin-top: 30px;
                }

                .titleMainLine {
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 150%;

                    @media (max-width: 575.98px) {
                        margin-top: 30px;

                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 140%;
                    }
                }
            }

            .image {
                width: 100%;
                background: linear-gradient(253.28deg, #ffcedb 0%, #de97ef 50.43%, #6a82fb 98.81%);
                box-shadow: 0 0 10px rgba(6, 102, 246, 0.1);
                border-radius: 12px;
                object-fit: cover;

                @media (max-width: 575.98px) {
                    width: 100%;
                    min-width: 100%;
                }
            }

            .titleWrapper {
                display: flex;
                align-items: center;

                .title {
                    font-family: 'Inter', serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 150%;
                    margin-left: 52px;

                    @media (max-width: 575.98px) {
                        margin-left: 0;
                        margin-top: 30px;
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 140%;
                    }
                }
            }
        }
    }

    .contentWrapper {
        margin: 30px auto;
        max-width: 919px;

        @media (max-width: 575.98px) {
            width: 100%;
            margin: 36px auto;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 28px;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
            line-height: 42px;
            margin: 22px 0 0;

            @media (max-width: 575.98px) {
                margin: 25px 0 0 0;
                font-weight: 400;
                font-size: 20px;
                line-height: 140%;
            }
        }

        h2 {
            font-size: 25px;

            @media (max-width: 575.98px) {
                font-size: 18px;
            }
        }

        h3 {
            font-size: 22px;

            @media (max-width: 575.98px) {
                font-size: 16px;
            }
        }

        h4 {
            font-size: 19px;

            @media (max-width: 575.98px) {
                font-size: 14px;
            }
        }

        h5 {
            font-size: 16px;

            @media (max-width: 575.98px) {
                font-size: 12px;
            }
        }

        h6 {
            font-size: 13px;

            @media (max-width: 575.98px) {
                font-size: 10px;
            }
        }

        p {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin: 22px 0 0;

            @media (max-width: 575.98px) {
                margin: 25px 0 0 0;
                font-size: 14px;
                line-height: 140%;
            }
        }

        a:link,
        a:visited,
        a:hover,
        a:active {
            text-decoration: none;
            color: inherit;

            u {
                text-decoration: none;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: #3d536c;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                }
            }
        }

        img {
            width: 100%;
            border-radius: 12px;
            object-fit: cover;
            margin: 22px 0 0;

            background: linear-gradient(253.28deg, #ffcedb 0%, #de97ef 50.43%, #6a82fb 98.81%);

            @media (max-width: 575.98px) {
                margin: 25px 0 0 0;
            }
        }

        blockquote {
            position: relative;
            padding: 10px 0 10px 40px;
            border-left: 2px solid #3d536c;
            margin: 22px 0;
            quotes: '“' '”' '‘' '’';

            @media (max-width: 575.98px) {
                margin: 25px 0 0 0;
                font-size: 20px;
                line-height: 140%;
                padding: 10px 0 10px 25px;
            }

            p {
                font-family: 'Inter', sans-serif;
                font-style: italic;
                font-weight: 400;
                font-size: 28px;
                line-height: 150%;
                position: relative;
                margin-top: 0;

                &::before {
                    content: open-quote;
                }

                &::after {
                    content: close-quote;
                }

                @media (max-width: 575.98px) {
                    font-size: 20px;
                    line-height: 140%;
                }
            }
        }

        table {
            margin: 22px;

            @media (max-width: 575.98px) {
                margin: 36px 0;
                display: block;
                overflow-x: auto;
                white-space: nowrap;
            }
        }

        table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }

        td {
            padding: 5px;
        }
    }
}

.recommendations {
    margin: 65px 0 0;

    .header {
        margin: 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #3d536c;

        h5 {
            font-family: 'Inter', serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #3d536c;
        }
    }
}

.featuredPosts {
    margin: 70px 0 35px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media (max-width: 575.98px) {
        margin: 0 0 36px;
        gap: 5px;
    }
    @media (max-width: 1191.98px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767.98px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .postPreviewSmall {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        @media (max-width: 575.98px) {
            margin-left: 0;
            width: 100%;
            min-width: 100%;
            height: initial;
            margin-top: 36px !important;

            &:first-child {
                margin-top: 0;
            }
        }

        &:first-child,
        &:nth-child(1n + 4) {
            margin-left: 0;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 0;
        }

        .image {
            width: 100%;
            height: 224px;

            background: linear-gradient(253.28deg, #ffcedb 0%, #de97ef 50.43%, #6a82fb 98.81%);
            box-shadow: 0 0 10px rgba(6, 102, 246, 0.1);
            border-radius: 12px;

            @media (max-width: 575.98px) {
                width: 100%;
                min-width: 100%;
            }

            @media (max-width: 1391.98px) {
                height: 210px;
            }

            @media (max-width: 1341.98px) {
                height: 195px;
            }

            @media (max-width: 1291.98px) {
                height: 180px;
            }

            @media (max-width: 1241.98px) {
                height: 170px;
            }

            @media (max-width: 1191.98px) {
                height: 275px;
            }

            @media (max-width: 1141.98px) {
                height: 262px;
            }

            @media (max-width: 1091.98px) {
                height: 248px;
            }

            @media (max-width: 1041.98px) {
                height: 241px;
            }

            @media (max-width: 991.98px) {
                height: 234px;
            }

            @media (max-width: 941.98px) {
                height: 222px;
            }

            @media (max-width: 891.98px) {
                height: 210px;
            }

            @media (max-width: 817.98px) {
                height: 180px;
            }

            @media (max-width: 767.98px) {
                height: 100%;
            }
        }

        .category {
            margin-top: 25px;
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;

            @media (max-width: 575.98px) {
                margin-top: 15px;
            }
        }

        .title {
            font-family: $font-koho;
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 120%;

            @media (max-width: 575.98px) {
                margin-top: 5px;
            }
        }

        .description {
            margin-top: 16px;
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            @media (max-width: 575.98px) {
                display: none;
            }
        }
    }
}

.cursorPointer {
    cursor: pointer;
}
