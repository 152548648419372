@import 'src/styles/mixins';

.container {
    @include display-flex($align: center, $justify: space-between, $direction: column, $grow: 1);
    width: 100%;

    .apiList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 25px;
        row-gap: 40px;
        width: 100%;
        max-width: 1330px;
        padding: 70px 30px 95px;
    }
}
