@import 'src/styles/variables';
@import 'src/styles/mixins';

.content {
    background-color: #D7E4F4;
    padding: 16px;
    border-radius: 16px;

    .headSection {
        @include display-flex($align: center, $justify: space-between);

        .textSection {
            @include display-flex($align: center);

            .infoIcon {
                margin: 0 8px;
            }

            & > h4 {
                @include font-style($size: 18px, $bold: 700, $color: #1F3B82);

                .titleLink {
                    @include font-style($size: 18px, $bold: 500, $color: #1F3B82);
                }
            }

            & > h5 {
                @include font-style($size: 12px, $bold: 450, $color: $fiord);
            }
        }
    }

    .gridSection {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        margin-top: 20px;

        @media (max-width: 900px) {
            grid-template-columns: 1fr;
        }
    }

    .tableWrapper {
        margin-top: 20px;
        border-radius: 6px;
        border: 1px solid #B0C2CD;

        .tableContainer {
            background-color: $white;
            border-radius: 6px;
        }
    }

    .loaderBox {
        position: static;
        padding: 20px 0;
    }

    @media (max-width: 900px) {
        .tableWrapper {
            overflow-x: auto;
        }

        .tableContainer {
            min-width: 900px;
        }
    }
}
