@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalContainer {
    @include display-flex($align: center, $justify: center);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);

    .modal {
        @include display-flex($direction: column);
        width: 500px;
        padding: 30px;
        background-color: $white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;
        overflow: auto;

        &Header {
            @include display-flex($align: flex-start, $justify: space-between);
            background-color: $white;

            .closeButton {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-left: auto;
                min-width: unset;
                margin-right: -8px;

                svg {
                    font-size: 25px;
                    color: $fiord;
                }
            }
        }

        &Body {
            margin-top: 30px;

            .cropContainer {
                position: relative;
                width: 100%;
                height: 300px;
            }

            .controls {
                margin: 20px 0;
            }

            .uploadButtonContainer {
                @include display-flex($justify: flex-end);

                .uploadButton {
                    @include font-style($size: 16px, $bold: 400, $color: $white, $transform: none);
                    background-color: $dodger-blue-darker;
                }
            }
        }
    }
}
