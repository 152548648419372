@import 'src/styles/variables';
@import 'src/styles/mixins';

.graphWrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 24px;
    padding: 0 10px;

    & > div {
        background-color: #D7E4F4;
        padding: 16px;
        border-radius: 16px;
        overflow-x: scroll;
    }

    .header {
        @include display-flex($align: center);
        padding: 0 0 10px 0;

        .infoIcon {
            margin: 0 8px;
        }

        .title {
            @include font-style($size: 18px, $bold: 700, $color: #1F3B82);

            @media (max-width: 767.98px) {
                font-size: 16px;
            }
        }
    }

    .proposalsGraphCard {
        &Wrapper {
            @include display-flex($direction: column);
            height: 295px;
            border-radius: 6px;
            border: 1px solid #B0C2CD;
            background-color: $white;
            overflow: hidden;
            position: relative;

            .loaderBox {
                position: absolute;
                bottom: 40%;
            }

            .wrapper {
                @include display-flex($direction: column);
                height: 100%;

                .graphInfoContent {
                    @include display-flex($justify: space-between, $align: center);
                    padding: 10px 14px 0;

                    .date {
                        @include font-style($size: 12px, $bold: 400, $color: $nepal);

                        @media (max-width: 767.98px) {
                            font-size: 14px;
                        }
                    }

                    .barColoringInfo {
                        span {
                            @include font-style($size: 10px, $bold: 700, $color: $nepal);
                            position: relative;
                        }

                        .proposalsColoring {
                            &::before {
                                content: '';
                                position: absolute;
                                left: -15px;
                                top: 2px;
                                width: 10px;
                                height: 10px;
                                background: $dodger-blue-darker;
                            }
                        }

                        .votesColoring {
                            margin-left: 30px;

                            &::before {
                                content: '';
                                position: absolute;
                                left: -15px;
                                top: 2px;
                                width: 10px;
                                height: 10px;
                                background: #4DD3FF;
                            }
                        }
                    }
                }
            }
        }
    }

    .notFoundWrapper {
        background-color: $wisp-pink;
    }

    @media (max-width: 767.98px) {
        grid-template-columns: 1fr;
        padding: 0 10px 0 10px;
    }
}
