@import 'src/styles/variables';
@import 'src/styles/mixins';

.tokensVotersWrapper {
    display: none;
    background-color: $white;
    padding: 6px 0;
    box-shadow: 0px 0px 10px rgba(61, 83, 108, 0.45);
    border: 2px solid $dodger-blue-darker;
    border-radius: 4px;
    position: absolute;
    bottom: 100%;
    left: -20px;
    z-index: 3;
    max-height: 250px;
    overflow-y: scroll;

    .voterInfo {
        @include display-flex($align: center);
        width: 230px;
        padding: 8px 10px;
        transition: background-color 0.3s;
        cursor: pointer;

        &:hover {
            background-color: $aliceblue;

            .name {
                color: $dodger-blue-darker;
            }
        }

        .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .name {
            @include font-style($size: 13px, $bold: 500, $color: $fiord);
            margin-left: 8px;
            transition: 0.3s color;
        }
    }
}
