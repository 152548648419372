@import 'src/styles/variables';
@import 'src/styles/mixins';

.loggedUserOptions {
    width: 160px;
    position: absolute;
    background-color: $white;
    border-radius: 4px;
    right: -75px;
    top: 40px;
    transform: translate(-50%, 10px);
    padding: 4px 16px;
    border: 2px solid $dodger-blue-darker;
    z-index: 4;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 8px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 9px solid $dodger-blue-darker;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        right: 10px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid $white;
    }

    .optionWrapper,
    .logoutOptionWrapper {
        cursor: pointer;
        transition: background-color 0.2s;
        padding: 10px 0;
        border-bottom: 1px solid $nepal;

        &:last-child {
            border-bottom: none;
        }

        .optionName {
            @include font-style($size: 13px, $bold: 400, $color: $fiord);
            transition: color 0.3s;
        }

        &:hover {
            .optionName {
                color: $dodger-blue-darker;
            }
        }
    }

    .inactiveOptionWrapper {
        @include display-flex($align: center, $justify: space-between);
        padding: 10px 0;
        border-bottom: 1px solid $nepal;

        &:last-child {
            border-bottom: none;
        }

        .commingSoon {
            @include display-flex($align: center, $justify: center);
            padding: 2px 6px;
            background-color: $nepal;
            border-radius: 12px;

            .text {
                @include font-style($size: 7px, $bold: 700, $color: $white);
            }
        }

        .optionName {
            @include font-style($size: 13px, $bold: 400, $color: $fiord);
            transition: color 0.3s;
        }
    }

    .logoutOptionWrapper {
        @include display-flex($align: center);

        .logoutIcon {
            @include font-style($size: 20px, $color: $fiord);
            transition: color 0.3s;
            margin-left: -2px;
        }

        .logoutText {
            @include font-style($size: 9px, $bold: 400, $color: $fiord);
            transition: color 0.3s;
            margin-left: 4px;
        }

        &:hover {
            .logoutText,
            .logoutIcon {
                color: $dodger-blue-darker;
            }
        }

        @media (max-width: 767.98px) {
            border-top: 1px solid $heather;
            padding-top: 26px;
            margin-top: 16px;

            .logoutText {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 991.98px) {
        right: -78px;
    }

    @media (max-width: 767.98px) {
        padding-left: 10px;
        padding-right: 10px;
        top: -10px;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }

        .optionWrapper,
        .inactiveOptionWrapper {
            border-bottom: none;

            .optionName {
                font-size: 16px;
            }
        }
    }
}
