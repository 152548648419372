@import 'src/styles/variables';
@import 'src/styles/mixins';

.selectWrapper {
    position: relative;
    width: 150px;

    .select {
        @include display-flex($justify: space-between, $align: center);
        width: 100%;
        height: 30px;
        padding: 0 12px;
        border: 2px solid $nepal;
        border-radius: 20px;
        cursor: pointer;

        .selectTitle {
            @include font-style($size: 12px, $bold: 500, $color: $fiord);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .selectToggleIcon {
            width: 10px;
            height: 5px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $fiord;
        }
    }

    .selectActive {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .selectItems {
        width: 100%;
        background-color: $white;
        position: absolute;
        z-index: 5;
        top: 30px;
        left: 0;
        border: solid $nepal;
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 4px 4px 12px;
        visibility: hidden;
        opacity: 0;
    }

    .selectItemsOpen {
        visibility: visible;
        opacity: 1;
    }

    .itemWrapper {
        @include display-flex($align: center);
        margin-bottom: 4px;
        padding: 6px 5px;
        cursor: pointer;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            background-color: $ghostwhite;
        }

        .checkbox {
            width: 16px;
            height: 16px;
            color: $nepal !important;
            margin-right: 8px;

            span {
                cursor: default;
            }

            input {
                cursor: pointer;
            }

            &:hover {
                background-color: transparent !important;
            }
        }

        .checkboxChecked {
            color: $fiord !important;
        }

        .checkboxDisabled {
            color: $heather !important;
        }

        .itemName {
            @include font-style($size: 12px, $bold: 400, $color: $fiord);
        }

        .itemNameActive {
            font-weight: 700;
        }

        .itemNameDisabled {
            color: $heather;
        }
    }

    .itemWrapperDisabled {
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    }
}
