@import 'src/styles/variables';
@import 'src/styles/mixins';

.statusWrapper {
    @include display-flex($align: center, $justify: center);
    width: 74px;
    height: 34px;
    border-radius: 4px;

    .status {
        @include font-style($size: 14px, $bold: 700);
    }
}

.statusActive {
    color: $java;
    background-color: $foam;
}

.statusDone {
    color: $regent-gray;
    background-color: $zircon;
}

.statusPending {
    color: $froly;
    background: rgba(242, 131, 138, 0.15);
}

.feedStatus {
    width: 78px;
    height: 28px;
    margin-left: auto;
    margin-bottom: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @media (max-width: 575.98px) {
        height: 20px;
        width: 55px;
        margin: 0 10px;
        border-radius: 2px;

        .status {
            font-size: 10px;
        }
    }
}

.feedProposalStatus {
    width: 95px;
}

.removeForMobile {
    @media (max-width: 575.98px) {
        display: none;
    }
}
