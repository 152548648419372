@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    width: 100%;
    margin-top: 25px;

    .rowsWrapper {
        & > div:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}
