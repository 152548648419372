@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardWrapper {
    margin-top: 15px;

    .cardContainer {
        @include display-flex($align: center, $justify: space-between);
        padding: 18px 20px 18px 25px;
        background-color: $white;
        border: 1px solid $catskill-white;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
        border-radius: 6px;
        transition: box-shadow 0.3s;

        .topicName {
            @include font-style($size: 26px, $bold: 400, $color: $fiord, $family: $font-quicksand);
        }

        .expandMoreBtn {
            width: 30px;
            height: 30px;
            min-width: unset;
            background-color: $link-water-whiter;
            border-radius: 3px;

            & svg {
                @include font-style($size: 30px, $color: $dodger-blue-darker);
            }
        }

        &:hover {
            box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.4);
        }
    }

    .topicQuestionsContainer {
        @include display-flex($align: flex-start, $direction: column);
        padding: 25px 25px 10px;

        & > a:first-child {
            margin-top: 0;
        }

        .questionLink {
            @include font-style($size: 15px, $bold: 400, $color: $fiord);
            margin-top: 15px;
            transition: color 0.3s;

            &:hover {
                color: $dodger-blue-darker;
            }
        }
    }
}
