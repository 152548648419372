@import 'src/styles/variables';
@import 'src/styles/mixins';

.feedHeader {
    @include display-flex($align: center);
    width: 100%;
    min-height: 120px;
    padding: 20px;
    background: linear-gradient(180deg, $dodger-blue-darker 14.51%, $darkturquoise 94.76%);

    .contentWrapper {
        @include display-flex($align: center);
        width: 100%;
        max-width: 1270px;
        margin: 0 auto;

        .link {
            @include display-flex($align: center);
            cursor: pointer;
            color: $white;

            .arrowIcon {
                font-size: 20px;
                transform: rotate(180deg);
                transition: transform ease-out 0.25s;
            }

            .goBackText {
                @include font-style($size: 14px, $bold: 700);
                margin-left: 8px;
            }

            &:hover {
                .arrowIcon {
                    transform: translateX(-10px) rotate(180deg);
                }
            }
        }

        .divider {
            width: 1px;
            height: 58px;
            background-color: $white;
            margin-left: 20px;
            transform: rotate(15deg);
        }

        .feedInfo {
            @include display-flex($align: center);
            margin-left: 20px;

            .organizationImage {
                width: 55px;
                height: 55px;
                border-radius: 50%;
                border: 4px solid $white;
            }

            .organizationName {
                @include font-style($size: 34px, $bold: 400, $color: $white, $family: $font-quicksand);
                overflow-wrap: anywhere;
                margin-left: 15px;
            }
        }
    }

    @media (max-width: 767.98px) {
        align-items: flex-start;
        padding: 0;

        .contentWrapper {
            flex-direction: column;
            align-items: flex-start;

            .divider {
                display: none;
            }

            .link {
                @include display-flex($align: center);
                width: 100%;
                height: 46px;
                border-bottom: 1px solid rgba($white, 0.5);

                .goBackText {
                    display: none;
                }

                .arrowIcon {
                    margin-left: 16px;
                    font-size: 26px;
                }
            }

            .feedInfo {
                width: 100%;
                margin-left: 0;
                padding: 20px 40px;
            }
        }
    }
}
