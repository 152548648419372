@import 'src/styles/variables';
@import 'src/styles/mixins';

.categoriesWrapper {
    @include display-flex($justify: center);
    background-color: $lavender-grey;

    .categoriesContainer {
        @include display-flex($align: center, $justify: center, $wrap: wrap);
        max-width: 1240px;
        padding: 14px 16px 26px;

        .categoryButton {
            @include display-flex($align: center, $justify: center);
            @include font-style($size: 12px, $color: $fiord, $bold: 500, $transform: none);
            background-color: $white;
            border-radius: 20px;
            margin: 12px 6px 0;
            padding: 4px 14px;
            height: 30px;

            &:hover {
                background-color: $lightseagreen;
                border-color: $lightseagreen;
                color: $white;
            }

            @media (max-width: 991.98px) {
                height: 26px;
            }
        }

        .showAllCategoriesButton {
            background-color: rgba(255, 255, 255, 0.4);
            border: 2px solid $white;
        }

        .categorySelected {
            background-color: $bright-turquoise;
            border-color: $bright-turquoise;
            color: $white;

            @media (max-width: 991.98px) {
                &:hover {
                    background-color: $bright-turquoise;
                }
            }
        }

        @media (max-width: 991.98px) {
            padding-top: 8px;
            padding-bottom: 20px;
        }
    }
}
