@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include max-device-width();
    @include display-flex($align: flex-start, $justify: center, $direction: column);

    .container {
        @include display-flex($align: center, $justify: center, $direction: column);
        padding: 50px 16px;
        overflow-x: hidden;

        .pdfWrapper {
            @include display-flex($align: center, $justify: center, $direction: column);
            overflow: hidden;

            & > div:first-child {
                & > div:last-child {
                    display: none;
                }
            }

            .pageWrapper {
                & > canvas {
                    max-width: 100%;
                    height: auto !important;
                }
            }
        }
    }
}
