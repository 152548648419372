@import 'src/styles/variables';

.itemContainer {
    display: flex;
    border-bottom: 1px solid $catskill-white;
    padding: 11px 14px;
    background: $white;
    color: rgba(84, 92, 97, 1);
    position: relative;

    & > div {
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
        }
    }

    .rank {
        display: flex;
        width: 6%;
        font-weight: 700;

        span {
            background: $polar;
            padding: 5px 12px;
        }
    }

    .tokenInfo {
        width: 30%;
        font-weight: 700;
        overflow: hidden;

        .tokenLogo {
            width: 28px;
            height: 28px;
        }

        .tokenName {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 10px;
        }
    }

    .symbol {
        width: 25%;
    }

    .balance {
        width: 25%;

        .textContainer {
            display: flex;
            padding: 5px 10px;
            align-items: center;
            align-content: center;
            background: $polar;
            border-radius: 2px;

            .sum {
                font-weight: 700;
            }
        }

        svg {
            width: 20px;
            transform: rotate(45deg);
            color: $java;
            margin-right: 5px;
            margin-left: -5px;

            &.down {
                transform: rotate(135deg);
                color: $wild-watermelon;
            }
        }
    }

    .people {
        width: 14%;
    }

    @media (max-width: 767.98px) {
        .rank {
            width: 10%;
        }

        .tokenInfo {
            width: 30%;
        }

        .symbol {
            width: 18%;
        }

        .balance {
            width: 23%;
        }

        .organizations {
            width: 19%;
        }
    }
}
