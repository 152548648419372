@import 'src/styles/mixins';

.linksContainer {
    @include display-flex($align: center);
    margin-left: 30px;

    .socialLink {
        width: 24px;
        height: 24px;
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }

        .socialIcon {
            width: 24px;
            height: 24px;
        }
    }
}
