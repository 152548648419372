@import 'src/styles/variables';
@import 'src/styles/mixins';

.timeframe {
    @include display-flex($align: center, $justify: center, $direction: column);
    padding: 6px 10px;
    height: 75px;
    background-color: $ghostwhite;
    border-radius: 0px 0px 4px 4px;

    &Info {
        @include display-flex($align: center, $justify: center, $direction: column);

        .timeLeft {
            @include display-flex($justify: space-between);
            width: 100%;

            .dateBlock {
                @include display-flex($align: center, $justify: center, $direction: column);
                margin-top: 10px;

                &:last-child {
                    .timeBlock {
                        &::before {
                            content: '';
                        }
                    }
                }

                .timeBlock {
                    @include display-flex($align: center, $justify: center);
                    @include font-style($size: 14px, $color: $dodger-blue-darker, $bold: 700);
                    background-color: $pattens-blue;
                    width: 30px;
                    height: 20px;
                    position: relative;

                    &::before {
                        content: ':';
                        position: absolute;
                        color: $dodger-blue-darker;
                        right: -10px;
                    }
                }

                .period {
                    @include font-style($size: 10px, $color: $dodger-blue-darker, $bold: 400);
                    margin-top: 4px;
                }
            }
        }

        & > p {
            @include font-style($size: 10px, $color: $regent-gray, $bold: 700, $transform: uppercase);
        }

        & > h5 {
            @include font-style(
                $size: 14px,
                $color: $dodger-blue-darker,
                $bold: 700,
                $transform: capitalize,
                $lineHeight: 21px,
                $align: center
            );
            margin-top: 2px;
            white-space: nowrap;
        }
    }
}

.discussionFinished {
    height: 56px;
    justify-content: flex-end;

    .timeframeInfo {
        & > h5 {
            font-size: 10px;
            line-height: 12px;
        }

        & > h5 {
            color: $regent-gray;
        }
    }

    @media (max-width: 575.98px) {
        max-width: 95px;
    }
}
