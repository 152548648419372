@import 'src/styles/mixins';
@import 'src/styles/variables';

.userAbout {
    &Container {
        margin-bottom: 30px;
        padding: 0 10px;

        .headingSection {
            @include display-flex($align: center);
            padding: 6px 16px;

            .title {
                @include font-style($size: 14px, $bold: 600, $color: $fiord);

                @media (max-width: 767.98px) {
                    font-size: 16px;
                }
            }

            .showMoreButton {
                @include font-style($size: 14px, $bold: 700, $color: $dodger-blue-darker);
                min-width: unset;
                width: 30px;
                height: 30px;
                margin-right: 10px;
                background-color: $athens-gray-darker;
                text-transform: none;

                .dropIcon {
                    @include font-style($size: 30px, $color: $malibu-lighter);
                }
            }

            .showMoreButtonOpen {
                transform: rotate(180deg);
            }

            @media (max-width: 575.98px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .loggedUserOptions {
            margin-top: 0 !important;
        }

        @media (max-width: 767.98px) {
            margin-bottom: 35px;
        }
    }

    &Body {
        background-color: $white;
        border-radius: 6px;
        margin-top: 5px;
        box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);

        .hashtagsContainer {
            @include display-flex($justify: space-between, $align: center);

            .title {
                @include font-style($size: 12px, $bold: 600, $color: $fiord);
                margin: 0;
            }

            .hastagsList {
                @include display-flex($wrap: wrap);
                gap: 5px;
            }
        }

        .hashtagsContainerFlexEnd {
            justify-content: flex-end;
        }

        .moreInfo {
            padding: 16px;
            border-top: 1px solid $catskill-white;

            .emptyFields {
                display: none;
            }

            &Block {
                margin-top: 30px;
            }
            &Title {
                @include font-style($size: 14px, $bold: 700, $color: $nepal);
                margin-right: 10px;

                @media (max-width: 767.98px) {
                    font-size: 16px;
                }

                &.intro {
                    margin-bottom: 8px;
                }
            }
            &Text {
                @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 21px);
            }

            .skills {
                @include display-flex($direction: column, $align: flex-start);

                &List {
                    @include display-flex($wrap: wrap);
                }
            }

            .social {
                @include display-flex($align: center);

                &List {
                    @include display-flex($wrap: wrap);
                    gap: 5px;
                }
            }

            @media (max-width: 575.98px) {
                padding: 0 10px 10px;
                border-top: none;
            }
        }
    }
    &FallbackText {
        @include font-style($size: 14px, $bold: 700, $color: $nepal);
    }
}
