@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    display: flex;
    align-items: center;
    padding: 11px 25px 13px;
    margin-right: 30px;

    & > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: $fiord;

        .avatar {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }
    }

    .rank {
        width: 10%;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $polar;
            font-size: 14px;
            font-weight: 700;
            padding: 8px 12px;
            border-radius: 2px;
        }
    }

    .token {
        width: 32%;

        .tokenInfo {
            @include display-flex($direction: column);
            margin-left: 8px;
            overflow: hidden;
            padding-right: 20px;

            .tokenName {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .tokenBalance {
                font-size: 10px;
                font-weight: 500;
                margin: 0;
                margin-top: 2px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .currencyValue {
        display: flex;
        width: 38%;

        .value {
            background-color: $polar;
            padding: 8px 12px;
            border-radius: 2px;

            & > div {
                max-width: 110px;
                overflow: hidden;
                text-overflow: ellipsis;

                & > span {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }

        .percentage {
            font-size: 14px;
            font-weight: 400;
            margin-left: 12px;
        }
    }

    .balance {
        width: 20%;
        overflow: hidden;

        .tokenBalanceValue {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    @media (max-width: 767.98px) {
        padding-left: 8px;
        padding-right: 8px;
    }
}
