@import 'src/styles/variables';
@import 'src/styles/mixins';

.opportunitiesOptions {
    @include display-flex($align: center, $justify: center);
    position: relative;

    .opportunitiesButton {
        @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
        width: 124px;
        height: 34px;
        background-color: $dodger-blue-darker;
        border: 2px solid $white;

        &:hover {
            background-color: $dodger-blue-darker;
        }
    }

    .opportunitiesWrapper {
        width: 270px;
        height: 63px;
        background-color: $white;
        position: absolute;
        bottom: -80px;
        border-radius: 4px;
        border: 2px solid $dodger-blue-lighter;
        box-shadow: 0px 4px 10px rgba(64, 107, 179, 0.25);
        z-index: 2;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 123px;
            bottom: 100%;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid $dodger-blue-lighter;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 126px;
            bottom: 100%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid $white;
        }

        .opportunitiesContainer {
            @include display-flex();
            height: 100%;
            padding: 15px;

            .title {
                @include font-style($size: 14px, $bold: 700, $color: $fiord);
                white-space: nowrap;
            }

            .inputWrapper {
                flex-grow: 1;
                margin-top: -2px;
                margin-left: 10px;

                .input {
                    @include font-style($size: 14px, $color: $fiord);
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid $heather;
                    padding: 0;
                    padding-bottom: 5px;

                    &:focus {
                        outline: none;
                        border-color: $dodger-blue-darker;
                    }
                }

                & > p {
                    @include font-style($size: 10px, $bold: 500, $color: $heather);
                    text-align: right;
                }
            }
        }
    }
}
