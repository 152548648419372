@import 'src/styles/variables';
@import 'src/styles/mixins';

.rowWrapper {
    @include display-flex($justify: space-between);
    width: 100%;

    .logoWrapper {
        @include display-flex($align: center);
        padding: 0 20px;

        & > img {
            height: auto;
            width: 100%;
        }
    }

    @media (max-width: 767.98px) {
        .logoWrapper {
            padding: 0 10px;
        }
    }
}
