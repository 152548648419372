@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 8px 12px;
    background-color: $white;
    box-shadow: 0 0 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;

    & > div {
        @include display-flex($align: center);
        @include font-style($size: 12px, $bold: 400, $color: $fiord);
    }

    &:hover {
        background: $polar-white;
        box-shadow: 0 0 10px rgba(64, 107, 179, 0.2);
    }

    .rank {
        width: 6%;

        span {
            @include display-flex($align: center, $justify: center);
            @include font-style($size: 14px, $bold: 700);
            background-color: $polar;
            padding: 8px 12px;
            border-radius: 2px;
        }
    }

    .members {
        width: 28%;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .userInfo {
            margin-left: 8px;
            padding-right: 40px;
            overflow: hidden;

            .userName {
                @include font-style($size: 14px, $bold: 700, $lineHeight: 21px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .description {
                @include font-style($size: 12px, $bold: 400, $color: $regent-gray, $lineHeight: 15px);
                margin-top: 1px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .type {
        width: 12%;

        & > span {
            font-size: 14px;
            font-weight: 700;
        }
    }

    .participationScore {
        width: 18%;
    }

    .daos {
        width: 12%;

        & > span {
            @include display-flex($align: center, $justify: center);
        }
    }

    .proposals {
        width: 12%;

        & > div {
            @include display-flex($align: center, $justify: center);

            & > span {
                margin-left: 5px;
            }

            .proposalIcon {
                width: 13px;
                height: 16px;
            }
        }
    }

    .votes {
        width: 12%;

        & > div {
            @include display-flex($align: center, $justify: center);

            & > span {
                margin-left: 5px;
            }

            .votesIcon {
                width: 13px;
                height: 16px;
            }
        }
    }
}
