@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@300;400;500;600;700&display=swap');

$font-inter: 'Inter', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-manrope: 'Manrope', sans-serif;
$font-quicksand: 'Quicksand', sans-serif;
$font-inconsolata: 'Inconsolata', sans-serif;
$font-itim: 'Itim', sans-serif;
$font-koho: 'KoHo', sans-serif;

$header-height: 100px;
$header-tablet-height: 100px;
$header-mobile-height: 62px;

$sidebar-width: 70px;
$expanded-sidebar-width: 180px;

$white: #fff;
$geraldine: #ff8087;
$heliotrope: #9069ff;
$heliotrope-darker: #705cef;
$java: #1bcccc;
$regent-gray: #798d99;
$foam: #ddfcfc;
$zircon: #f1f6ff;
$athens-gray: #f0f3f5;
$dodger-blue: #46bcff;
$fiord-darker: #545c61;
$heliotrope-lighter: #8f6cff;
$nepal: #91afbf;
$catskill-white: #eaeef5;
$sail: #b5d9f7;
$botticelli: #c5d3e3;
$malibu: #73c1ff;
$polar: #dbf7f7;
$polar-light: #e9fafa;
$heather: #becdd7;
$fog: #dacfff;
$fog-lighter: #e8eafa;
$wild-watermelon: #ff646d;
$turquoise: #35d0d1;
$polar-white: #f4fdfd;
$selago: #ebeefc;
$blue-gem: #3916ac;
$silver-sand: #b4b8ba;
$white-lilac: #e4e6f5;
$mystic: #dee8ee;
$daisy-bush: #551a8b;
$indigo: #4e5dcc;
$indigo-lighter: #406bb3;
$mystic-lighter: #ecf0f3;
$scooter: #2db1db;
$puerto-rico: #48bfaa;
$link-water: #d3e6f1;
$gull-gray: #91a4b1;
$dodger-blue-darker: #6f7cde;
$snuff: #d9d1e8;
$lavender-magenta: #f283ea;
$vivid-tangerine: #ff9e80;
$shamrock: #35cb83;
$darkslategray: #4b4a4a;
$geyser: #c8d5dd;
$tomato: #f44335;
$link-water-whiter: #e8eafa;
$mystic-darker: #dce6ed;
$gallery: #ededed;
$athens-gray-darker: #f8f9fb;
$solitude: #e0ebff;
$turquoise: #43cccc;
$botticelli-lighter: #d4ddec;
$dodger-blue-darker: #337bff;
$bright-turquoise: #0fdab5;
$cornflower-blue: #4c7ffc;
$fiord: #3d536c;
$dodger-blue-lighter: #2b75fd;
$pattens-blue: #deeaff;
$white-ice: #e2fbf6;
$malibu-lighter: #5d96ff;
$cornflower-blue-darker: #588be8;
$botticelli-darker: #d1dde7;
$cornflowerblue: #67b7f4;
$ghostwhite: #f5f8ff;
$paleturquoise: #b5e3ff;
$cornflowerblue-lighter: #46bcff;
$darkturquoise: #1db3d2;
$aquamarine: #4effdf;
$turquoise-blue: #5de6de;
$cornflower-lilac: #ffabab;
$zumthor: #ebf2ff;
$lightseagreen: #1ec0a3;
$mediumaquamarine: #61d3bf;
$aliceblue: #eaf2ff;
$blizzard-blue: #acdef2;
$lightskyblue: #8db5ff;
$wisp-pink: #fdebec;
$froly: #f2838a;
$lightblue: #b8d3fe;
$periwinkle: #4dd3ff;
$curious-blue: #20aed7;
$lavender-rose: #fcb9f7;
$java-lighter: #1cb9cf;
$light-blue: #b8d6e8;
$aquamarine-blue: #80ffff;
$lavender-lighter: #daebf5;
$lavender-grey: #dde5ee;
$viking: #59c6df;
$eastern-blue: #2794ad;
$lavender-blush: #ffedfe;
$lightcyan: #ddfcfc;
$white-ice-lighter: #e7fbf8;
$wisp-pink-lighter: #fef3fd;
$periwinkle-lighter: #bdd4ff;
$anakiwa: #99beff;
$kashmir-blue: #537092;
$zumthor: #e2ecff;
$black: #000000;
$alice-blue: #f3f6fe;
$bunker: #0d1016;

$left-sidebar-background-color: linear-gradient(180deg, #163a86 19.05%, #498aa2 100%);
$left-sidebar-active-link: linear-gradient(149.62deg, #437df6 24.63%, #52aed2 86.57%);
