@import 'src/styles/variables';
@import 'src/styles/mixins';

.activityInfo {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    row-gap: 40px;
    column-gap: 40px;

    .activityCardsWrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(250px, 1fr));
        column-gap: 40px;

        @media (max-width: 767.98px) {
            grid-template-columns: minmax(200px, 1fr);
            grid-template-rows: 1fr 1fr;
            row-gap: 40px;
        }
    }

    @media (max-width: 1199.98px) {
        grid-template-columns: minmax(200px, 1fr);
        grid-template-rows: 1fr 1fr;
    }

    @media (max-width: 767.98px) {
        row-gap: 0;
    }
}
