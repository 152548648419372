@import 'src/styles/variables';
@import 'src/styles/mixins';

.rowHover {
    background: #CCDEF5;
}

.tableRow {
    height: 70px;
    @include display-flex($align: center);
    @include font-style($size: 14px, $bold: 400, $color: $fiord);
    border-bottom: 1px solid $catskill-white;
    padding: 17px 22px;
    position: relative;

    .number {
        width: 4%;
        font-weight: 700;
    }
}
