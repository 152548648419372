@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;

    .container {
        padding: 60px 20px;

        .link {
            @include display-flex($align: center);
            color: $fiord;

            .arrowIcon {
                @include font-style($size: 14px);
            }

            .text {
                @include font-style($size: 14px, $bold: 400);
                margin-left: 4px;
            }
        }

        .title {
            @include font-style($size: 40px, $bold: 500, $color: $fiord, $family: $font-koho);
            margin-top: 8px;

            & > span {
                text-transform: capitalize;
                font-family: $font-koho;
            }
        }

        .card {
            margin-top: 60px;
            padding: 50px 40px;
            background: $white;
            box-shadow: 0px 0px 30px rgba(0, 102, 246, 0.2);
            border-radius: 25px;

            .headingText {
                @include font-style($size: 20px, $bold: 700, $color: $fiord);
            }

            .benefitsBlock {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 50px;
                row-gap: 50px;
                margin-top: 40px;
            }

            .optionsBlock {
                margin-top: 50px;
                padding-top: 40px;
                border-top: 1px solid $nepal;

                .cancelSubscriptionButton {
                    @include font-style($size: 18px, $bold: 700, $color: $white, $transform: none);
                    width: 214px;
                    height: 44px;
                    background-color: $bright-turquoise;

                    .loader {
                        position: static;
                    }
                }

                .errorText {
                    @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
                    margin-top: 10px;
                }
            }
        }
    }
}
