@import 'src/styles/variables';
@import 'src/styles/mixins';

.treasuryStats {
    background-color: #fff;
    border: 1px solid $lightblue;
    border-radius: 8px;
    overflow: hidden;
    background-color: #68A3FF;
    min-width: 380px;
    min-height: 340px;
    position: relative;

    &__container {
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #337bff 34.12%, #1db3d2 100%);

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            background-color: #68A3FF;
            padding: 10px 20px;

            &__box {
                border: 1px solid #fff;
                padding: 10px;
                background: linear-gradient(180deg, #68A4FE 0%, #6BC7E2 100%);
                border-radius: 6px;
                width: 163px;
                height: 260px;

                &__title {
                    height: 30px;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 10px;
                    white-space: wrap;
                    text-align: center;
                }

                &__progressBar {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &_wrapper {
                        width: 14px;
                        height: 116px;
                        border-radius: 10px;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid #fff;
                    }

                    &_fill {
                        width: 100%;
                        background: #fff;
                        position: absolute;
                        bottom: 0;
                        border-radius: 10px;
                    }

                    &_text {
                        display: flex;
                        flex-direction: column;
                        gap: 2px; 
                        font-size: 12px;
                        text-align: left;
                        color: #fff;
                        padding-left: 17px;
                        padding-top: 10px;
                    }


                    &_percentage {
                        font-size: 20px;
                        font-weight: bold;
                        color: #4effdf;
                        padding: 15px 0;
                    }
                }

                &__circular {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &_wrapper {
                        margin-top: 11px;
                        width: 100px;
                        height: 100px;
                        position: relative;
                    }

                    &_text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                    }

                    &_description {
                        margin-top: 17px;
                        font-size: 12px;
                        font-weight: 500;
                        text-align: center;
                        color: #fff;
                    }
                }
            }
        }
    }
}
