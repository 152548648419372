@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex($align: center);
    padding: 15px 25px;
    border-top: 1px solid $mystic;
    position: relative;

    &:hover {
        &::before {
            content: '';
            position: absolute;
            width: 3px;
            height: 100%;
            background-color: $dodger-blue-darker;
            left: 0;
        }
    }

    & > div {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
    }

    .rank {
        @include font-style($bold: 700);
        width: 4%;
    }

    .titleBlock {
        width: 35%;
        padding-right: 20px;
        overflow: hidden;

        .title {
            @include font-style($size: 17px, $bold: 700, $lineHeight: 25.5px);
            padding-right: 0;

            @media (max-width: 767.98px) {
                font-size: 14px;
            }

            & > div {
                max-width: 100%;
            }

            .titleText {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;

                .proposalLink {
                    color: $fiord;
                    transition: color 0.3s;

                    &:hover {
                        color: $dodger-blue-darker;
                    }
                }
            }
        }
    }

    .date {
        width: 12%;
    }

    .proposerInfo {
        width: 15%;
        padding-right: 40px;

        .proposedBy {
            border-right: 2px solid $catskill-white;
            padding-right: 0;

            & > div {
                @include display-flex($align: center);

                .logo {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                }

                .proposalName {
                    margin: 0 10px;
                    max-width: 74px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-decoration: none;

                    .link {
                        color: $fiord;
                        transition: 0.3s color;

                        &:hover {
                            color: $dodger-blue-darker;
                        }
                    }
                }

                .proposalSortingActive {
                    font-weight: 700;
                    margin-right: 0;
                }
            }
        }
    }

    .outcomeStatus {
        width: 9%;
        padding: 0 9px;

        & > div {
            max-width: 100%;
        }
    }

    .quorum {
        width: 9%;
    }

    .votes {
        width: 9%;
    }

    .forShares {
        width: 8%;
        padding-right: 20px;
    }

    .againstShares {
        width: 8%;
    }

    .abstainShares {
        width: 8%;
    }

    .itemNotExist {
        color: $nepal;
    }

    .forShares,
    .againstShares,
    .abstainShares,
    .votes {
        @include display-flex();
        padding-right: 20px;
        overflow: hidden;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    @media (max-width: 991.98px) {
        padding: 10px 15px;
    }
}
