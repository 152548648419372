@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    padding: 12px;

    .titleWrapper {
        padding-bottom: 8px;

        .tableTitle {
            @include font-style($size: 14px, $bold: 600, $color: $fiord);

            @media (max-width: 767.98px) {
                font-size: 16px;
                margin-left: 8px;
            }
        }

        @media (max-width: 767.98px) {
            border-bottom: 1px solid $catskill-white;
        }
    }

    & > div {
        @include table-header-font-styles();
    }

    .headerItems {
        @include display-flex();

        .address {
            width: 25%;
        }

        .type {
            width: 20%;
        }

        .chainName {
            width: 20%;
        }

        .description {
            width: 35%;
        }

        @media (max-width: 767.98px) {
            padding: 8px 12px 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
}
