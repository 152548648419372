@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex();
    width: 100%;
    padding-bottom: 10px;

    & > div {
        width: 22%;

        & > span {
            @include font-style($size: 14px, $bold: 700, $color: $fiord);
            white-space: nowrap;
        }

        &:last-child {
            @include display-flex($justify: center);
            width: 12%;
        }
    }
}
