@import 'src/styles/variables';
@import 'src/styles/mixins';

.footerWrapper {
    background-color: $fiord;
    padding: 30px 16px 16px;

    .linksSection {
        @include display-flex($justify: space-between);
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($nepal, 0.2);

        .info {
            .footerLogo {
                width: 28px;
                height: 26px;
            }

            .description {
                @include font-style(
                    $size: 14px,
                    $bold: 400,
                    $color: $aquamarine,
                    $lineHeight: 16px,
                    $family: $font-koho
                );
            }
        }

        .pagesLinks {
            @include display-flex();

            .linksWrapper {
                @include display-flex($direction: column);

                .link {
                    @include font-style($size: 12px, $bold: 400, $color: $white);
                    margin-top: 8px;
                }

                & > a:first-child {
                    margin-top: 0;
                }
            }

            & > div:first-child {
                margin-right: 24px;
            }
        }
    }

    .bottomSection {
        @include display-flex($align: flex-end, $justify: space-between);
        padding-top: 16px;

        .mail {
            @include font-style($size: 12px, $bold: 400, $color: $aquamarine);
        }

        .copyright {
            @include font-style($size: 10px, $bold: 400, $color: $nepal);
        }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        padding-left: 40px;
        padding-right: 40px;
    }
}
