@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableWrapper {
    width: 100%;
    padding: 28px 28px 10px 28px;
    height: 85%;

    .tableContainer {
        .rowsWrapper {
            padding-top: 18px;
        }

        .loader {
            bottom: 0;
            top: 50%;
            transform: translateY(-25%);
        }
    }

    @media (max-width: 767.98px) {
        padding: 20px 0 0;
    }
}
