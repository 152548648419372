@import 'src/styles/variables';
@import 'src/styles/mixins';

.headerWrapper {
    width: 100%;
    min-height: 290px;
    position: relative;
    background: linear-gradient(180deg, $dodger-blue-darker 33.92%, $java 110.2%);
    padding: 30px 40px;

    .headerContainer {
        @include display-flex($align: flex-start, $justify: space-between);
        @include max-device-width();

        .userContent {
            @include display-flex($align: flex-start);

            .avatar {
                &Container {
                    display: flex;
                    position: relative;
                }

                width: 170px;
                height: 170px;
                border: 8px solid $white;
                border-radius: 50%;
                background-color: $white;

                @media (max-width: 767.98px) {
                    width: 85px;
                    height: 85px;
                    border-width: 3px;
                }
            }

            .workStatusWrapper {
                @include display-flex($align: center, $justify: center);
                padding: 9px 14px;
                position: absolute;
                top: 84%;
                right: 80px;
                background-color: $white;
                box-shadow: 0px -4px 4px rgba(6, 102, 246, 0.2);
                border-radius: 30px;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 25px;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    border-left: 16px solid transparent;
                    border-right: 2px solid transparent;
                    border-bottom: 10px solid $white;
                }

                .workStatus {
                    @include font-style($size: 16px, $bold: 500, $color: $fiord);
                    white-space: nowrap;

                    .textWhiteSpaces {
                        white-space: normal;
                    }
                }

                .closeButton {
                    background-color: transparent;
                    min-width: unset;
                    padding: 0;
                    margin-top: 2px;
                    margin-left: 10px;

                    .closeIcon {
                        @include font-style($size: 18px, $color: $bright-turquoise);
                    }
                }

                @media (max-width: 991.98px) {
                    top: -22px;
                    right: 0px;
                    left: 45%;
                    width: fit-content;

                    &::before {
                        right: 0;
                        left: 20px;
                        bottom: -10px;
                        width: 0;
                        height: 0;
                        border-left: 2px solid transparent;
                        border-right: 16px solid transparent;
                        border-bottom: none;
                        border-top: 10px solid $white;
                    }
                }

                @media (max-width: 767.98px) {
                    padding-top: 5px;
                    padding-bottom: 5px;

                    .workStatus {
                        font-size: 14px;
                    }
                }
            }

            .mainContent {
                @include display-flex($justify: space-between);
                margin-left: 30px;
                margin-right: 40px;
                width: 100%;

                .userInfo {
                    @include display-flex();
                    flex-direction: column;

                    & > div {
                        @include display-flex($align: center, $wrap: wrap);

                        .userName {
                            @include font-style($size: 40px, $bold: 400, $family: $font-koho, $color: $white);
                            @include display-flex($align: center);
                            margin-top: -4px;
                            margin-right: 16px;
                            overflow-wrap: anywhere;

                            @media (max-width: 768px) {
                                font-size: 28px;
                            }
                        }

                        .userButtonsContainer {
                            @include display-flex($wrap: wrap, $align: center);

                            & > button {
                                height: 34px;
                                box-shadow: 0px 4px 4px rgba(6, 102, 246, 0.2);
                            }

                            & > div:first-child {
                                margin: 10px 16px 10px 0;

                                @media (max-width: 768px) {
                                    margin-top: 6px;
                                    margin-bottom: 6px;
                                }
                            }

                            .editButton {
                                @include display-flex($justify: space-between);
                                width: 81px;
                                margin: 10px 16px 10px 0;
                                padding: 8px 12px;
                                background-color: $dodger-blue-darker;
                                border: 2px solid $white;

                                .editIcon {
                                    @include font-style($size: 24px, $color: $white);
                                    margin-left: -4px;
                                }

                                .buttonText {
                                    @include font-style($size: 16px, $bold: 500, $color: $white, $transform: none);
                                }

                                @media (max-width: 768px) {
                                    margin-top: 6px;
                                    margin-bottom: 6px;
                                }
                            }
                        }

                        @media (max-width: 768px) {
                            align-items: flex-start;
                            flex-direction: column;
                        }
                    }

                    @media (min-width: 768px) and (max-width: 991.98px) {
                        margin-top: 20px;
                    }
                }

                .descriptionText {
                    @include font-style($size: 14px, $bold: 400, $lineHeight: 21px);
                    margin-top: 10px;
                }

                .buttonsContainer {
                    @include display-flex($align: center);

                    & > div {
                        @include display-flex();
                    }

                    .button,
                    .shareButton {
                        @include font-style(
                            $size: 16px,
                            $bold: 500,
                            $color: $heliotrope-lighter,
                            $transform: none,
                            $spacing: 0
                        );
                        height: 55px;
                        background-color: $white;
                        border: 2px solid $heliotrope-lighter;
                        padding: 2px 4px;
                        margin-right: 12px;
                        border-radius: 4px;
                    }

                    .button {
                        padding: 8px 11px;
                        margin-right: 12px;
                    }
                }

                @media (max-width: 1199.98px) {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }

        @media (max-width: 991.98px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (max-width: 1199.98px) {
        padding-right: 40px;
        padding-left: 80px;
    }

    @media (max-width: 991.98px) {
        padding: 30px 40px;
    }

    @media (max-width: 575.98px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
