@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($align: center, $justify: center);
    background: linear-gradient(180deg, rgba(51, 123, 255, 0.8) 34.12%, rgba(29, 179, 210, 0.8) 100%);
    min-height: 200px;
    padding: 30px;

    .container {
        @include display-flex($align: center, $justify: center);
        width: 1000px;
        column-gap: 60px;

        .title {
            @include font-style($size: 64px, $color: $white, $bold: 400, $family: $font-koho);
            white-space: nowrap;
        }

        .description {
            @include font-style($size: 14px, $color: $white, $lineHeight: 18px, $bold: 600);

            .link {
                color: $aquamarine-blue;
            }
        }
    }
}
