@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    padding-right: 15px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: $white;
    border-bottom: 1px solid $catskill-white;

}
.tableHeader {
    padding: 10px 25px;
    background-color: $white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-right: 30px;
    .headerItems {
        @include display-flex();
        & > div {
            @include display-flex($align: center);

            & > div {
                @include display-flex($align: center);

                span {
                    @include table-header-font-styles();
                }
            }
        }

        .column {
          width: 30%;
        }
        .organizationColumn {
            width: 40%;
        }
        .holderName {
            width: 23%;
        }
        .holderVotes {
            width: 25%;
            margin-left: 10px
        }


        @media (max-width: 767.98px) {
            padding: 8px 8px 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
}
