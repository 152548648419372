@import 'src/styles/variables';
@import 'src/styles/mixins';

.headWrapper {
    @include display-flex();
    padding-bottom: 20px;
    border-bottom: 1px solid $periwinkle;

    .titleSection {
        .title {
            @include font-style(
                $size: 40px,
                $lineHeight: 44px,
                $bold: 400,
                $color: $fiord,
                $family: $font-koho !important
            );
            white-space: nowrap;
        }
    }

    .selectedFiltersWrapper {
        @include display-flex($align: center, $wrap: wrap);
        margin-left: 40px;

        .selectedFilter,
        .searchResultsBlock {
            margin: 14px 20px 6px 0;
        }

        .selectedFilter {
            @include display-flex($align: center);
            background-color: $nepal;
            padding: 4px 8px;
            border-radius: 4px;

            .filterName {
                @include font-style($size: 14px, $lineHeight: 16px, $bold: 700, $color: $white);
            }

            .removeFilterButton {
                width: 15px;
                height: 15px;
                min-width: unset;
                padding: 0;
                background-color: $white;
                border-radius: 50%;
                margin-left: 8px;

                .removeFilterIcon {
                    @include font-style($size: 15px, $color: $nepal);
                }
            }
        }

        .searchResultsBlock {
            .searchResultsCount {
                @include font-style($size: 16px, $lineHeight: 16px, $bold: 700, $color: $fiord);
            }
        }
    }
}
