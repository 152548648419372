@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsWrapper {
    background-color: $white;
    border-bottom: 1px solid $mystic;

    .tabsContainer {
        @include display-flex();
        max-width: 1270px;
        margin: 0 auto;

        .tabItem {
            @include display-flex($align: center, $justify: center);
            text-align: center;
            width: 100%;
            height: 62px;
            cursor: pointer;
            position: relative;
            transition: 0.5s all;
            border-right: 1px solid $mystic;

            @media (max-width: 767.98px) {
                width: 100%;
                height: 44px;
                border-right: none;
            }

            &:hover {
                & > div {
                    & > span {
                        color: $bright-turquoise;
                    }
                }
            }

            &:first-child {
                border-left: 1px solid $mystic;
            }

            .itemContent {
                @include display-flex($align: center, $justify: space-between);
                width: 100%;
                padding: 13px 16px;

                .optionsContainer {
                    @include display-flex($align: center);

                    .categoriesButton {
                        @include font-style($size: 12px, $bold: 500, $color: $fiord, $transform: none);
                        height: 30px;
                        min-width: unset;
                        color: $fiord;
                        border: 2px solid $nepal;
                        border-radius: 20px;

                        &:hover {
                            background-color: transparent;
                        }

                        .title {
                            margin-top: -1px;
                        }

                        .arrowIcon {
                            @include font-style($size: 20px);
                            margin-right: -6px;
                        }
                    }

                    .categoriesButtonActive {
                        border-color: $bright-turquoise;

                        .arrowIcon {
                            transform: rotate(180deg);
                        }
                    }
                }

                & > span {
                    @include font-style($size: 16px, $bold: 500, $color: $fiord);
                    transition: 0.3s color;
                }

                @media (max-width: 767.98px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media (max-width: 767.98px) {
                    justify-content: center;

                    & > span {
                        @include font-style($size: 14px, $bold: 600);
                    }
                }
            }
        }

        .activeTab {
            .itemContent {
                & > span {
                    color: $bright-turquoise;
                }
            }

            &::before {
                content: '';
                position: absolute;
                bottom: -1px;
                width: 100%;
                height: 3px;
                background-color: $bright-turquoise;
            }
        }
    }
}
