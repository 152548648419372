@import '../../../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../../../styles/mixins';

.tooltipWrapper {
    @include display-flex($align:center,$direction: column);
    width: 120px;
    background-color: $white;
    padding: 10px;
    border: 1px solid $fiord;
    border-radius: 4px;

    & > span {
        @include font-style($size: 14px, $color: $fiord);
        margin-top: 10px;
    }

    & > span:first-child {
        margin-top: 0;
    }
}
