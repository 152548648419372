@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include max-device-width();
    @include display-flex($justify: center, $grow: 1);
    width: 100%;

    .container {
        @include display-flex($align: center, $direction: column, $justify: space-between);
        width: 100%;
        max-width: 940px;
        padding: 60px 20px 35px;

        .mainContent {
            @include display-flex($align: center, $direction: column);
            width: 100%;

            .navigationBackBlock {
                @include display-flex($align: flex-end);
                width: 100%;
                margin-bottom: 10px;

                .accountSettingsLink {
                    @include display-flex($align: center);
                    color: $fiord;
                    transition: 0.3s color;

                    .arrowIcon {
                        @include font-style($size: 14px);
                    }

                    .text {
                        @include font-style($size: 14px, $bold: 400);
                    }

                    &:hover {
                        color: $dodger-blue-darker;
                    }
                }
            }

            .title {
                @include font-style($size: 40px, $bold: 500, $color: $fiord, $lineHeight: 44px, $family: $font-koho);
            }

            .description {
                @include font-style($size: 16px, $bold: 400, $color: $fiord, $lineHeight: 20px);
                margin-top: 12px;

                a {
                    color: $dodger-blue-darker;
                    font-weight: 700;
                }
            }

            .formWrapper {
                width: 440px;
                background-color: $white;
                padding: 40px;
                box-shadow: 0px 0px 20px rgba(51, 123, 255, 0.2);
                border-radius: 12px;
                margin-top: 60px;

                .formTitle {
                    @include font-style($size: 16px, $bold: 400, $color: $fiord, $lineHeight: 20px, $align: center);
                    margin-bottom: 14px;
                }

                .form {
                    .fieldsWrapper {
                        & > div {
                            margin-top: 35px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }

                    .submitBtn {
                        @include font-style($size: 18px, $bold: 700, $color: $white, $transform: lowercase);
                        width: 100%;
                        height: 44px;
                        background-color: $bright-turquoise;
                        margin-top: 50px;

                        .loader {
                            position: static;
                        }

                        &:disabled {
                            background-color: $ghostwhite;
                            color: $heather;
                        }
                    }

                    .errorText {
                        @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
                        margin-top: 4px;
                    }
                }
            }
        }

        .optionsBlock {
            @include display-flex($align: center, $direction: column);
            margin-top: 40px;

            .infoText {
                @include font-style($size: 12px, $bold: 400, $color: $fiord);

                .mail {
                    @include font-style($size: 12px, $bold: 700, $color: $fiord);
                }
            }
        }
    }
}
