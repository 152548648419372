@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    padding: 15px;
    border-top: 1px solid $selago;

    .mainRowInfo {
        @include display-flex($align: center);

        & > div {
            @include display-flex($align: center);
            @include font-style($size: 14px, $bold: 400, $color: $fiord);
        }

        .organizationContainer {
            width: 35%;

            .expandButton {
                width: 30px;
                height: 30px;
                margin-right: 20px;
                min-width: unset;
                background-color: $ghostwhite;
                border-radius: 3px;

                & svg {
                    @include font-style($size: 30px, $color: $malibu-lighter);
                }
            }

            & a {
                overflow: hidden;

                .organization {
                    @include display-flex($align: center);

                    &:hover {
                        .organizationInfo {
                            .daoName {
                                color: $dodger-blue-darker;
                            }
                        }
                    }
                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    &Info {
                        margin-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .daoName {
                            @include font-style($size: 14px, $bold: 700, $color: $fiord);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            transition: color 0.2s;
                        }
                    }
                }
            }
        }

        // .votingPower {
        //     width: 12%;

        //     & > span {
        //         @include font-style($size: 14px, $bold: 700, $color: $fiord);
        //         padding: 8px;
        //         background-color: $selago;
        //         border-radius: 2px;
        //     }
        // }

        .proposals {
            width: 16%;
        }

        .successProposals {
            flex-grow: 1;
        }

        .votes {
            width: 15%;
        }

        .successfulVotes {
            width: 17%;
        }
    }
}
