@import 'src/styles/variables';
@import 'src/styles/mixins';

.tooltipWrapper {
    position: absolute;
    pointer-events: none;
    z-index: 5;

    .tootlipContainer {
        @include display-flex($align: flex-start);
        width: 240px;
        background-color: $dodger-blue-darker;
        box-shadow: 0px 4px 10px rgba(0, 102, 246, 0.2);
        border-radius: 6px;
        padding: 14px;
        position: relative;
        pointer-events: fill;

        .text {
            @include font-style($size: 14px, $bold: 400, $color: $white, $lineHeight: 21px);
            margin-top: 12px;
        }

        .closeButton {
            min-width: unset;
            padding: 0;

            &:hover {
                background-color: transparent;
            }

            .closeIcon {
                @include font-style($size: 21px, $color: $white);
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 47%;
            top: 99%;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 10px solid $dodger-blue-darker;
        }

        @media (max-width: 575.98px) {
            &::after {
                display: none;
            }
        }
    }
}
