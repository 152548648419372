@import 'src/styles/variables';
@import 'src/styles/mixins';

.sideBarWrapper {
    position: fixed;
    width: $sidebar-width;
    z-index: 11;
    height: 100%;

    .sideBarContainer {
        background: $left-sidebar-background-color;
        height: 100%;

        .mainContent {
            @include display-flex($direction: column, $justify: space-between);
            height: 100%;
            padding: 25px 14px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .navigations {
                .logoWrapper {
                    @include display-flex($align: center, $justify: center);

                    .imageLink {
                        .deepDaoLogo {
                            width: 150px;
                            height: 52px;
                        }
                    }
                }

                .arrowButtonWrapper {
                    @include display-flex($justify: center);
                    visibility: hidden;
                    margin-top: 25px;

                    .arrowButton {
                        min-width: unset;
                        padding: 0;

                        .arrowIcon {
                            @include font-style($size: 23px, $color: $nepal);
                            transition: 0.3s all;
                            transition-property: color, transform;
                        }

                        &:hover {
                            background-color: transparent;

                            .arrowIcon {
                                color: $white;
                            }
                        }
                    }
                }

                .linksWrapper {
                    margin-top: 36px;
                }
            }

            .collapseOptionContainer {
                @include display-flex($align: center, $justify: flex-end);

                .collapseOption {
                    @include display-flex($align: center);
                    color: $white;
                    cursor: pointer;
                    padding: 10px;

                    .arrowIcon {
                        transform: rotate(90deg);
                        margin-bottom: -2px;
                    }

                    .collapseText {
                        @include font-style($size: 16px, $bold: 400, $family: $font-koho);
                        display: none;
                        margin-left: 10px;
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.sidebarExpanded {
    width: $expanded-sidebar-width;

    .sideBarContainer {
        .mainContent {
            .collapseOptionContainer {
                .collapseOption {
                    .collapseText {
                        display: block;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
