.tableWrapper {
    padding: 10px;
    overflow-x: scroll;

    .tableContainer {
        width: 1270px;

        .loader {
            bottom: 10px;
        }
    }
}
