@import 'src/styles/variables';
@import 'src/styles/mixins';

.filter {
    @include font-style($size: 14px, $bold: 400);
    padding: 8px 0;
    cursor: pointer;
    transition: 0.3s color;

    &:hover {
        color: $dodger-blue-darker;
    }
}

.filterIsSelected {
    @include font-style($color: $dodger-blue-darker, $bold: 700);
}
