@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 25px 26px;
}

.addressContainer {
    display: flex;
    align-items: center;
    width: 130px;
    position: relative;

    .avatar {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        margin-right: 10px;
        z-index: 1;
    }

    &.rowHover:before {
        content: '';
        width: 210px;
        height: 70px;
        position: absolute;
        top: -21px;
        left: -27px;
        right: 0;
        bottom: 0;
        background-color: #CCDEF5;
        z-index: 0;
    }

    &::after {
        border-bottom: 1px solid $catskill-white;
        position: absolute;
        content: '';
        top: 48px;
        width: 180px;
        z-index: 0;
    }

    .coalitionDetails {
        position: relative;

        .userNameAddress {
            max-width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .link {
                font-size: 14px;
                color: $fiord;
                text-decoration: none;
            }
        }
    }
}
