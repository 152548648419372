@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    @include display-flex();

    .icon {
        width: 36px;
        height: 25px;
    }

    & > div {
        margin-left: 10px;

        .title {
            @include font-style($size: 18px, $bold: 700, $lineHeight: 20px, $color: $fiord);

            .link {
                color: $fiord;
                position: relative;
                transition: 0.3s color;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $fiord-darker;
                    transition: 0.3s background-color;
                }

                &:hover {
                    color: $dodger-blue-darker;

                    &::before {
                        background-color: $dodger-blue-darker;
                    }
                }
            }
        }

        .text {
            @include font-style($size: 16px, $bold: 400, $lineHeight: 24px, $color: $fiord);
            margin-top: 10px;
        }
    }
}
