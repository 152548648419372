@import 'src/styles/variables';
@import 'src/styles/mixins';

.tool {
    display: flex;
    margin-top: 25px;

    .imageWrapper {
        height: 73px;
        width: 73px;
        min-width: 73px;
        border-radius: 10px;
        box-shadow: -4px 5px 0px -2px rgba(51, 123, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;

        .logo {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }

    .nameWrapper {
        @include display-flex($direction: column, $justify: center, $align: flex-start);
        margin-left: 12px;

        .organizationName {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            color: $fiord;
        }

        .phrase {
            color: $fiord;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            margin-top: 4px;
            transition: color 0.3s;

            &:hover {
                color: $dodger-blue-darker;
                text-decoration: none;
            }
        }
    }

    @media (max-width: 1199.98px) {
        width: 178px;
        min-width: 178px;
        height: 68px;
        background-color: $white;
        border-radius: 10px;
        margin: 0 8px;
        padding: 10px;

        .imageWrapper {
            width: auto;
            height: auto;
            min-width: auto;
            background-color: transparent;
            box-shadow: none;

            .logo {
                width: 48px;
                height: 48px;
            }
        }

        .nameWrapper {
            .organizationName {
                max-width: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .phrase {
                font-size: 14px;
                line-height: 18px;
                margin-top: 2px;
                overflow-wrap: anywhere;
                word-break: break-word;
            }
        }
    }
}
