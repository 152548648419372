@import 'src/styles/variables';
@import 'src/styles/mixins';

.logosWrapper {
    @include display-flex($align: center);
    height: 20px;

    .logoWrapper {
        width: 20px;
        height: 20px;
        margin-right: 2px;

        .logo {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            filter: drop-shadow(0px 1px 1px rgba(6, 102, 246, 0.2));
        }
    }

    .total {
        @include font-style($size: 10px, $bold: 700, $color: $nepal);
        margin-left: 3px;
    }
}
