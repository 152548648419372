@import 'src/styles/variables';
@import 'src/styles/mixins';

.votingBtn {
    @include display-flex($align: center, $justify: center);
    height: 38px;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid $periwinkle-lighter;
    border-radius: 4px;
    transition: 0.3s all;

    .votingDolphinImage {
        width: 30px;
        height: 30px;
    }

    .title {
        @include font-style($size: 16px, $color: $fiord, $bold: 500);
        margin-left: 8px;
    }

    &:hover {
        background-color: $ghostwhite;
    }
}

.commentVoteBtn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 0px 6px rgba(51, 123, 255, 0.4);

    &:hover {
        background-color: $ghostwhite;
    }

    .votingDolphinImage {
        width: 20px;
        height: 20px;
    }
}

.buttonDisabled {
    pointer-events: none;
}

.votedFor {
    background-color: $white-ice-lighter;
    border: none;
}

.votedAgainst {
    background-color: $wisp-pink-lighter;
    border: none;
}

.votedForBorder {
    border: 1px solid $bright-turquoise;
}

.votedAgainstBorder {
    border: 1px solid $lavender-magenta;
}

@media (max-width: 575.98px) {
    .votedAgainst,
    .votedFor {
        border: 1px solid $periwinkle-lighter;
    }
}
