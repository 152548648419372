@import 'src/styles/variables';
@import 'src/styles/mixins';

.followButton {
    @include font-style($size: 14px, $bold: 500, $lineHeight: 14px, $color: $fiord, $transform: none);
    min-width: unset;
    border-radius: 20px;
    cursor: pointer;
    height: 28px;
    width: 88px;
    background-color: $bright-turquoise;
    color: $white;
    border: 2px solid $bright-turquoise;
    &:hover {
        color: $white;
    }
}
.isFollowed {
    background-color: $white;
    border-color: $nepal;
    color: $nepal;
    &:hover {
        color: $bright-turquoise;
        border-color: $bright-turquoise;
    }
}