@import 'src/styles/variables';
@import 'src/styles/mixins';

.buttonsWrapper {
    @include display-flex($justify: space-between);
    margin-top: 80px;

    & > button {
        width: 170px;
        height: 44px;
        text-transform: none;
    }

    .previousStepButton {
        border: 2px solid $nepal;
        padding: 8px 12px;

        & > span {
            @include display-flex($justify: space-between, $direction: row-reverse);

            .title {
                @include font-style($size: 16px, $bold: 500, $color: $nepal);
            }

            .arrowIcon {
                @include font-style($size: 26px, $color: $nepal);
                transform: rotate(180deg);
                margin-left: -4px;
            }
        }

        &:hover {
            background-color: $white;
        }
    }

    .listOrganizationButton {
        @include font-style($size: 16px, $bold: 500, $color: $white);
        background-color: $bright-turquoise;

        &:hover {
            background-color: $bright-turquoise;
        }

        &:disabled {
            background-color: $ghostwhite;
            color: $heather;
            border: none;
        }

        .loader {
            position: static;
        }
    }
}

.errorBlock {
    @include display-flex($align: flex-start, $justify: space-between);
    width: 100%;
    background-color: $lavender-rose;
    box-shadow: 0px 0px 10px rgba(64, 107, 179, 0.1);
    border-radius: 6px;
    padding: 20px;
    margin-top: 40px;

    .errorText {
        @include font-style($size: 20px, $bold: 600, $color: $white, $transform: uppercase);
    }

    .closeBtn {
        min-width: unset;
        padding: 0;
        margin-left: 20px;

        .closeIcon {
            @include font-style($size: 26px, $color: $white);
        }
    }
}