@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsContainer {
    @include display-flex();
    margin-left: 60px;

    .tabItem {
        @include font-style($size: 16px, $color: $nepal);
        margin-right: 25px;
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: 0.2s linear all;
    }

    .activeTab {
        color: $fiord;
        border-bottom: 2px solid $fiord;
    }
}
