@import 'src/styles/variables';
@import 'src/styles/mixins';

.rowHover {
    background: #CCDEF5;
    box-shadow: -6px 0 10px rgba(0, 0, 0, 0.15);
}

.tableRow {
    height: 70px;
    @include display-flex($align: center);
    @include font-style($size: 14px, $bold: 500, $color: #41536A);
    border-bottom: 1px solid $catskill-white;
    padding: 17px 26px;
    position: relative;

    .votedTogether {
        width: 18%;
    }

    .delegatorsNumber {
        width: 15%;
    }

    .votingPower {
        width: 18%;
    }

    .votingPowerPercentage {
        width: 14%;
    }

    .circulatingSupplyPercentage {
        width: 17%;
    }

    .sixMWinningPercentage {
        width: 18%;
    }

    .memberStats {
        flex-grow: 1;
        max-width: 200px;

        & > div {
            @include display-flex($align: flex-start, $justify: space-between);

            & > h6 {
                @include font-style($size: 14px, $bold: 400, $color: $nepal);
                margin-right: 10px;
                margin-top: 15px;
            }

            & > p {
                @include font-style($size: 14px, $bold: 700, $color: $fiord);
                margin-top: 15px;
            }
        }
    }

    @media (max-width: 767.98px) {
        justify-content: space-between;
        padding: 10px;

        .number {
            display: none;
        }

        .coalitions {
            flex-direction: column;

            .coalitionItem {
                margin-right: 0;
                margin-bottom: 14px;
            }

            & > div:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}
