@import 'src/styles/variables';

.tableRow {
    display: flex;
    align-items: center;
    padding: 20px 20px 13px;
    border-top: 1px solid $catskill-white;

    & > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: $fiord;
    }

    .voter {
        width: 41%;
        overflow: hidden;
        padding-right: 20px;

        .avatar {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }

        &Name {
            font-weight: 700;
            margin-left: 9px;
            color: $fiord;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .shares {
        width: 34%;
    }

    .index {
        width: 25%;
    }
}
