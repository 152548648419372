@import 'src/styles/variables';
@import 'src/styles/mixins';

.stepperWrapper {
    max-width: 650px;
    margin: 30px auto 0;

    .stepperContent {
        .stepsList {
            @include display-flex();

            .step {
                @include display-flex($align: center, $direction: column);
                width: calc(100% / 3);
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    width: 100%;
                    height: 1px;
                    background-color: $nepal;
                }

                &Circle {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $white;
                    border: 1px solid $nepal;
                    z-index: 2;
                }

                &Description {
                    @include font-style($size: 12px, $bold: 700, $color: $nepal);
                    margin-top: 10px;
                }
            }

            .activeStep {
                .stepCircle {
                    border: none;
                    background-color: $dodger-blue-darker;
                }

                .stepDescription {
                    color: $fiord;
                }
            }

            .complitedStep {
                .stepCircle {
                    border: none;
                    background-color: $nepal;
                } 
            }

            .firstStep {
                &::before {
                    width: 50%;
                    right: 0;
                }
            }

            .lastStep {
                &::before {
                    width: 50%;
                    left: 0;
                }
            }
        }
    }
}
