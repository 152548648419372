@import 'src/styles/variables';
@import 'src/styles/mixins';

.membersSection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    max-width: 950px;
    row-gap: 40px;
    margin-top: 40px;

    .cardWrapper {
        @include display-flex($justify: center);
        padding: 0 10px;

        .memberCard {
            height: 235px;
            min-width: 165px;

            .memberAvatar {
                width: 133px;
                height: 133px;
                border-radius: 50%;
            }

            .memberInfo {
                margin-top: 16px;

                .name {
                    @include font-style($size: 20px, $bold: 500, $family: $font-koho);
                }

                .role {
                    @include font-style($size: 16px, $bold: 700, $color: $nepal);
                    margin-top: 6px;
                }

                .profileLink {
                    @include display-flex($align: center);
                    margin-top: 6px;

                    .profile {
                        @include font-style($size: 14px, $bold: 400, $color: $nepal);
                    }

                    .arrowIcon {
                        width: 25px;
                        height: 15px;
                        margin-left: 7px;
                        transition: transform ease-out 0.25s;
                    }

                    &:hover {
                        .arrowIcon {
                            transform: translateX(12px);
                        }
                    }
                }
            }

            @media (max-width: 575.98px) {
                @include display-flex($align: center);
                height: auto;
                min-height: auto;

                .memberAvatar {
                    width: 75px;
                    height: 75px;
                }

                .memberInfo {
                    margin-top: 0;
                    margin-left: 10px;

                    .name {
                        @include font-style($size: 22px, $bold: 400);
                    }

                    .role {
                        margin-top: 0;
                    }

                    .profileLink {
                        margin-top: 0;
                        margin-top: 4px;

                        .profile {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        @media (max-width: 575.98px) {
            justify-content: flex-start;
        }
    }

    @media (min-width: 767px) and (max-width: 991.98px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 575.98px) {
        grid-template-columns: 1fr;
        margin-top: 40px;
    }
}
