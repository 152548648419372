@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: space-between, $direction: column);
    width: 100%;
    height: 213px;
    padding: 16px 20px;
    background-color: rgba($white, 0.25);
    border-right: 1px solid rgba($white, 0.5);

    .info {
        color: $white;

        .heading {
            @include font-style($size: 14px, $bold: 600);
        }

        .value {
            @include font-style($size: 32px, $bold: 700, $family: $font-koho);
        }
    }

    .graph {
        width: 100%;
        height: 120px;

        .loaderBox {
            margin-top: 40px;
            position: static;
        }
    }

    @media (max-width: 767.98px) {
        height: auto;
        min-height: 230px;
    }
}
