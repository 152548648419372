@import 'src/styles/variables';
@import 'src/styles/mixins';

.membersWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 34px;

    .header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        color: $fiord;
        line-height: 120%;
        text-transform: uppercase;
        margin-top: 20px;
    }

    .membersList {
        max-height: 190px;
        overflow-y: auto;
        padding-right: 20px;

        &::-webkit-scrollbar {
            width: 3px;
            background-color: $catskill-white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $nepal;
        }

        .member {
            display: flex;
            margin-top: 10px;
            align-items: center;
            cursor: pointer;
            user-select: none;
            overflow: hidden;

            svg {
                width: 22px;
                margin-right: 20px;
                fill: #9cb4c2;
            }

            .avatar {
                width: 28px;
                height: 28px;
                object-fit: cover;
                border-radius: 50%;
            }

            .name {
                @include font-style($size: 14px, $bold: 400, $color: $fiord);
                margin-left: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @media (max-width: 575.98px) {
                margin-top: 12px;

                .avatar {
                    width: 36px;
                    height: 36px;
                }

                .name {
                    font-size: 16px;
                }
            }
        }

        .bold {
            .name {
                font-weight: 700;
            }
        }

        @media (max-width: 575.98px) {
            max-height: 255px;
        }
    }

    @media (max-width: 575.98px) {
        margin-left: 40px;

        .header {
            font-size: 12px;
        }
    }
}
