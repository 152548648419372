@import 'src/styles/variables';
@import 'src/styles/mixins';

.checkbox {
    width: 18px;
    height: 18px;
    margin: 0;
    color: $dodger-blue-darker !important;

    span {
        cursor: default;
    }

    input {
        cursor: pointer;
    }

    &:hover {
        background-color: transparent !important;
    }
}

.checkboxChecked {
    color: $dodger-blue-darker !important;
}
