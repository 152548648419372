@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    padding: 10px 20px;
    background-color: $white;
    border-bottom: 1px solid $catskill-white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .headerItems {
        @include display-flex();

        & > div {
            @include display-flex($align: center);

            & > div {
                @include display-flex($align: center);

                span {
                    @include table-header-font-styles();
                }
            }
        }

        .rank {
            width: 10%;
        }

        .tokenInfo {
            width: 25%;
        }

        .currencyValue {
            width: 38%;
        }

        .tokenBalance {
            width: 27%;
        }

        @media (max-width: 767.98px) {
            padding: 8px 8px 0;
        }
    }

    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
}
