@import 'src/styles/mixins';

.wrapper {
    @include max-device-width();
    width: 100%;
    padding: 10px 16px;

    .container {
        @include display-flex($justify: center, $direction: column);

        .bannerWrapper {
            @include display-flex($justify: flex-end);
            padding: 0 10px;
            margin-bottom: 10px;

            .bannerLink {
                .bannerImage {
                    width: 354px;
                    height: 100px;
                    border-radius: 4px;
                }

                @media (max-width: 575.98px) {
                    width: 100%;

                    .bannerImage {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .mainContent {
            @include display-flex();

            .leftSideContainer {
                width: 100%;
                max-width: 1100px;
                overflow-x: scroll;
            }

            .rightSideContainer {
                width: 100%;
                max-width: 320px;
                margin-left: 30px;
                margin-top: 5px;
                padding: 0 10px;

                .banner {
                    width: 100%;
                    height: auto;
                    margin-bottom: 30px;
                    border-radius: 4px;
                }

                @media (max-width: 991.98px) {
                    margin-left: 0;
                    max-width: none;
                    padding: 0 16px;
                    margin-top: 45px;
                }

                @media (max-width: 576px) {
                    margin-top: 35px;
                }
            }

            @media (max-width: 991.98px) {
                flex-direction: column;
            }
        }
    }
}
