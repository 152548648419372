.tablesWrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 40px;
    padding: 0 10px;
    position: relative;

    @media (max-width: 767.98px) {
        grid-template-columns: 1fr;
        padding: 0;
    }
}
