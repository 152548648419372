@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    width: 100%;
    & > div {
        & > div {
            @include display-flex();
            @include font-style($size: 14px, $bold: 700, $color: $fiord);
        }
    }

    .organization {
        width: 20%;
    }

    .treasury {
        width: 20%;
    }

    .members {
        width: 30%;
    }

    .notifications,
    .following {
        @include display-flex($justify: center, $align: center);
        width: 15%;
    }

    @media (max-width: 767.98px) {
        .organization {
            width: 30%;
        }

        .treasury {
            width: 20%;
        }

        .members {
            width: 20%;
        }
    }

    @media (max-width: 575.98px) {
        .organization {
            width: 50%;
        }

        .notifications,
        .following {
            width: 25%;
        }

        .treasury,
        .members {
            display: none;
        }
    }
}
