@import 'src/styles/mixins';

.block {
    @include display-flex($align: center);
    transform: translate3d(0, 0, 0);
    animation: moveSlideshow 35s linear infinite;

    .imageWrapper {
        margin-left: 40px;

        &:first-child {
            margin-left: 0px;
        }
    }

    @keyframes moveSlideshow {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}
