@import 'src/styles/variables';
@import 'src/styles/mixins';

.contentWrapper {
    @include max-device-width();

    .statsGraphCardsWrapper {
        display: grid;
        grid-template-columns:
            minmax(170px, 1fr)
            minmax(400px, 2.5fr)
            minmax(300px, 1.9fr);
        column-gap: 35px;
        row-gap: 35px;
        margin: 0 auto;
        margin-top: 25px;

        .lineGraphContainer {
            margin-top: 10px;

            & > div {
                & > h6 {
                    margin-bottom: 8px;
                }
            }
        }

        .barLabelStyles {
            font-size: 11px !important;
        }

        @media (min-width: 576px) and (max-width: 991.98px) {
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr;
        }

        @media (max-width: 575.98px) {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
        }
    }
}

.tableContent {
    @include display-flex($justify: center);
    @include max-device-width();
    padding: 60px 15px;
}

.peopleStatsCard {
    min-height: unset !important;
}
