@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.tableRow {
    @include display-flex();
    padding: 10px 14px;
    border-top: 1px solid $catskill-white;

    .rowCellWrapper {
        @include display-flex($align: center);
        padding-right: 20px;
    }

    .rowCellTextOverflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .rowCellText {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
        @extend .rowCellTextOverflow;
    }

    .rowCellBoldText {
        font-weight: 700;
    }

    .linkWrapper {
        color: $dodger-blue-darker;

        & > span {
            color: $dodger-blue-darker;
            @extend .rowCellBoldText;
        }
    }
}
