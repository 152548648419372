@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    margin-top: 24px;

    .container {
        padding: 10px;
        border-radius: 8px;

        .visibleContent {
            @include display-flex($align: center, $justify: space-between);
            cursor: pointer;

            & > div:first-child {
                @include display-flex($align: center);

                .moreIcon {
                    width: 24px;
                    height: 24px;
                }

                .title {
                    @include font-style($size: 16px, $bold: 400, $color: $white, $family: $font-koho);
                    margin-left: 14px;
                }
            }

            .arrowIconWrapper {
                @include display-flex($align: center);

                .arrowIcon {
                    @include font-style($size: 20px, $color: $white);
                    transition: 0.3s transform;
                }
            }
        }

        .menuWrapper {
            margin-top: 4px;
        }
    }

    .containerOpen {
        background: $left-sidebar-active-link;
        padding-bottom: 20px;

        .visibleContent {
            .arrowIconWrapper {
                .arrowIcon {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
