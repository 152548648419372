@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableHeader {
    @include display-flex($align: flex-end);
    padding: 20px 26px 10px 26px;
    min-width: 1200px;

    & > div {
        @include display-flex($align: flex-end);

        & > div {
            @include display-flex($align: center);

            span {
                @include font-style($size: 14px, $bold: 700, $color: #1F3B82);

                @media (max-width: 575.98px) {
                    font-size: 12px;
                }
            }
        }
    }

    .number {
        width: 4%;
    }

    .coalitions {
        width: 34%;
    }

    .votedTogether {
        padding-left: 10px;
        width: 12%;
    }

    .delegators {
        width: 10%;
    }

    .votingPower {
        width: 10%;
    }

    .quorumPercentage {
        width: 8%;
    }

    .circulatingSupplyPercentage {
        width: 10%;
    }

    .sixMWiningPercentage {
        width: 10%;
    }
}
