@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    .introduction {
        @include display-flex($align: center, $justify: center, $direction: column);
        padding: 145px 30px 70px;
        background: linear-gradient(180deg, $dodger-blue-darker 79.02%, $java 103.83%);

        .introducing {
            @include display-flex($align: center, $justify: space-between);
            width: 100%;
            max-width: 1140px;
            color: $white;

            &Title {
                @include font-style($size: 64px, $lineHeight: 70px, $bold: 400, $family: $font-koho);
                max-width: 700px;
                margin-right: 30px;
            }

            &Description {
                @include font-style($size: 20px, $lineHeight: 24px, $bold: 400);
                max-width: 380px;
            }

            @media (max-width: 767.98px) {
                flex-direction: column;

                &Title {
                    @include font-style($size: 38px, $lineHeight: 42px);
                    margin-right: 0;
                }

                &Description {
                    max-width: none;
                    margin-top: 20px;
                }
            }
        }

        .sellingPoints {
            width: 100%;
            max-width: 900px;

            & > div {
                margin-top: 100px;

                @media (max-width: 767.98px) {
                    margin-top: 50px;
                }
            }

            .firstWrapper {
                justify-content: flex-start;

                & > div {
                    & > div {
                        &:last-child {
                            margin-left: 80px;

                            @media (min-width: 575.98px) and (max-width: 768px) {
                                margin-left: 20px;
                            }
                        }

                        & > div {
                            max-width: 420px;
                        }
                    }

                    & > img {
                        width: 265px;
                        height: 515px;
                    }
                }

                @media (max-width: 575.98px) {
                    justify-content: center;

                    & > div {
                        & > div {
                            &:last-child {
                                margin-left: 0;
                            }

                            & > div {
                                max-width: none;
                            }
                        }

                        & > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            .secondWrapper {
                justify-content: flex-end;

                & > div {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    width: 100%;

                    & > img {
                        width: 239px;
                        height: 436px;
                    }

                    & > div {
                        margin-left: 100px;
                        margin-right: 80px;

                        @media (min-width: 575.98px) and (max-width: 768px) {
                            margin-left: 0;
                            margin-right: 20px;
                        }

                        & > div {
                            max-width: 370px;
                        }
                    }
                }

                @media (max-width: 575.98px) {
                    justify-content: center;

                    & > div {
                        flex-direction: column;

                        & > div {
                            &:last-child {
                                margin-left: 0;
                                margin-right: 0;
                            }

                            & > div {
                                max-width: none;
                            }
                        }

                        & > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            .thirdWrapper {
                justify-content: center;

                & > div {
                    & > div:last-child {
                        margin-left: 80px;

                        @media (min-width: 575.98px) and (max-width: 768px) {
                            margin-left: 20px;
                        }
                    }

                    & > img {
                        width: 227px;
                        height: 414px;
                    }

                    & > div {
                        & > div {
                            max-width: 450px;
                        }
                    }
                }

                @media (max-width: 575.98px) {
                    & > div {
                        & > div {
                            &:last-child {
                                margin-left: 0;
                            }

                            & > div {
                                max-width: none;
                            }
                        }

                        & > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        @media (max-width: 767.98px) {
            padding-top: 60px;
            padding-bottom: 30px;
        }
    }

    .partnersInfo {
        @include display-flex($align: center, $direction: column);
        padding: 100px 20px 85px;
        background-color: $white;
        color: $fiord;

        &Title {
            @include font-style($size: 20px, $bold: 500, $family: $font-koho);
        }

        &Description {
            @include font-style($size: 40px, $lineHeight: 44px, $bold: 400, $family: $font-koho);
            margin-top: 16px;
        }

        .firstSection {
            max-width: 950px;
            margin-top: 80px;
        }

        .secondSection {
            max-width: 1150px;
            margin-top: 40px;
        }

        @media (max-width: 575.98px) {
            display: none;
        }
    }

    .teamInfo {
        @include display-flex($align: center, $direction: column);
        padding: 100px 30px;
        background-color: $ghostwhite;
        color: $fiord;

        &Title {
            @include font-style($size: 20px, $bold: 500);

            @media (max-width: 575.98px) {
                font-size: 14px;
                font-weight: 400;

                & > span:first-child {
                    text-transform: uppercase;
                }
            }
        }

        &Description {
            @include font-style($size: 32px, $lineHeight: 40px, $bold: 500);
            margin-top: 16px;

            & > span {
                font-family: $font-koho;
            }

            @media (max-width: 575.98px) {
                @include font-style($size: 28px, $bold: 400);
                margin-top: 2px;

                & > span:first-child {
                    display: none;
                }
            }
        }

        @media (max-width: 575.98px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .supportedBy {
        @include display-flex($align: center, $direction: column);
        padding: 90px 30px;
        background: linear-gradient(
            175.43deg,
            $dodger-blue-darker 49.35%,
            $curious-blue 83.21%,
            $bright-turquoise 110.33%
        );

        &Title {
            @include font-style($size: 40px, $lineHeight: 44px, $color: $white, $bold: 400, $family: $font-koho);
        }

        .firstRow {
            max-width: 700px;
            margin-top: 60px;
        }

        .secondRow {
            max-width: 900px;
            margin-top: 60px;
        }

        .othersSupported {
            position: relative;
            margin-top: 80px;
            width: 100%;
            max-width: 1200px;

            &::before {
                content: '';
                position: absolute;
                width: calc(100% - 40px);
                left: 20px;
                height: 1px;
                background-color: rgba($color: $white, $alpha: 0.5);
                top: -40px;

                @media (max-width: 767.98px) {
                    width: calc(100% - 60px);
                    left: 30px;
                }
            }

            &Wrapper {
                @include display-flex($align: center, $direction: column);

                & > div {
                    width: 100%;
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        @media (max-width: 767.98px) {
            padding: 40px 0;

            .firstRow,
            .secondRow {
                padding: 0 20px;
            }
        }
    }

    .contactInfo {
        @include display-flex($align: center, $justify: center);
        background-color: $kashmir-blue;

        &Wrapper {
            @include display-flex($align: center, $justify: center, $wrap: wrap);
            width: 950px;
            padding: 0 4px 16px;
            & > p,
            h6,
            a {
                @include font-style($size: 16px, $color: $white, $align: center);
                margin: 20px 12px 0;
            }
        }
    }
}
