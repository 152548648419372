@import "src/styles/variables";
@import "src/styles/mixins";

.container {
    font-family: "Koho", sans-serif;
    color: #081348;
    font-size: 14px;
    flex: 1;
    background: linear-gradient(
        180deg,
        rgba(181, 210, 254, 1) 0%,
        rgba(233, 250, 252, 1) 37%,
        rgba(254, 255, 255, 1) 100%
    );
}

.container .section {
    padding: 90px 29px 0;
}

.container .planSection {
    padding: 50px 290px 100px;
}

.sectionsDivider {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.waves {
    width: 100%;
    margin-top: 90px;
    height: 100px;
    background: url("../../assets/png/waves.png");
    background-size: cover;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Koho", sans-serif !important;
}

.sectionBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionHeader {
    font-size: 50px;
    font-weight: 700;
}

.sectionContent {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 500;
}

.formContainer {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 102, 246, 0.2);
    width: 700px;
    border-radius: 25px;
    padding: 25px 30px;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 767.98px) {
        width: 100%;
    }
}

.formBlock {
    font-size: 18px;

    .title {
        font-size: 30px;
        font-weight: bold;
    }
    > p:not(:last-child) {
        margin-bottom: 10px;
    }
}

.changeButton,
.copyButton {
    border: 1px solid $bright-turquoise;
    background-color: transparent;
    padding: 12px 18px;
    border-radius: 4px;
    color: $bright-turquoise;
    cursor: pointer;
    font-size: 16px;

    @media (max-width: 575.98px) {
        margin-top: -25px
    }
}
.changeButton {
    position: absolute;
    width: 95px;
    height: 45px;
    top: 25px;
    right: 30px;
}

.copyButton {
    margin-left: 15px;
}

.apiKeyBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    background-color: $zircon;
    font-size: 16px;

    > p {
        font-weight: bold;
        > span {
            font-weight: 400;
        }
    }
}

.apiKeyText {
    @media (max-width: 767.98px) {
        width: 500px;
    }

    @media (max-width: 575.98px) {
        width: 350px;
    }
}

.apiKeyValue {
    @media (max-width: 767.98px) {
        width: 500px;
    }

    @media (max-width: 575.98px) {
        width: 350px;
    }
}
