@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    @include display-flex($justify: center);
    position: relative;

    .blurBox {
        background: linear-gradient(
            180.71deg,
            $dodger-blue-darker 22.77%,
            $cornflowerblue 79.44%,
            $lightseagreen 99.39%
        );
        filter: blur(120px);
        position: absolute;
        width: 100%;
        height: 120px;
    }

    .container {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 45px 20px;
        z-index: 2;

        .title {
            @include font-style($size: 34px, $bold: 400, $color: $fiord, $family: $font-quicksand);
        }

        .linkWrapper {
            @include display-flex($justify: flex-start);
            margin-top: 10px;
            margin-left: -2px;

            .returnLink {
                @include display-flex($align: center);

                .arrowBackIcon {
                    @include font-style($size: 24px, $bold: 500, $color: $dodger-blue-darker);
                    transition: transform ease-out 0.25s;
                }

                .linkText {
                    @include font-style($size: 14px, $bold: 500, $color: $fiord);
                    margin-left: 5px;
                    margin-bottom: 2px;
                }

                &:hover {
                    .arrowBackIcon {
                        transform: translateX(-12px);
                    }
                }
            }
        }

        .answerSection {
            @include font-style($size: 14px, $bold: 400, $color: $fiord, $lineHeight: 21px);
            margin-top: 30px;
            white-space: pre-wrap;
            line-height: 1.5em;

            a {
                color: $dodger-blue-darker;
            }
        }
    }
}
