@import 'src/styles/variables';
@import 'src/styles/mixins';

.tokensInfoWrapper {
    @include display-flex($align: center);

    .imageCircle {
        @include display-flex($align: center, $justify: center);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $ghostwhite;

        & > img {
            width: 21px;
            height: 15px;
        }

        .defaultIcon {
            height: 20px;
        }
    }

    .forComment {
        background-color: $white !important;
        border: 1px solid $pattens-blue;
    }

    .forValuePrevails {
        background-color: $white-ice-lighter;
    }

    .againstValuePrevails {
        background-color: $wisp-pink-lighter;
    }

    .info {
        color: $fiord;
        margin-left: 6px;

        .title {
            @include font-style($size: 10px, $bold: 700, $transform: uppercase);
        }

        .stats {
            @include display-flex($align: center);

            & > div {
                position: relative;

                &:hover {
                    & > div {
                        display: block;
                    }
                }

                & > span {
                    @include font-style($size: 14px, $bold: 500, $color: $regent-gray);
                }
            }

            .forValuePrevailsText {
                & > span {
                    color: $bright-turquoise;
                }
            }

            .againstValuePrevailsText {
                & > span {
                    color: $lavender-magenta;
                }
            }

            .withVerticalDivider {
                margin-left: 16px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: -8px;
                    width: 1px;
                    height: 12px;
                    background-color: $pattens-blue;
                }
            }

            @media (max-width: 575.98px) {
                margin-top: 4px;

                & > span {
                    font-size: 10px;
                }

                .withVerticalDivider {
                    margin-left: 8px;

                    &::before {
                        top: 0;
                        left: -4px;
                    }
                }
            }
        }
    }
}
