@import 'src/styles/variables';
@import 'src/styles/mixins';

.statsCardWrapper {
    min-height: unset !important;
}

.pageContentWrapper {
    @include max-device-width();
    width: 100%;
    padding: 40px 16px;
    position: relative;
}
